import React, { createContext, ReactNode, useRef, useState } from 'react';

export const HighlightedDatamodelPropertyContext = createContext<{
    highlightedDatamodelProperty: string | null;
    highlightDatamodelProperty: (propertyName: string) => void;
}>({ highlightedDatamodelProperty: null, highlightDatamodelProperty: (_: string) => null });

interface HighlightedDataModelContextProps {
    children: ReactNode;
}

export const HighlightedDataModelContextProvider: React.FC<HighlightedDataModelContextProps> = ({ children }) => {
    const [highlightedDatamodelProperty, setHighlightedDatamodelProperty] = useState<string | null>(null);
    const highlightDatamodelProperty = (propertyName: string) => {
        setHighlightedDatamodelProperty(propertyName);
        if (timerRef.current !== undefined) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => setHighlightedDatamodelProperty(null), 3000);
    };
    const timerRef = useRef<undefined | NodeJS.Timeout>();
    return (
        <HighlightedDatamodelPropertyContext.Provider
            value={{
                highlightedDatamodelProperty,
                highlightDatamodelProperty,
            }}
        >
            {children}
        </HighlightedDatamodelPropertyContext.Provider>
    );
};
