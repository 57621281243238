import React, { ReactNode } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface EventHandlersWrapperProps {
    children: ReactNode;
}

export const EventHandlersWrapper = ({ children }: EventHandlersWrapperProps) => {
    const { wrapper } = useStyles();

    return <div className={wrapper}>{children}</div>;
};

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        wrapper: {
            paddingTop: '1rem',
            marginBottom: '1rem',
        },
    }),
);
