import { Typography } from '@material-ui/core';
import React from 'react';

import { EventHandler, EventTypes } from '../types';
import { CardTitle } from '../../../ui/typography/CardTitle';
import { CardLink } from '../../../ui/CardLink';

interface EventHandlerCardProps {
    url: string;
    eventHandler: EventHandler;
}

export const EventHandlerCard = ({ url, eventHandler }: EventHandlerCardProps) => {
    return (
        <CardLink link={url}>
            <CardTitle>{eventHandler.name}</CardTitle>

            <Description eventHandler={eventHandler} />
        </CardLink>
    );
};

interface DescriptionProps {
    eventHandler: EventHandler;
}
const Description = ({ eventHandler }: DescriptionProps) => {
    const eventsValue = eventNames(eventHandler.events);

    return (
        <Typography variant="body2">
            Notify for{' '}
            <b>
                <i>{eventsValue}</i>
            </b>{' '}
            on{' '}
            <b>
                <i>{eventHandler.entityName}</i>
            </b>
        </Typography>
    );
};

const eventNames = (events: EventTypes) => {
    if (events.content && events.create && events.update && events.delete) {
        return 'all events';
    }

    if (!events.content && !events.create && !events.update && !events.delete) {
        return 'nothing';
    }

    const truthyValues: string[] = [];
    Object.entries(events).forEach(([key, value]) => {
        if (!!value) {
            truthyValues.push(key);
        }
    });

    return truthyValues.join(', ');
};
