import { Select, withStyles } from '@material-ui/core';

const FlexSelect = withStyles({
    selectMenu: {
        // by default this is overflow: hidden, but that messes up baseline alignment in a flex container
        overflow: 'clip',
    },
})(Select);

export default FlexSelect;
