import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography,
    styled,
    withStyles,
} from '@material-ui/core';
import { skipToken } from '@reduxjs/toolkit/query';

import {
    useDeleteApplicationMutation,
    useGetApplicationConfigQuery,
    useUpdateApplicationConfigMutation,
} from '../../api';
import { Application, ApplicationConfig } from '../../../../repository/models/Application';
import BusyButton from '../../../../BusyButton';
import { TextInputList } from '../../../../ui/input/TextInputList';
import { RequestStateHandler } from '../../../../ui/RequestStateHandler';
import { resolveTemplate } from '../../../../hal';
import Actions from '../../../../ui/action/Actions';
import Action from '../../../../ui/action/Action';
import RemapPalette from '../../../../ui/theme/RemapPalette';
import { ServerErrorMessage } from '../../../../ui/ServerErrorMessage';
import { DeleteForever, HelpOutline } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router';
import { INSTANCE_OVERVIEW, INSTANCE_ROOT } from '../../../routes';
import TextInput from '../../../../ui/input/TextInput';

interface InstanceSettingsProps {
    application: Application | null;
}

export const InstanceSettings = ({ application }: InstanceSettingsProps) => {
    const { data: config, isLoading: isConfigLoading, error } = useGetApplicationConfigQuery(application ?? skipToken);
    const [updateApplicationConfig, { isLoading, error: configError }] = useUpdateApplicationConfigMutation();

    const [updatedConfig, setUpdatedConfig] = useState<ApplicationConfig | null>(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handleSave = () => {
        updateApplicationConfig({
            ...updatedConfig,
            cors_origins: updatedConfig?.cors_origins.filter((item) => !!item.trim()),
        } as ApplicationConfig);
    };

    const handleChange = (newList: readonly string[]) => {
        setUpdatedConfig({
            ...updatedConfig,
            cors_origins: newList,
        } as ApplicationConfig);
    };

    useEffect(() => {
        if (updatedConfig?.cors_origins.length === 0) {
            setUpdatedConfig({ ...updatedConfig, cors_origins: [''] });
        }
    }, [updatedConfig]);

    useEffect(() => {
        setUpdatedConfig(config ?? null);
    }, [config]);

    if (isConfigLoading || !!error) {
        return <RequestStateHandler isLoading={isConfigLoading} error={error} />;
    }
    const editIsEnabled = resolveTemplate(config!, 'put') !== null;
    const deleteIsEnabled = resolveTemplate(application!, 'delete') !== null;

    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <Box paddingTop={0.5} display="flex" alignItems="center" height="fit-content">
                    <Box marginRight={1}>
                        <Typography>
                            <b>CORS Origins</b>
                        </Typography>
                    </Box>

                    <CorsTooltip />
                </Box>

                <TextInputList
                    kindTitle="Origin"
                    list={updatedConfig?.cors_origins ?? []}
                    onChange={editIsEnabled ? handleChange : undefined}
                />
            </Box>

            <Actions title="Save and apply all changes">
                {!!configError ? <ServerErrorMessage error={configError} /> : null}

                <Action description="Changes will be immediately applied.">
                    <BusyButton
                        disabled={!editIsEnabled}
                        busy={isLoading}
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Save
                    </BusyButton>
                </Action>
            </Actions>
            <Actions title="Delete this application">
                <RemapPalette from="danger" to="secondary">
                    {application ? (
                        <DeleteConfirmDialog
                            application={application}
                            open={deleteConfirmOpen}
                            onClose={() => setDeleteConfirmOpen(false)}
                            onDelete={() => {
                                setDeleteConfirmOpen(false);
                                navigate(INSTANCE_ROOT.navigate(location.pathname, INSTANCE_OVERVIEW)!);
                            }}
                        />
                    ) : null}
                    <Action description="All data and documents stored in the application will be lost.">
                        <Button
                            disabled={!deleteIsEnabled}
                            variant="outlined"
                            color="secondary"
                            startIcon={<DeleteForever />}
                            onClick={() => setDeleteConfirmOpen(true)}
                        >
                            Delete
                        </Button>
                    </Action>
                </RemapPalette>
            </Actions>
        </Box>
    );
};

interface DeleteConfirmDialogProps {
    readonly application: Application;
    readonly open: boolean;
    readonly onClose: () => void;
    readonly onDelete: () => void;
}

function DeleteConfirmDialog({ application, onDelete, onClose, open }: DeleteConfirmDialogProps) {
    const [deleteApplication, { isLoading, error }] = useDeleteApplicationMutation();
    const [typedName, setTypedName] = useState('');

    const doDelete = useCallback(async () => {
        await deleteApplication(application).unwrap();
        onDelete();
    }, [deleteApplication, onDelete, application]);

    const doClose = useCallback(() => {
        setTypedName('');
        onClose();
    }, [setTypedName, onClose]);

    return (
        <Dialog open={open} onClose={doClose}>
            <DialogTitle>Delete application {application.name}?</DialogTitle>
            <DialogContent>
                <ServerErrorMessage error={error} />
                <Typography>The application will become unavailable immediately.</Typography>
                <Typography gutterBottom>
                    All data and documents will be <strong>destroyed</strong>.
                </Typography>
                <Typography>
                    Type <b>{application.name}</b> below to confirm deletion:
                </Typography>
                <TextInput fullWidth value={typedName} handleOnChange={setTypedName} />
            </DialogContent>
            <DialogActions>
                <Button onClick={doClose}>Cancel</Button>
                <BusyButton
                    disabled={typedName !== application.name}
                    color="secondary"
                    variant="contained"
                    onClick={doDelete}
                    busy={isLoading}
                >
                    Delete
                </BusyButton>
            </DialogActions>
        </Dialog>
    );
}

const CorsTooltip = () => {
    return (
        <OpaqueTooltip
            arrow
            placement="top"
            interactive
            title={
                <Box padding={2}>
                    <StyledTypography variant="body2">
                        <b>Allowed CORS origins</b>
                    </StyledTypography>

                    <Typography variant="body2">
                        Add all the webpages that should be allowed to call the REST API of this application.
                    </Typography>
                    <StyledTypography gutterBottom variant="body2">
                        Example origin: https://portal.acme.example
                    </StyledTypography>

                    <Typography variant="caption">
                        See{' '}
                        <StyledLink
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS"
                        >
                            https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
                        </StyledLink>{' '}
                        for detailed information.
                    </Typography>
                </Box>
            }
        >
            <HelpOutline fontSize="inherit" />
        </OpaqueTooltip>
    );
};

const OpaqueTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.grey[700],
        maxWidth: 450,
    },
}))(Tooltip);

const StyledLink = styled('a')(({ theme }) => ({
    color: theme.palette.common.white,
    '&:hover': {
        textDecoration: 'none',
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));
