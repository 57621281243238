import { Edge, Node, ReactFlowJsonObject, Viewport } from 'reactflow';
import dagre from 'dagre';

import { Attribute, Entity, Relation } from '../../../repository/models/Entity';
import {
    bordersHeight,
    boxPaddings,
    headerHeight,
    iconsBox,
    largeLetterSize,
    minWidth,
    smallLetterSize,
    attrBodySpacing,
    relationMiddle,
    relationMarginBottom,
    attrMarginBottom,
    attrItemHeight,
    relMarginBottom,
    relItemHeight,
} from './variables';
import { theme } from '../../../theme';
import { EntityNode } from './types';

export const getDotPosition = (index: number) => {
    return index === 0
        ? relationMiddle
        : index === 1
          ? 3 * relationMiddle + relationMarginBottom
          : (index * 2 + 1) * relationMiddle + relationMarginBottom * index;
};

const getAttrHeight = (data: Entity) => {
    const attrLength = data._embedded?.attributes?.length;
    const attrSpacing = !attrLength || attrLength < 2 ? 0 : (attrLength - 1) * attrMarginBottom;

    return !attrLength ? 0 : attrLength === 1 ? attrItemHeight : attrLength * attrItemHeight + attrSpacing;
};

const getRelHeight = (data: Entity) => {
    const relLength = data._embedded?.relations?.length;
    const relSpacing = !relLength || relLength < 2 ? 0 : (relLength - 1) * relMarginBottom;

    return !relLength ? 0 : relLength === 1 ? relItemHeight : relLength * relItemHeight + relSpacing;
};

export const getEntityModelHeight = (data: Entity) => {
    const attrHeight = getAttrHeight(data);
    const relHeight = getRelHeight(data);

    return attrHeight + relHeight + headerHeight + attrBodySpacing + bordersHeight;
};

export const getEntityModelWidth = (entity: Entity) => {
    const attrWidth = (entity._embedded?.attributes ?? []).reduce((maxVal: number, attribute: Attribute) => {
        const currentVal = attribute.name.length + attribute.type.length;
        return currentVal > maxVal ? currentVal : maxVal;
    }, 0);

    const nameWidth = entity.name.length * largeLetterSize;

    const textWidth = nameWidth > attrWidth * smallLetterSize ? nameWidth : attrWidth * smallLetterSize;
    const entityBoxWidth = textWidth + boxPaddings + iconsBox + attrBodySpacing;

    return entityBoxWidth < minWidth ? minWidth : entityBoxWidth;
};

export const getNodes = (entities: readonly Entity[]): Node<EntityNode>[] => {
    return entities.map((entity, index) => {
        return {
            id: 'entity_' + index,
            type: 'textUpdater',
            position: { x: 0, y: 0 },
            data: {
                ...entity,
                nodes: [],
                edges: [],
            },
        };
    });
};

export const getEdges = (nodes: Node<EntityNode>[], selectedEntity: string | null) => {
    const edges: Edge[] = [];

    nodes.forEach((node) => {
        (node.data._embedded?.relations ?? []).forEach((rel: Relation, index: number) => {
            const targetNode = getTargetNode(nodes, rel.target);
            if (!!targetNode) {
                edges.push({
                    id: node.id + '_' + index,
                    source: node.id,
                    target: targetNode.id,
                    animated: false,
                    sourceHandle: 'sourceHandle_' + node.id + '_' + index,
                    targetHandle: 'targetHandle_' + node.id + '_' + index,
                    type: 'smoothstep',
                    style: {
                        stroke: theme.palette.text.primary,
                        strokeWidth: node.data.name === selectedEntity || rel.target === selectedEntity ? 2 : 1,
                    },
                    data: {
                        manySourcePerTarget: rel.many_source_per_target,
                        manyTargetPerSource: rel.many_target_per_source,
                    },
                });
            }
        });
    });
    return edges;
};

const getTargetNode = (nodes: Node<EntityNode>[], targetName: string) => {
    return nodes.find((node) => node.data.name === targetName);
};

export const getLayoutedElements = (
    nodes: Node<EntityNode>[],
    edges: Edge[],
    schemaLayout: ReactFlowJsonObject | null,
    defaultViewport: Viewport | null,
): ReactFlowJsonObject => {
    const g = new dagre.graphlib.Graph();
    g.setDefaultEdgeLabel(() => ({}));

    g.setGraph({ rankdir: 'TB' });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));

    nodes.forEach((node) =>
        g.setNode(node.id, {
            ...node,
            width: getEntityModelWidth(node.data),
            height: getEntityModelHeight(node.data),
        }),
    );

    dagre.layout(g);

    return {
        nodes: nodes.map((node) => {
            const gNode = g.node(node.id);
            const flowNode = (schemaLayout?.nodes ?? []).find((item: Node<EntityNode>) => item.id === node.id);

            const nodeX = flowNode?.position.x ? flowNode.position.x : gNode.x - (gNode.width ? gNode.width / 2 : 0);
            const nodeY = flowNode?.position.y ? flowNode.position.y : gNode.y - (gNode.height ? gNode.height / 2 : 0);

            return {
                ...node,
                position: {
                    x: nodeX,
                    y: nodeY,
                },
            };
        }),
        edges,
        viewport:
            !schemaLayout && defaultViewport
                ? defaultViewport
                : schemaLayout
                  ? schemaLayout.viewport
                  : { x: 0, y: 0, zoom: 1 },
    };
};
