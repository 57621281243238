import { Box, Button, styled } from '@material-ui/core';
import React, { useState } from 'react';
import { Alert } from '@material-ui/lab';
import { SerializedError } from '@reduxjs/toolkit';

import { IamGroup, IamGroupMember, IamGroupMemberList } from '../../../../repository/models/IamGroup';
import { RequestStateHandler } from '../../../../ui/RequestStateHandler';
import { Row } from '../../../../ui/table/Row';
import { TableHeader } from '../../../../ui/typography/TableHeader';
import { Cell } from '../../../../ui/table/Cell';
import { ConfirmationDialog } from './ConfirmationDialog';
import { IamUserName } from '../../components/IamUserName';

interface MembersTableProps {
    list: IamGroupMemberList;
    isLoading: boolean;
    error: SerializedError;
    group: IamGroup;
}

export const MembersTable = ({ list, isLoading, error, group }: MembersTableProps) => {
    const members = list?._embedded?.members ?? [];
    const [selectedMember, setSelectedMember] = useState<IamGroupMember | null>(null);

    const handleLeave = (member: IamGroupMember) => {
        setSelectedMember(member);
    };

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    if (!members.length) {
        return <Alert severity="info">There are no members in the group!</Alert>;
    }

    return (
        <>
            <Box display="flex" flexDirection="column">
                <Row borders="horizontal">
                    <TableHeader>Email</TableHeader>
                    <TableHeader>Name</TableHeader>
                    <Cell width={15}></Cell>
                </Row>
                {members.map((item: IamGroupMember) => {
                    return (
                        <StyledRow display="flex" alignItems="center" key={item.username}>
                            <Cell>{item.email}</Cell>
                            <Cell>
                                <IamUserName firstName={item?.first_name} lastName={item?.last_name} />
                            </Cell>
                            <Cell width={15}>
                                <StyledButton color="primary" variant="text" onClick={() => handleLeave(item)}>
                                    Leave
                                </StyledButton>
                            </Cell>
                        </StyledRow>
                    );
                })}
            </Box>

            {!!selectedMember ? (
                <ConfirmationDialog
                    opened={selectedMember !== null}
                    group={group}
                    member={selectedMember}
                    onClose={() => setSelectedMember(null)}
                />
            ) : null}
        </>
    );
};

const StyledRow = styled(Box)(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.divider,
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'capitalize',
    fontWeight: 'inherit',
    fontSize: theme.typography.caption.fontSize,
}));
