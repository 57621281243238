import { Card, styled } from '@material-ui/core';

export const StyledCard = styled(Card)(() => ({
    position: 'relative',
    boxShadow: 'none',
    overflow: 'visible',
    '&:not(:last-child)': {
        marginBottom: '1rem',
    },
    '& > *': {
        position: 'relative',
        zIndex: 1,
    },
    '&::before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        inset: 0,
        boxShadow: '0 4px 13px 0 #00001e26, 0 1px 6px 0 #00001e26',
        borderRadius: '0.25rem',
        opacity: '0',
        zIndex: 0,
        transition: 'opacity 100ms ease-in',
    },
    '&::after': {
        display: 'block',
        content: '""',
        position: 'absolute',
        inset: 0,
        boxShadow: '0 2px 4px 0 #00001e1a, 0 1px 2px 0 #00001e1a',
        borderRadius: '0.25rem',
        opacity: '1',
        zIndex: 0,
        transition: 'opacity 100ms ease-in',
    },
    '& .MuiCardActionArea-root': {
        zIndex: 1,
    },
    '&.MuiCard-root': {
        overflow: 'visible',
    },
    '& .MuiCardActionArea-focusHighlight': {
        color: '#fff',
    },
    '& .MuiTouchRipple-root': {
        borderRadius: '0.25rem',
        color: '#a3a3a3',
    },
}));
