import { generatePath } from 'react-router-dom';
import { BaseRouteImpl } from './BaseRouteImpl';
import { ChildrenRoutesOption, ChildRoute, ExtractVar, Route, RoutesOptions, RoutesReturn } from './types';

export class RouteImpl<Pattern extends string, Variables extends string = never, AncillaryData = unknown>
    extends BaseRouteImpl<Pattern, Variables, AncillaryData>
    implements Route<Pattern, Variables, AncillaryData>
{
    private readonly dynamicChildren: Array<ChildRoute<Pattern, Variables, string>> = [];
    private readonly staticChildren: Array<ChildRoute<Pattern, Variables, string, never>> = [];

    private get children(): ReadonlyArray<ChildRoute<Pattern, Variables, string>> {
        return (this.staticChildren as typeof this.children).concat(this.dynamicChildren);
    }

    public routes<Opts extends RoutesOptions>(
        opts: Opts,
    ): ReadonlyArray<RoutesReturn<Pattern, Variables, Opts, AncillaryData>> {
        const allRoutes: Array<RoutesReturn<Pattern, Variables, Opts, AncillaryData>> = [];

        if (opts.self) {
            allRoutes.push(this as any);
        }

        switch (opts.children) {
            case ChildrenRoutesOption.NONE:
            case undefined:
                break;
            case ChildrenRoutesOption.STATIC:
                allRoutes.push(
                    ...(this.staticChildren.flatMap((child) =>
                        child.routes({
                            self: true,
                            wildcard: false,
                            children: ChildrenRoutesOption.STATIC,
                        }),
                    ) as any),
                );
                break;
            case ChildrenRoutesOption.DYNAMIC:
                allRoutes.push(
                    ...(this.dynamicChildren.flatMap((child) =>
                        child.routes({
                            self: true,
                            wildcard: false,
                            children: ChildrenRoutesOption.ALL,
                        }),
                    ) as any),
                );
                break;
            case ChildrenRoutesOption.ALL:
                allRoutes.push(
                    ...(this.children.flatMap((child) =>
                        child.routes({
                            self: true,
                            wildcard: false,
                            children: ChildrenRoutesOption.ALL,
                        }),
                    ) as any),
                );
                break;
        }

        if (opts.wildcard) {
            allRoutes.push(this.wildcard as any);
        }

        return allRoutes;
    }

    public child<ChildPattern extends string>(childPattern: ChildPattern, data?: AncillaryData) {
        const newRoute = new RouteImpl<
            `${Pattern}/${ChildPattern}`,
            Variables | ExtractVar<ChildPattern>,
            AncillaryData
        >(`${this.pattern}/${childPattern}`, this.rootRoute, data ?? this.data);
        if (!childPattern.startsWith(':')) {
            this.staticChildren.push(newRoute as any);
        } else {
            this.dynamicChildren.push(newRoute as any);
        }

        return newRoute;
    }

    public generate(params: Record<Variables, string>) {
        return generatePath(this.pattern, params);
    }

    public replace(path: string | null | undefined, params: Record<Variables, string>): string | null {
        const routes = this.routes({
            self: true,
            children: ChildrenRoutesOption.STATIC,
        });
        for (const route of routes) {
            const match = route.match(path);
            if (match) {
                return route.generate(params);
            }
        }
        return null;
    }
}
