import { AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import watcher from './watcher';
import fetcher from '../repository';
import account from '../features/account/accountSlice';
import authentication from '../features/authentication/authenticationSlice';
import project from '../features/project/projectSlice';
import blueprint from '../features/blueprint/blueprintSlice';
import tour from '../features/tour/tourSlice';
import datamodelAssistant from '../features/datamodel/DataModelAssistant/datamodelAssistantSlice';
import router, { routerMiddleware } from '../features/routes/routeSlice';
import IDataFetcher from '../repository/IDataFetcher';
import globalApi from './api';

const reducer = combineReducers({
    account,
    authentication,
    project,
    blueprint,
    router,
    tour,
    datamodelAssistant,
    [globalApi.reducerPath]: globalApi.reducer,
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    dataFetcher: fetcher,
                },
            },
        })
            .concat([routerMiddleware])
            .concat([globalApi.middleware]);
    },
    devTools: process.env.NODE_ENV === 'development',
});
watcher(store);

export type AppStore = typeof store;
export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunkAction<R> = ThunkAction<R, AppState, AppAsyncThunkExtra, AnyAction>;
export type AppAsyncThunkExtra = {
    dataFetcher: IDataFetcher;
};

export type AppAsyncThunkConfig = {
    extra: AppAsyncThunkExtra;
};

export default store;
