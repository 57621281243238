import {
    Button,
    Checkbox as UICheckbox,
    createStyles,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    makeStyles,
    Select as UISelect,
    Theme,
    Box,
    Typography,
    Tooltip,
    ButtonGroup,
} from '@material-ui/core';
import React from 'react';
import FingerPrintIcon from '@material-ui/icons/Fingerprint';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { Attribute, Entity } from '../../repository/models/Entity';
import BusyButton from '../../BusyButton';
import { HalForm, resolveTemplate, RForm, withHalOptions, withHalProperty, WithoutHal } from '../../hal';
import { useAddAttributeMutation } from './api';
import { HalCheckboxIconLabel } from '../../ui/CheckboxIconLabel';
import { RequireIcon } from '../../ui/icons/RequireIcon';
import ValidatedTextInput from '../../hal/forms/ValidatedTextInput';
import { inheritHalProperty } from '../../hal/forms/react';
import { ServerErrorMessage } from '../../ui/ServerErrorMessage';

type AttributeModelProps = {
    entity: Entity;
    onClose: () => void;
    onSave: (attribute: Attribute, configure: boolean) => void;
};

export enum Acts {
    RENAME,
    CHANGE_TYPE,
    SET_NAT_ID,
    SET_UNIQUE,
    SET_REQUIRED,
}

export type Action = {
    type: Acts;
    data?: any;
};

type State = {
    attribute: WithoutHal<Attribute> | Attribute;
};

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);
const Checkbox = withHalProperty(UICheckbox);

const typeNames: Record<string, string> = {
    STRING: 'Text',
    LONG: 'Integer',
    DOUBLE: 'Decimal',
    DATETIME: 'Date',
    BOOLEAN: 'Boolean',
    CONTENT: 'Content',
    AUDIT_METADATA: 'Audit Metadata',
};
const SelectAttrType = withHalOptions(withHalProperty(UISelect), ({ value, prompt }) => (
    <option value={value}>{typeNames[value] ?? prompt}</option>
));

function reducer(state: State, action: Action): State {
    const update = (attr: Partial<WithoutHal<Attribute>>) => ({
        attribute: { ...state.attribute, ...attr },
    });
    switch (action.type) {
        case Acts.RENAME:
            return update({ name: action.data });
        case Acts.SET_UNIQUE:
            return update({ unique: action.data });
        case Acts.SET_REQUIRED:
            return update({ required: action.data });
        case Acts.CHANGE_TYPE:
            switch (action.data) {
                case 'CONTENT':
                    return update({
                        type: action.data,
                        unique: false,
                        required: false,
                        natural_id: false,
                    });
                case 'AUDIT_METADATA':
                    return update({ type: action.data, unique: false, required: false });
                default:
                    return update({ type: action.data });
            }
        case Acts.SET_NAT_ID:
            return action.data
                ? update({
                      natural_id: true,
                      unique: true,
                      required: true,
                  })
                : update({ natural_id: false });
    }
}

export default function AttributeModelAdd(props: AttributeModelProps) {
    const classes = useStyles();
    // start from empty attribute if we're adding a new one
    let attr: WithoutHal<Attribute> = {
        name: '',
        type: 'STRING',
        natural_id: false,
        unique: false,
        required: false,
    };

    const [addAttribute, { isLoading: addAttributeIsLoading, error: addAttributeError }] = useAddAttributeMutation();

    const attributeHalForm = resolveTemplate(props.entity, 'addAttribute');

    const save = async (configure: boolean) => {
        const attribute = await addAttribute({
            entity: props.entity,
            attribute: state.attribute,
        }).unwrap();
        props.onSave(attribute, configure);
        showChangesSavedToast();
    };

    const [state, dispatch] = React.useReducer(reducer, { attribute: attr });
    const act = (type: Acts, data?: any) => {
        return dispatch({ type: type, data: data });
    };

    const { disabled: uniqueDisabled, hint: uniqueHint } = getPropertyValues(attributeHalForm, 'unique');
    const { disabled: requiredDisabled, hint: requiredHint } = getPropertyValues(attributeHalForm, 'required');

    const isUniqueDisabled =
        state.attribute.natural_id || ['CONTENT', 'AUDIT_METADATA'].includes(state.attribute.type) || uniqueDisabled;
    const isRequiredDisabled =
        state.attribute.natural_id || ['CONTENT', 'AUDIT_METADATA'].includes(state.attribute.type) || requiredDisabled;

    return (
        <>
            <DialogTitle>Add New Attribute</DialogTitle>
            <DialogContent className={classes.propertyEdit}>
                <ServerErrorMessage error={addAttributeError} />
                <RForm template={attributeHalForm}>
                    <Typography className={clsx(classes.formRow, classes.baseline)}>
                        <span className={classes.label}>Entity:</span>
                        <span>{props.entity.name}</span>
                    </Typography>
                    <div className={clsx(classes.formRow, classes.baseline)}>
                        <label className={classes.label}>Name: </label>
                        <HalValidatedTextInput
                            name="name"
                            displayName="Name"
                            overrideRegexMessage="Only lowercase alphanumeric and underscores are allowed."
                            className={classes.grow}
                            placeholder="my_attribute"
                            value={state.attribute.name}
                            autoFocus
                            handleOnChange={(v) => act(Acts.RENAME, v)}
                        />
                    </div>
                    <div className={classes.formRow}>
                        <label className={classes.label}>Type:</label>
                        <SelectAttrType
                            name="type"
                            native
                            value={state.attribute.type}
                            onChange={(e) => act(Acts.CHANGE_TYPE, e.target.value)}
                        />
                        {false /* Temporarily disabled until we fully support it in the backend */ && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="natural_id"
                                        checked={state.attribute.natural_id}
                                        disabled={state.attribute.type === 'CONTENT'}
                                        onChange={(e) => act(Acts.SET_NAT_ID, e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={<Typography noWrap>Use as ID</Typography>}
                            />
                        )}
                    </div>
                    <div className={classes.checkBoxes}>
                        <Tooltip arrow placement="top" title={uniqueHint}>
                            <Box width="max-content">
                                <HalCheckboxIconLabel
                                    name="unique"
                                    checked={state.attribute.unique}
                                    disabled={isUniqueDisabled}
                                    label="Values must be unique"
                                    onChange={(value) => act(Acts.SET_UNIQUE, value)}
                                >
                                    <FingerPrintIcon
                                        fontSize="inherit"
                                        color={isUniqueDisabled ? 'disabled' : 'action'}
                                    />
                                </HalCheckboxIconLabel>
                            </Box>
                        </Tooltip>

                        <Tooltip arrow placement="top" title={requiredHint}>
                            <Box width="max-content">
                                <HalCheckboxIconLabel
                                    name="required"
                                    checked={state.attribute.required}
                                    disabled={isRequiredDisabled}
                                    label="Values are required"
                                    onChange={(value) => act(Acts.SET_REQUIRED, value)}
                                >
                                    <RequireIcon
                                        fontSize="inherit"
                                        color={isRequiredDisabled ? 'disabled' : 'action'}
                                    />
                                </HalCheckboxIconLabel>
                            </Box>
                        </Tooltip>
                    </div>
                </RForm>
            </DialogContent>
            <DialogActions>
                <Button disabled={addAttributeIsLoading} onClick={props.onClose}>
                    Cancel
                </Button>
                <ButtonGroup color="primary" variant="contained">
                    <BusyButton busy={addAttributeIsLoading} onClick={() => save(false)}>
                        Save
                    </BusyButton>
                    <BusyButton busy={addAttributeIsLoading} onClick={() => save(true)}>
                        Save & configure
                    </BusyButton>
                </ButtonGroup>
            </DialogActions>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        propertyEdit: {
            '& > * + *': {
                marginTop: theme.spacing(1),
            },
        },
        formRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: theme.typography.body1.fontSize,
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        label: {
            width: theme.spacing(8),
        },
        baseline: {
            alignItems: 'baseline',
        },
        grow: {
            flexGrow: 1,
        },
        checkBoxes: {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiCheckbox-root': {
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 3,
                paddingRight: 3,
                marginLeft: 6,
            },
            marginBottom: theme.spacing(1),
        },
        delete: {
            marginRight: 'auto',
        },
        summaryLabel: {
            display: 'inline',
            fontSize: theme.typography.body1.fontSize,
            color: theme.palette.text.primary,
        },
    }),
);

const showChangesSavedToast = () => {
    toast.success('Changes saved successfully', {
        toastId: 'attribute-changes',
    });
};

const getPropertyValues = (halForm: HalForm<unknown> | null, property: string) => {
    if (halForm) {
        const isDisabled = !halForm.property(property).enabled;
        const hint = halForm.property(property)?.hint ?? '';

        return { disabled: isDisabled, hint: isDisabled ? hint : '' };
    }

    return { disabled: false, hint: '' };
};
