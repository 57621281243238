import { Organization } from '../../repository/models/Organization';
import fetcher from '../../repository';
import { Project, ProjectList } from '../../repository/models/Project';
import { tags, wrapPromise } from '../../repository/rtk-query';
import api from '../../store/api';

const projectsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProjectListForOrg: build.query<ProjectList, Organization>({
            queryFn: (org) => wrapPromise(fetcher.getProjects(org)),
            providesTags: (projects, _error, organization) =>
                tags('Project').listFor(organization).withItems(projects?._embedded?.projects).toArray(),
        }),
        createProject: build.mutation<Project, { projectList: ProjectList; projectName: string }>({
            queryFn: async ({ projectList, projectName }) => wrapPromise(fetcher.addProject(projectList, projectName)),
            invalidatesTags: (_project, _error, { projectList }) => tags('Project').listFor(projectList).toArray(),
        }),
    }),
});

export const { useGetProjectListForOrgQuery, useCreateProjectMutation } = projectsApi;

export default projectsApi;
