import { tags, wrapPromise } from '../../repository/rtk-query';
import fetcher from '../../repository';
import { Member } from '../../repository/models/Member';
import { CreateOrganization, Organization, OrganizationList } from '../../repository/models/Organization';
import { OrganizationInvite, OrganizationInviteList, UserInvite } from '../../repository/models/Invite';
import { User } from '../../repository/models/User';
import api from '../../store/api';

const organizationApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrganizationMembers: build.query<Member[], Organization>({
            queryFn: (organization) => wrapPromise(fetcher.getOrganizationMembers(organization)),
            providesTags: (members, _error, organization) =>
                tags('OrganizationMembers')
                    .listFor(organization)
                    .withItems(members, (m) => m.email)
                    .toArray(),
        }),
        getOrganizationInvites: build.query<OrganizationInviteList, Organization>({
            queryFn: (organization) => wrapPromise(fetcher.getOrganizationInvites(organization)),
            providesTags: (invites, _error, organization) =>
                tags('OrganizationInvites')
                    .listFor(organization)
                    .listFor(invites!._links.self.href)
                    .withItems(invites?._embedded?.invitations)
                    .toArray(),
        }),
        getUserInvites: build.query<UserInvite[], User>({
            queryFn: (user) => wrapPromise(fetcher.getUserInvites(user)),
            providesTags: (invites, _error, user) =>
                tags('InvitesToOrganization').listFor(user).withItems(invites).toArray(),
        }),
        getOrganizationList: build.query<OrganizationList, User>({
            queryFn: (user) => wrapPromise(fetcher.getOrganizations(user)),
            providesTags: (orgs, _error, user) =>
                tags('Organization').list().listFor(user).withItems(orgs?._embedded?.organizations).toArray(),
        }),
        inviteUser: build.mutation<void, { inviteList: OrganizationInviteList; email: string }>({
            queryFn: ({ inviteList, email }) => wrapPromise(fetcher.inviteUser(inviteList, email)),
            invalidatesTags: (_members, _error, { inviteList }) =>
                tags('OrganizationInvites').listFor(inviteList._links.self.href).toArray(),
        }),
        cancelOrganizationInvite: build.mutation<void, { org: Organization; invite: OrganizationInvite }>({
            queryFn: ({ org: _org, invite }) => wrapPromise(fetcher.deleteOrganizationInvite(invite)),
            invalidatesTags: (_, _error, { invite }) => tags('OrganizationInvites').item(invite).toArray(),
        }),
        rejectUserInvite: build.mutation<void, UserInvite>({
            queryFn: (invite) => wrapPromise(fetcher.rejectUserInvite(invite)),
            invalidatesTags: [{ type: 'InvitesToOrganization' }],
        }),
        acceptUserInvite: build.mutation<void, UserInvite>({
            queryFn: (invite) => wrapPromise(fetcher.acceptUserInvite(invite)),
            invalidatesTags: [{ type: 'InvitesToOrganization' }, { type: 'Organization', id: 'LIST' }],
        }),
        createOrganization: build.mutation<Organization, CreateOrganization>({
            queryFn: (organization) => wrapPromise(fetcher.addOrganization(organization)),
            invalidatesTags: tags('Organization').list().toArray(),
        }),
    }),
});

export const {
    useGetOrganizationMembersQuery,
    useGetOrganizationInvitesQuery,
    useGetOrganizationListQuery,
    useGetUserInvitesQuery,
    useInviteUserMutation,
    useCreateOrganizationMutation,
    useCancelOrganizationInviteMutation,
    useRejectUserInviteMutation,
    useAcceptUserInviteMutation,
} = organizationApi;

export default organizationApi;
