import { Route } from '.';
import { createRoot } from './_internal';

export interface RouteData {
    isTabIn?: Route;
}

export const ROOT = createRoot<RouteData>({});

// Organizations
export const ORGANIZATION_ROOT = ROOT.child(':org');
export const ORGANIZATION_DASHBOARD = ORGANIZATION_ROOT;
export const ORGANIZATION_SETTINGS = ORGANIZATION_ROOT.child('~settings', {
    isTabIn: ORGANIZATION_DASHBOARD,
});
export const ORGANIZATION_MEMBERS = ORGANIZATION_ROOT.child('~members', {
    isTabIn: ORGANIZATION_DASHBOARD,
});
export const CREATE_ORGANIZATION = ROOT.child('~create-organization');

// Projects
export const PROJECT_ROOT = ORGANIZATION_ROOT.child(':project');
export const PROJECT_DASHBOARD = PROJECT_ROOT;
export const PROJECT_BLUEPRINTS = PROJECT_ROOT.child('~blueprints');
export const PROJECT_RELEASES = PROJECT_ROOT.child('~releases');
export const PROJECT_INSTANCES = PROJECT_ROOT.child('~applications');
export const CREATE_PROJECT = ORGANIZATION_ROOT.child('~create-project');

// Blueprints
export const BLUEPRINT_ROOT = PROJECT_ROOT.child(':blueprint');
export const BLUEPRINT_MODEL = BLUEPRINT_ROOT.child('~model');
export const BLUEPRINT_PERMISSIONS = BLUEPRINT_ROOT.child('~permissions');
export const BLUEPRINT_EVENTHANDLERS = BLUEPRINT_ROOT.child('~eventhandlers');

// Entities
export const DATAMODEL_ROOT = BLUEPRINT_MODEL.child(':entity');
export const DATAMODEL_ATTRIBUTE = DATAMODEL_ROOT.child('attribute').child(':attribute');

// Permissions
export const PERMISSIONS_ROOT = BLUEPRINT_PERMISSIONS.child(':entity');
export const PERMISSIONS_NEW = PERMISSIONS_ROOT.child('~new');
export const PERMISSIONS_EDIT = PERMISSIONS_ROOT.child(':policy');

// Event Handlers
export const EVENTHANDLERS_ROOT = BLUEPRINT_ROOT.child('~eventhandlers');
export const EVENTHANDLERS_NEW = BLUEPRINT_EVENTHANDLERS.child('~new');
export const EVENTHANDLERS_EDIT = BLUEPRINT_EVENTHANDLERS.child(':eventHandler');

// Releases
export const RELEASES_ROOT = PROJECT_RELEASES;
export const RELEASE_SHOW = RELEASES_ROOT.child(':release');
export const RELEASE_SHOW_OPENAPI = RELEASE_SHOW.child('~openapi');
export const RELEASE_CREATE = BLUEPRINT_ROOT.child('~create-release');
export const RELEASE_CREATE_WITHOUT_SELECTED_BLUEPRINT = PROJECT_ROOT.child('~create-release');

// Instances
export const INSTANCES_ROOT = PROJECT_INSTANCES;
export const INSTANCE_ROOT = INSTANCES_ROOT.child(':application');
export const INSTANCE_OVERVIEW = INSTANCE_ROOT.child('~overview', {
    isTabIn: INSTANCE_ROOT,
});
export const INSTANCE_HISTORY = INSTANCE_ROOT.child('~history', {
    isTabIn: INSTANCE_ROOT,
});
export const INSTANCE_SETTINGS = INSTANCE_ROOT.child('~settings', {
    isTabIn: INSTANCE_ROOT,
});

// IAM
export const IAM_ROOT = ORGANIZATION_ROOT.child('~iam');
export const IAM_REALM = IAM_ROOT.child(':realm');
export const IAM_REALM_OVERVIEW = IAM_REALM.child('~overview');
export const IAM_REALM_USERS = IAM_REALM.child('~users');
export const IAM_REALM_USER = IAM_REALM.child('~user').child(':user');
export const IAM_REALM_EDIT_USER = IAM_REALM_USER.child('~edit');
export const IAM_REALM_CREATE_USER = IAM_REALM.child('~create-user');
export const IAM_REALM_GROUPS = IAM_REALM.child('~groups');
export const IAM_REALM_GROUP = IAM_REALM.child('~group').child(':group');
export const IAM_REALM_CREATE_GROUP = IAM_REALM.child('~create-group');
export const IAM_REALM_EDIT_GROUP = IAM_REALM_GROUP.child('~edit');
export const IAM_REALM_ATTRIBUTES = IAM_REALM.child('~attributes');
export const IAM_REALM_CREATE_ATTRIBUTE = IAM_REALM.child('~create-attribute');
export const IAM_REALM_CLIENTS = IAM_REALM.child('~clients');
export const IAM_REALM_SSO = IAM_REALM.child('~sso');
