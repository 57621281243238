import { createSelector } from '@reduxjs/toolkit';
import { createWatcher, combineWatchers } from '@xenit/redux-utils';
import { AppDispatch } from '../../store';
import { selectIsAuthenticated } from '../authentication/authenticationSlice';
import organizationApi from '../organization/api';
import { ROOT, selectRouterPath } from '../routes';
import {
    selectOrganizations,
    selectCurrentUser,
    selectCurrentOrganization,
    accountSetCurrentOrganizationWithoutHistory,
    accountGetCurrentUser,
    selectInvites,
} from './accountSlice';

const authenticationWatcher = createWatcher(
    selectIsAuthenticated,
    (newAuth, _oldAuth, { dispatch }: { dispatch: AppDispatch }) => {
        if (newAuth) {
            dispatch(accountGetCurrentUser());
        }
    },
);

const userWatcher = createWatcher(selectCurrentUser, (newUser, oldUser, { dispatch }: { dispatch: AppDispatch }) => {
    // A user can change without being cleared inbetween.
    // When a different user has logged in, we need to fetch a new list of organizations.
    // But when the same user has just re-logged in after a session timeout,
    // we don't want to re-fetch their list of organizations. (See also accountSlice.ts#currentUserReducer)
    if (newUser && newUser.email !== oldUser?.email) {
        dispatch(organizationApi.endpoints.getOrganizationList.initiate(newUser));
        dispatch(organizationApi.endpoints.getUserInvites.initiate(newUser));
    }
});

const selectOrgToAutoselect = createSelector(
    selectRouterPath,
    selectOrganizations,
    selectCurrentOrganization,
    selectInvites,
    (path, orgs, currentOrg, invites) => {
        if (!ROOT.match(path)) {
            return null;
        }
        if (currentOrg !== null) {
            return null;
        }
        if (invites === null || invites === undefined) {
            return null;
        }
        if (!!orgs && orgs.length === 1 && !invites.length) {
            return orgs[0];
        }
        return null;
    },
);

const defaultOrgWatcher = createWatcher(
    selectOrgToAutoselect,
    (autoselectOrg, _oldOrgs, { dispatch }: { dispatch: AppDispatch }) => {
        if (autoselectOrg) {
            dispatch(accountSetCurrentOrganizationWithoutHistory(autoselectOrg));
        }
    },
);

export default combineWatchers(authenticationWatcher, userWatcher, defaultOrgWatcher);
