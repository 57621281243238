import { createStyles, makeStyles, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Organization } from '../../repository/models/Organization';
import { AppState } from '../../store';
import { selectCurrentOrganization, selectOrganizations } from './accountSlice';

type OrganizationSelectorProps = {
    labelId?: string;
    currentOrg: Organization | null;
    orgs: readonly Organization[] | null;
    handleSetSelectedOrg: (org: Organization) => void;
};

function OrganizationSelector(props: OrganizationSelectorProps) {
    const { orgs, currentOrg, labelId, handleSetSelectedOrg } = props;

    const [selectedOrg, setSelectedOrg] = React.useState<Organization | null>(null);

    const orgByName = React.useCallback(
        (name: string) => (orgs ?? []).filter((org) => org.name === name)[0] ?? null,
        [orgs],
    );

    React.useEffect(() => {
        if (selectedOrg === null && !!orgs && orgs.length > 0) {
            if (currentOrg === null) {
                setSelectedOrg(orgs[0]!);
                handleSetSelectedOrg(orgs[0]!);
            } else {
                setSelectedOrg(currentOrg);
                handleSetSelectedOrg(currentOrg);
            }
        }
    }, [currentOrg, selectedOrg, setSelectedOrg, orgs, handleSetSelectedOrg]);

    const handleChange = React.useCallback(
        (name: string) => {
            const org = orgByName(name);
            setSelectedOrg(org);
            if (org !== null) {
                handleSetSelectedOrg(org);
            }
        },
        [orgByName, setSelectedOrg, handleSetSelectedOrg],
    );

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Select
                variant="outlined"
                className={classes.select}
                labelId={labelId}
                value={selectedOrg?.name ?? ''}
                onChange={(e) => handleChange(e.target.value as string)}
            >
                {(orgs ?? []).map((org) => (
                    <MenuItem key={org.name} value={org.name}>
                        {org.display_name}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'inline-block',
            '& .MuiOutlinedInput-root': {
                width: '100%',
            },
        },
        select: {
            '& .MuiOutlinedInput-input': {
                padding: '8px calc(12px + 1.5em) 8px 12px',
            },
        },
    }),
);

export default connect((state: AppState) => ({
    currentOrg: selectCurrentOrganization(state),
    orgs: selectOrganizations(state),
}))(OrganizationSelector);
