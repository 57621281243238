import { Typography } from '@material-ui/core';
import { skipToken } from '@reduxjs/toolkit/query';
import RelativeTime from '@yaireo/relative-time';
import React from 'react';

import { TypedLink } from '../../../hal';
import { Changeset } from '../../../repository/models/Changeset';
import { Release } from '../../../repository/models/Release';
import { RequestStateHandler } from '../../../ui/RequestStateHandler';
import { useGetChangesetQuery } from '../../application/api';
import { ApiVersion } from './ApiVersion';

interface ReleaseDetailsProps {
    readonly release: Release;
    readonly baseChangeset?: Changeset | TypedLink<Changeset> | null;
}

const relativeTime = new RelativeTime();

export default function ReleaseDetails(props: ReleaseDetailsProps) {
    const release = props.release;
    let baseChangeset = props.baseChangeset;

    const {
        data: changeset,
        isLoading: changesetIsLoading,
        error: changesetError,
    } = useGetChangesetQuery(release._links.changeset.href);

    const {
        data: baseChangesetData,
        isLoading: baseChangesetIsLoading,
        error: baseChangesetError,
    } = useGetChangesetQuery(typeof baseChangeset === 'string' ? baseChangeset : skipToken);
    if (typeof baseChangeset === 'string') {
        baseChangeset = baseChangesetData;
    }

    if (!changeset) {
        return <RequestStateHandler isLoading={changesetIsLoading} error={changesetError} />;
    }
    if (baseChangesetIsLoading || !!baseChangesetError) {
        return <RequestStateHandler isLoading={baseChangesetIsLoading} error={baseChangesetError} />;
    }

    const baseProjection = baseChangeset ? baseChangeset.projections.post : changeset.projections.base;

    return (
        <>
            <Typography>{release.message ?? null}</Typography>
            <Typography variant="body2">
                Created {relativeTime.from(new Date(release.created))}
                {<>{' by ' + release.author}</>}
            </Typography>
            <Typography variant="body2">
                REST API Version:{' '}
                <ApiVersion base={baseProjection.version} current={changeset.projections.post.version} />
            </Typography>
        </>
    );
}
