import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export default createSvgIcon(
    <>
        <defs>
            <style>
                {
                    '.cls-1{fill:#404041;}.cls-2{fill:#939598;}.cls-3{fill:#424143;}.cls-4{fill:#6ba43a;}.cls-5{fill:#6d6e71;}.cls-6{fill:#93d500;}'
                }
            </style>
        </defs>
        <g transform="matrix(.21426 0 0 .21426 -8.8983 -26.25)">
            <polygon className="cls-6" points="42.98 200.51 43.93 200.21 42.97 200.48" />
            <path className="cls-6" d="m116.5 227.55s0.02-0.01 0.03-0.02c0 0-0.02 0.01-0.03 0.02z" />
            <polygon className="cls-6" points="116.48 227.56 116.49 227.56" />
            <path className="cls-6" d="m116.53 227.53c0.07-0.04 0.13-0.07 0.2-0.11-0.07 0.03-0.13 0.07-0.2 0.11z" />
            <polygon className="cls-6" points="116.49 227.56 116.5 227.55" />
            <polygon className="cls-6" points="116.49 227.56 116.45 227.5 116.48 227.56" />
            <path className="cls-6" d="m116.5 227.55s0.02-0.01 0.03-0.02c0 0-0.02 0.01-0.03 0.02z" />
            <polygon className="cls-6" points="116.48 227.56 116.47 227.57" />
            <polygon className="cls-6" points="" />
            <path className="cls-6" d="m116.49 227.56h0.01z" />
            <path className="cls-6" d="m116.53 227.53c0.07-0.04 0.13-0.07 0.2-0.11-0.07 0.04-0.13 0.07-0.2 0.11z" />
            <polygon className="cls-6" points="116.73 227.42 116.73 227.41" />
            <rect
                className="cls-6"
                transform="rotate(-33.69 116.48 227.57)"
                x="116.47"
                y="227.57"
                width="0"
                height="0"
            />
            <polygon className="cls-6" points="116.46 227.57 116.47 227.57" />
            <polygon className="cls-6" points="" />
            <rect className="cls-4" transform="rotate(-31.07 65.775 142.86)" x="65.8" y="142.37" width="0" height="1" />
            <polygon className="cls-4" points="65.54 142.44 65.54 142.45" />
            <g transform="matrix(.87501 0 0 .87501 17.793 16.693)">
                <g transform="translate(0 .00040782)">
                    <path
                        className="cls-2"
                        d="m71.13 186.93h-29.56c0 0.14 0 0.29 0.02 0.43 0.01 0.28 0.03 0.56 0.05 0.83 0 0.12 0.01 0.24 0.02 0.36 0.02 0.32 0.05 0.64 0.08 0.96 0 0.07 0.01 0.14 0.02 0.22 0.03 0.36 0.07 0.71 0.12 1.07 0 0.04 0 0.07 0.01 0.1l0.15 1.14v0.01c0.34 2.36 0.85 4.69 1.53 6.98v0.03c0.11 0.36 0.22 0.72 0.33 1.08 0 0.02 0.01 0.04 0.02 0.06s0.01 0.04 0.02 0.06c0.1 0.32 0.21 0.64 0.33 0.97 0.03 0.08 0.06 0.16 0.09 0.24l0.3 0.84c0.05 0.13 0.1 0.25 0.14 0.38 0.09 0.23 0.18 0.46 0.27 0.69 0.07 0.17 0.14 0.35 0.21 0.52 0.07 0.18 0.15 0.36 0.23 0.54 0.09 0.22 0.19 0.44 0.29 0.66l0.18 0.39c0.12 0.26 0.24 0.53 0.37 0.79 0.04 0.08 0.08 0.17 0.12 0.25 0.14 0.3 0.29 0.6 0.44 0.9 0.03 0.05 0.05 0.09 0.07 0.14 0.17 0.33 0.34 0.66 0.52 0.98 0 0.01 0.02 0.03 0.02 0.04 0.04 0.07 0.08 0.13 0.12 0.2l25.24-15.21 0.09-0.06c-1-2.1-1.62-4.33-1.85-6.6z"
                    />
                    <path
                        className="cls-1"
                        d="m78.39 200.5-20.89 20.89c0.11 0.1 0.21 0.2 0.32 0.3 0.19 0.18 0.39 0.35 0.59 0.52l0.3 0.27c0.24 0.2 0.47 0.4 0.71 0.6 0.06 0.05 0.13 0.11 0.19 0.16 0.27 0.22 0.54 0.44 0.81 0.65 0.03 0.03 0.07 0.06 0.1 0.08 0.3 0.23 0.59 0.46 0.89 0.69 0.01 0 0.02 0.02 0.03 0.02 1.25 0.94 2.55 1.82 3.89 2.63 0.05 0.03 0.09 0.06 0.14 0.08 0.25 0.15 0.51 0.3 0.77 0.45 0.16 0.09 0.31 0.18 0.47 0.27 0.15 0.09 0.3 0.17 0.45 0.25 0.27 0.15 0.54 0.3 0.81 0.44 0.04 0.02 0.07 0.04 0.11 0.06 0.76 0.4 1.53 0.76 2.3 1.12l0.74-1.79 10.47-25.43 0.04-0.09c-1.14-0.61-2.24-1.33-3.27-2.18z"
                    />
                    <path
                        className="cls-5"
                        d="m76.23 198.42c-0.22-0.25-0.44-0.51-0.65-0.77-0.19-0.23-0.37-0.46-0.54-0.7-0.2-0.27-0.39-0.54-0.58-0.82s-0.37-0.56-0.54-0.84l-25.27 15.23c0.39 0.64 0.79 1.27 1.21 1.89 0.01 0.02 0.03 0.04 0.04 0.07 0 0 0 0.02 0.01 0.02 0.01 0.02 0.03 0.04 0.04 0.06 0.03 0.05 0.07 0.1 0.1 0.15 0.44 0.65 0.89 1.29 1.35 1.91 0.01 0.01 0.02 0.03 0.03 0.04 0 0.01 0.02 0.03 0.03 0.04 0.2 0.27 0.4 0.54 0.61 0.8 0.03 0.03 0.05 0.07 0.08 0.1 0.21 0.27 0.43 0.54 0.65 0.81 0.05 0.06 0.11 0.13 0.16 0.19 0.2 0.24 0.4 0.48 0.6 0.71 0.09 0.1 0.18 0.2 0.27 0.3 0.17 0.2 0.35 0.39 0.52 0.59 0.1 0.11 0.2 0.21 0.3 0.32 0.05 0.05 0.09 0.1 0.13 0.15l0.39 0.42c0.05 0.06 0.11 0.11 0.16 0.16 0.23 0.24 0.46 0.48 0.7 0.72l20.85-20.85c-0.24-0.24-0.46-0.48-0.68-0.73z"
                    />
                    <path
                        className="cls-1"
                        d="m103.86 200.49c-0.23 0.19-0.46 0.37-0.7 0.54l0.05 0.09 15.2 25.23c0.7-0.46 1.4-0.93 2.08-1.43 1.47-1.08 2.9-2.26 4.27-3.53l-20.82-20.82z"
                    />
                    <path
                        className="cls-2"
                        d="m116.21 226.56-0.52-0.86-14.19-23.56c-0.29 0.17-0.58 0.33-0.87 0.49-0.3 0.16-0.59 0.31-0.9 0.45-2.73 1.29-5.68 1.95-8.63 1.95-1.94 0-3.87-0.28-5.74-0.84-0.32-0.1-0.63-0.22-0.94-0.33s-0.63-0.21-0.94-0.33l-10.46 25.41-0.41 1-0.35 0.85s0.05 0.02 0.08 0.04c0.03 0.01 0.06 0.02 0.09 0.03h0.02c0.25 0.1 0.5 0.19 0.74 0.28l0.93 0.36c0.16 0.06 0.32 0.13 0.48 0.18 3.28 1.16 6.67 1.97 10.12 2.42 0.13 0.02 0.27 0.04 0.4 0.05 0.14 0.02 0.28 0.03 0.42 0.05 0.27 0.03 0.53 0.06 0.8 0.09 0.07 0 0.13 0.01 0.2 0.02 0.33 0.03 0.65 0.06 0.98 0.08 0.11 0 0.22 0.01 0.33 0.02 0.29 0.02 0.57 0.04 0.86 0.05 0.18 0 0.35 0.01 0.53 0.02 0.23 0 0.45 0.02 0.68 0.02 0.33 0 0.66 0.01 0.99 0.01h0.23c2.76 0 5.51-0.23 8.23-0.69 0 0 0.09-0.01 0.14-0.02 0.29-0.05 0.58-0.1 0.86-0.16 0.16-0.03 0.33-0.06 0.49-0.09 0.17-0.03 0.34-0.07 0.51-0.11l0.84-0.18 0.15-0.03c4.14-0.97 8.14-2.46 11.9-4.44 0.25-0.13 0.49-0.28 0.73-0.41 0.29-0.16 0.58-0.32 0.87-0.49 0.2-0.12 0.4-0.22 0.6-0.34l-0.04-0.06 0.04 0.06s0.02-0.02 0.03-0.02c0.07-0.04 0.13-0.08 0.2-0.12 0 0-0.52-0.86-0.52-0.86z"
                    />
                    <path
                        className="cls-2"
                        d="m78.41 169.37c0.23-0.19 0.46-0.37 0.7-0.54l-0.05-0.09-15.2-25.23c-0.71 0.46-1.4 0.94-2.09 1.44-1.47 1.08-2.89 2.26-4.26 3.52l20.82 20.82z"
                    />
                    <path
                        className="cls-1"
                        d="m56.06 149.86c-0.24 0.24-0.46 0.48-0.69 0.72s-0.47 0.48-0.69 0.72c-1.54 1.67-2.94 3.41-4.21 5.22-0.06 0.09-0.12 0.17-0.18 0.26-0.14 0.21-0.28 0.42-0.42 0.62-0.15 0.22-0.29 0.44-0.43 0.66-0.05 0.08-0.11 0.16-0.16 0.24-4.79 7.51-7.36 16.03-7.7 24.63-0.01 0.33-0.02 0.67-0.03 1 0 0.33-0.02 0.67-0.02 1h29.49c0-0.33 0.03-0.67 0.05-1s0.02-0.67 0.05-1c0.38-3.84 1.86-7.59 4.45-10.74 0.21-0.26 0.45-0.5 0.67-0.74 0.22-0.25 0.43-0.5 0.67-0.74z"
                    />
                    <path
                        className="cls-1"
                        d="m116.9 142.54s-0.08-0.05-0.12-0.07c-0.26-0.16-0.52-0.31-0.78-0.47-0.15-0.09-0.3-0.17-0.46-0.26-0.15-0.09-0.31-0.17-0.46-0.26-0.27-0.15-0.53-0.29-0.8-0.43-0.04-0.02-0.08-0.04-0.13-0.07-1.73-0.9-3.5-1.7-5.32-2.39-0.05-0.02-0.09-0.04-0.14-0.05-0.39-0.15-0.79-0.29-1.19-0.43-3.22-1.12-6.55-1.91-9.94-2.36-0.14-0.02-0.28-0.04-0.41-0.06-0.14-0.02-0.28-0.03-0.41-0.05-0.27-0.03-0.53-0.06-0.8-0.09-0.07 0-0.15-0.01-0.22-0.02-0.32-0.03-0.64-0.06-0.95-0.08-0.12 0-0.25-0.02-0.37-0.02-0.27-0.02-0.55-0.04-0.82-0.05-0.15 0-0.29-0.01-0.43-0.02v29.56c1.52 0.16 3.02 0.48 4.48 0.97l21.75-21.75c-0.81-0.56-1.62-1.1-2.46-1.61z"
                    />
                    <path
                        className="cls-5"
                        d="m90.14 135.35c-0.33 0-0.67 0-1 0.02-2.09 0.08-4.17 0.3-6.23 0.64-0.05 0-0.09 0.01-0.14 0.02-0.29 0.05-0.58 0.1-0.86 0.16-0.16 0.03-0.33 0.06-0.49 0.09-0.17 0.03-0.34 0.07-0.51 0.11l-0.84 0.18-0.15 0.03c-4.14 0.97-8.14 2.46-11.9 4.44-0.25 0.13-0.48 0.28-0.73 0.41-0.29 0.16-0.58 0.33-0.87 0.49-0.22 0.12-0.43 0.24-0.65 0.37-0.01 0-0.03 0.02-0.05 0.03-0.06 0.03-0.11 0.06-0.17 0.1 0 0 0.52 0.86 0.52 0.86l14.71 24.41c0.29-0.17 0.58-0.33 0.87-0.49 0.3-0.16 0.59-0.31 0.9-0.45 2.1-1 4.33-1.62 6.6-1.85 0.33-0.03 0.67-0.06 1-0.08s0.67-0.03 1-0.03v-29.49c-0.33 0-0.67 0.01-1 0.02z"
                    />
                    <path
                        className="cls-1"
                        d="m140.68 182.49c-0.01-0.26-0.03-0.53-0.05-0.79 0-0.13-0.02-0.26-0.03-0.4-0.02-0.31-0.05-0.62-0.08-0.93 0-0.08-0.01-0.16-0.02-0.24-0.03-0.35-0.07-0.7-0.11-1.04 0-0.03 0-0.06-0.01-0.08v-0.04c-0.05-0.38-0.1-0.75-0.15-1.12v-0.03c-0.34-2.35-0.85-4.68-1.52-6.97 0-0.02 0-0.03-0.01-0.04-0.11-0.36-0.22-0.71-0.33-1.06-0.02-0.05-0.03-0.09-0.04-0.14-0.1-0.32-0.21-0.64-0.32-0.95-0.03-0.09-0.06-0.17-0.09-0.26-0.1-0.27-0.2-0.55-0.3-0.82l-0.15-0.39c-0.09-0.23-0.18-0.45-0.27-0.68-0.07-0.18-0.14-0.36-0.22-0.53-0.07-0.18-0.15-0.35-0.22-0.53-0.1-0.22-0.2-0.45-0.29-0.67-0.06-0.13-0.11-0.25-0.17-0.38-0.12-0.27-0.25-0.53-0.37-0.8l-0.12-0.24c-0.15-0.3-0.3-0.61-0.45-0.91-0.02-0.04-0.04-0.08-0.07-0.13-0.17-0.33-0.34-0.66-0.52-0.99 0-0.01-0.01-0.02-0.02-0.04-0.86-1.58-1.8-3.11-2.82-4.58l-21.76 21.76c0.5 1.46 0.82 2.96 0.97 4.48h29.56c0-0.15 0-0.29-0.02-0.44z"
                    />
                    <path
                        className="cls-5"
                        d="m111.25 184.93c0 0.33-0.03 0.67-0.05 1s-0.02 0.67-0.05 1c-0.38 3.84-1.86 7.59-4.45 10.74-0.21 0.26-0.45 0.5-0.67 0.74-0.22 0.25-0.43 0.5-0.67 0.74l20.85 20.85c0.24-0.24 0.46-0.48 0.69-0.72s0.47-0.48 0.69-0.72c1.54-1.67 2.95-3.42 4.22-5.24l0.15-0.21c0.16-0.22 0.31-0.45 0.46-0.67 0.13-0.2 0.27-0.4 0.4-0.61 0.06-0.1 0.13-0.2 0.19-0.3 4.78-7.51 7.34-16.02 7.68-24.61 0.01-0.33 0.02-0.67 0.03-1 0-0.33 0.02-0.67 0.02-1h-29.49z"
                    />
                </g>
                <path
                    className="cls-3"
                    d="m149.5 126.57c-5.39-5.39-14.14-5.39-19.54 0-4.3 4.3-5.16 10.74-2.6 15.9l-30.09 30.09c-5.17-2.56-11.6-1.7-15.9 2.6-5.4 5.39-5.39 14.14 0 19.54 5.4 5.4 14.14 5.39 19.54 0 4.3-4.3 5.16-10.74 2.6-15.9l30.09-30.09c5.17 2.56 11.6 1.7 15.9-2.6 5.39-5.39 5.39-14.14 0-19.54z"
                />
            </g>
        </g>
    </>,
    'OAS',
);
