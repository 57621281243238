import { SerializedError } from '@reduxjs/toolkit';

export default async function wrapPromise<T>(promise: Promise<T>): Promise<{ data: T } | { error: SerializedError }> {
    try {
        return { data: await promise };
    } catch (e) {
        if (e instanceof Error) {
            return {
                error: {
                    name: e.name,
                    message: e.message,
                    stack: e.stack,
                },
            };
        }
        return {
            error: {
                message: '' + e,
            },
        };
    }
}
