import React from 'react';
import { Dialog, DialogContentText, DialogTitle, Button, DialogActions, DialogContent } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import { IamGroup, IamGroupMemberList } from '../../../../repository/models/IamGroup';
import { useDeleteRealmGroupMutation } from '../../api';
import BusyButton from '../../../../BusyButton';
import { ServerErrorMessage } from '../../../../ui/ServerErrorMessage';
import RemapPalette from '../../../../ui/theme/RemapPalette';
import { IAM_REALM_GROUPS } from '../../../routes';
import { Organization } from '../../../../repository/models/Organization';
import { IamRealm } from '../../../../repository/models/IamRealm';

interface DeleteGroupDialogProps {
    group: IamGroup;
    memberList: IamGroupMemberList | null;
    opened: boolean;
    org: Organization | null;
    realm?: IamRealm;
    onClose: () => void;
}

export const DeleteGroupDialog = ({ group, memberList, opened, org, realm, onClose }: DeleteGroupDialogProps) => {
    const navigate = useNavigate();
    const members = memberList?._embedded?.members ?? [];
    const [deleteGroup, { isLoading, error }] = useDeleteRealmGroupMutation();

    const handleClick = async () => {
        await deleteGroup(group).unwrap();
        onClose();
        navigate(IAM_REALM_GROUPS.generate({ org: org?.name!, realm: realm?.id! }));
    };

    return (
        <Dialog open={opened} maxWidth="lg" onClose={onClose}>
            {!!error ? <ServerErrorMessage error={error} /> : null}

            <DialogTitle>Delete group {group.name}</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete group <b>{group.name}</b>?
                </DialogContentText>

                <DialogContentText>
                    This group has {members.length} members. All membership info will be lost.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <RemapPalette from="danger" to="primary">
                    <BusyButton
                        color="primary"
                        busy={isLoading}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                    >
                        Delete
                    </BusyButton>
                </RemapPalette>
            </DialogActions>
        </Dialog>
    );
};
