import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import RemapPalette from '../../../ui/theme/RemapPalette';
import BusyButton from '../../../BusyButton';

interface EventHandlerFormFooterProps {
    isCreating?: boolean;
    parentUrl: string;
    disabledSave: boolean;
    loading?: boolean;
    onSave: () => void;
    onRemove?: () => void;
}

export const EventHandlerFormFooter = ({
    isCreating,
    parentUrl,
    disabledSave,
    loading,
    onSave,
    onRemove,
}: EventHandlerFormFooterProps) => {
    const { footer } = useStyles();
    const navigate = useNavigate();

    const handleSaveClick = useCallback(() => {
        navigate(parentUrl);
        if (!disabledSave) {
            onSave();
        }
    }, [navigate, onSave, parentUrl, disabledSave]);

    const handleRemove = () => {
        if (!!onRemove) {
            onRemove();
        }
    };

    return (
        <div className={footer}>
            <Button component={NavLink} to={parentUrl}>
                Cancel
            </Button>

            {!isCreating && (
                <>
                    <RemapPalette from="danger" to="primary">
                        <Button variant="contained" color="primary" onClick={handleRemove}>
                            Delete
                        </Button>
                    </RemapPalette>
                </>
            )}

            <BusyButton
                busy={!!loading}
                variant="contained"
                color="primary"
                disabled={disabledSave}
                onClick={handleSaveClick}
            >
                Save
            </BusyButton>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            display: 'flex',
            justifyContent: 'end',
            padding: theme.spacing(1),
            gap: theme.spacing(2),
        },
    }),
);
