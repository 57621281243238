import { combineReducers, createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { Project } from '../../repository/models/Project';
import { AppState } from '../../store';
import { AppThunkAction } from '../../store/store';
import { selectCurrentOrganization } from '../account/accountSlice';
import { BLUEPRINT_MODEL, BLUEPRINT_ROOT, LeafRoute, PROJECT_ROOT, replace, replaceRouterVariable } from '../routes';
import { getRouterUrlParams, isRouterUrlVisit } from '../routes/routeSlice';
import projectsApi from './api';
import { skipToken } from '@reduxjs/toolkit/query';

export const clearCurrentProject = createAction('project/clearCurrent');

export function projectSetCurrentProject(project: Project): AppThunkAction<void> {
    return (dispatch) => {
        dispatch(
            replaceRouterVariable({
                route: BLUEPRINT_ROOT,
                params: {
                    org: project.organization,
                    project: project.name,
                    blueprint: 'main',
                },
            }),
        );
    };
}

export function projectSetCreatedProject(project: Project): AppThunkAction<void> {
    return (dispatch) => {
        dispatch(
            replaceRouterVariable({
                route: BLUEPRINT_MODEL,
                params: {
                    org: project.organization,
                    project: project.name,
                    blueprint: 'main',
                },
            }),
        );
    };
}

// Like the above, but uses history.replace instead of history.push, so no history entry is created.
// This causes the back button to still work in case of automatic redirects
export function projectSetCurrentProjectWithoutHistory(project: Project): AppThunkAction<void> {
    return (dispatch) => {
        dispatch(
            replaceRouterVariable({
                route: PROJECT_ROOT,
                params: {
                    org: project.organization,
                    project: project.name,
                },
                operation: replace,
            }),
        );
    };
}

interface ProjectContext {
    readonly org: string;
    readonly project: string;
}

const projectContextReducer = createReducer<ProjectContext | null>(null, (builder) =>
    builder
        .addCase(clearCurrentProject, () => null)
        .addMatcher(isRouterUrlVisit(PROJECT_ROOT.wildcard), (state, action) => {
            const match = getRouterUrlParams(PROJECT_ROOT.wildcard, action);
            if (match !== null) {
                return match.params;
            }
            return state;
        }),
);

export default combineReducers({
    currentContext: projectContextReducer,
});

function selectSlice(state: AppState) {
    return state.project;
}

export const selectProjects = createSelector(
    (state: AppState) => state,
    selectCurrentOrganization,
    (state, org) => {
        const data = projectsApi.endpoints.getProjectListForOrg.select(org ?? skipToken)(state);
        if (data.isLoading) {
            return [];
        }
        return data.data?._embedded?.projects ?? [];
    },
);

export const selectCurrentProjectContext = createSelector(selectSlice, selectCurrentOrganization, (slice, org) => {
    if (slice.currentContext?.org === org?.name) {
        return slice.currentContext;
    }
    return null;
});

export const selectCurrentProject = createSelector(selectCurrentProjectContext, selectProjects, (context, projects) => {
    if (!context) {
        return null;
    }
    return projects.filter((project) => project.name === context.project)[0] ?? null;
});

// This is a bit of an unconventional selector: instead of just returning a data-object,
// it returns a function. This function is used to generate paths for different routes.
export const selectCurrentProjectRouteGenerator = createSelector(
    selectCurrentProjectContext,
    (context) =>
        function generateRoute<Variables extends keyof ProjectContext>(
            target: LeafRoute<string, Variables>,
            additionalParams: Record<Exclude<Variables, keyof ProjectContext>, string>,
        ) {
            if (context) {
                return target.generate({
                    ...context,
                    ...additionalParams,
                });
            }
            return null;
        },
);
