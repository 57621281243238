import { ListItem, Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

import { Entity } from '../repository/models/Entity';
import { StyledNavLink } from './styles/StyledNavLink';

type EntityListItemProps = {
    entity: Entity;
    selected: boolean;
};
export const EntityListItem = ({ entity, selected }: EntityListItemProps): React.ReactElement => {
    const { linkClasses } = useStyles();

    return (
        <ListItem
            selected={selected}
            component={StyledNavLink}
            key={entity.name}
            to={'?entity=' + entity.name}
            className={linkClasses}
        >
            {entity.name}
        </ListItem>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        linkClasses: {
            border: '1px solid',
            borderRight: 0,
            borderColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            marginBottom: theme.spacing(1),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'block',
            '&:last-child': {
                marginBottom: theme.spacing(2),
            },
            '&.is-selected': {
                backgroundColor: theme.palette.primary.light,
            },
        },
    }),
);
