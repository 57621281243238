import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

import { Row } from '../../../ui/table/Row';
import { Cell } from '../../../ui/table/Cell';

interface EventHandlerEditFormRowProps {
    label: string;
    htmlFor?: string;
    children: ReactNode;
}

export const EventHandlerEditFormRow = ({ label, htmlFor, children }: EventHandlerEditFormRowProps) => {
    return (
        <Row>
            <Cell isLarge width={20}>
                <Typography variant="body2">
                    <label htmlFor={htmlFor}>{label}</label>
                </Typography>
            </Cell>
            <Cell isLarge>{children}</Cell>
        </Row>
    );
};
