import React, { useState, useRef, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Box, Button, IconButton, styled } from '@material-ui/core';

import TextInput from './TextInput';

interface TextInputListProps {
    kindTitle: string;
    list: readonly string[];
    onChange?: (newList: readonly string[]) => void;
}

export const TextInputList = ({ kindTitle, list, onChange }: TextInputListProps) => {
    const [focusedIndex, setFocusedIndex] = useState<number>(0);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, list.length);
    }, [list]);

    const handleKeyPress = (key: string, index: number) => {
        switch (key) {
            case 'Enter':
                if (onChange && index === list.length - 1) {
                    onChange([...list, '']);
                    setFocusedIndex(list.length);
                } else if (index < list.length - 1) {
                    setFocusedIndex(index + 1);
                }
                break;
            case 'ArrowUp':
                if (index > 0) {
                    setFocusedIndex(index - 1);
                }
                break;
            case 'ArrowDown':
                if (index < list.length - 1) {
                    setFocusedIndex(index + 1);
                }
                break;
        }
    };

    useEffect(() => {
        inputRefs.current[focusedIndex]?.focus();
    }, [focusedIndex]);

    return (
        <StyledWrapper marginLeft={2}>
            <Box display="flex" flexDirection="column" width="100%">
                {list.map((item, index) => {
                    return (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
                            <TextInput
                                disabled={!onChange}
                                fullWidth
                                autoFocus={index === focusedIndex}
                                value={item}
                                handleOnChange={(value) =>
                                    onChange && onChange(list.map((val, idx) => (idx === index ? value : val)))
                                }
                                handleOnKeyDown={(key) => handleKeyPress(key, index)}
                                inputRef={(el) => (inputRefs.current[index] = el)}
                            />

                            {onChange ? (
                                <StyledButton
                                    color="secondary"
                                    onClick={() => {
                                        onChange(list.filter((_value, i) => i !== index));
                                        setFocusedIndex(Math.min(focusedIndex, list.length - 2));
                                    }}
                                >
                                    <RemoveIcon />
                                </StyledButton>
                            ) : null}
                        </Box>
                    );
                })}
            </Box>

            {onChange ? (
                <StyledAddButton
                    variant="text"
                    color="primary"
                    disableRipple
                    onClick={() => {
                        onChange([...list, '']);
                        setFocusedIndex(list.length);
                    }}
                >
                    <AddIcon fontSize="small" />
                    &nbsp;Add {kindTitle}
                </StyledAddButton>
            ) : null}
        </StyledWrapper>
    );
};

const StyledWrapper = styled(Box)(() => ({
    flex: '1 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    maxWidth: '40rem',
}));

const StyledButton = styled(IconButton)(() => ({
    padding: 0,
    marginLeft: '1rem',
}));

const StyledAddButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(5),
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
    },
}));
