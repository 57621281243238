import { Grid, MenuItem, Theme, createStyles, makeStyles, alpha } from '@material-ui/core';
import React, { BaseSyntheticEvent, useState } from 'react';
import Select from '@material-ui/core/Select';
import cx from 'classnames';
import { MenuProps } from '@material-ui/core/Menu';

import { Entity } from '../../../repository/models/Entity';
import TextInput from '../../../ui/input/TextInput';
import { EventHandlerEditFormRow } from './EventHandlerEditFormRow';
import { EventHandlerFormFooter } from './EventHandlerFormFooter';
import { EventHandlerCreationDto } from '../../../repository/models/EventHandlerDto';
import { EventHandler } from '../types';
import { EventHandlerFormEventTypes } from './EventHandlerFormEventTypes';
import { SerializedError } from '@reduxjs/toolkit';
import { ServerErrorMessage } from '../../../ui/ServerErrorMessage';

interface EventHandlerFormProps {
    entities: readonly Entity[];
    parentUrl: string;
    eventHandler?: EventHandler;
    loading?: boolean;
    error?: SerializedError;
    onSave: (eventHandler: EventHandlerCreationDto) => void;
    onRemove?: () => void;
}

const initialEventHandler = (entities: Entity[]) => {
    const name = entities?.[0]?.name ?? '';
    return {
        id: '',
        name: '',
        webhook_url: '',
        events: {
            create: false,
            update: false,
            delete: false,
            content: false,
        },
        entityName: name,
    };
};

export const EventHandlerForm = ({
    entities,
    parentUrl,
    eventHandler,
    loading,
    error,
    onSave,
    onRemove,
}: EventHandlerFormProps) => {
    const initializeData = !!eventHandler ? eventHandler : initialEventHandler(entities as Entity[]);
    const [state, setState] = useState(initializeData);

    const saveEventHandler = () => {
        const eventHandler: EventHandlerCreationDto = {
            entity: state.entityName,
            create: state.events.create,
            content: state.events.content,
            update: state.events.update,
            delete: state.events.delete,
            description: state.name,
            endpoint: state.webhook_url,
        };
        onSave(eventHandler);
    };

    return (
        <Grid container direction="column" spacing={4}>
            <EventHandlerFormName
                name="Name:"
                value={state.name}
                onChange={(value) => setState({ ...state, name: value })}
            />

            <EventHandlerFormSelect
                name="For entity:"
                value={state.entityName}
                options={entities}
                onChange={(value) => setState({ ...state, entityName: value })}
            />

            <EventHandlerFormEventTypes
                name="Notify me for:"
                events={state.events}
                onChange={(value) => setState({ ...state, events: value })}
            />

            <EventHandlerEditFormRow label="Webhook URL:" htmlFor="webhook-url">
                <TextInput
                    id="webhook-url"
                    value={state.webhook_url}
                    placeholder="https://example.com/postreceive"
                    handleOnChange={(value) => setState({ ...state, webhook_url: value })}
                    fullWidth
                />
            </EventHandlerEditFormRow>

            {!!error ? <ServerErrorMessage error={error} /> : null}

            <EventHandlerFormFooter
                isCreating={!eventHandler}
                parentUrl={parentUrl}
                disabledSave={!state.name || !state.webhook_url}
                loading={loading}
                onSave={saveEventHandler}
                onRemove={onRemove}
            />
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            minWidth: '10rem',
            paddingLeft: '0.5rem',
            border: '1px solid #cdd',
            backgroundColor: '#fff',
            borderRadius: '0.25rem',
            overflow: 'hidden',
            transition: 'border-color 200ms ease-in',
            minHeight: '39px',
            '&:after': {
                display: 'none',
            },
            '&:before': {
                display: 'none',
            },
            '& .MuiSelect-select': {
                backgroundColor: '#fff',
            },
            '&:hover': {
                borderColor: theme.palette.primary.main,
                transition: 'border-color 200ms ease-out',
            },
            '&.Mui-focused': {
                boxShadow: alpha(theme.palette.primary.main, 0.5) + ' 0 0 0px 3px',
            },
        },
        focusedList: {
            borderColor: theme.palette.primary.main,
            transition: 'border-color 200ms ease-out',
        },
        listItem: {
            backgroundColor: '#fff',
            transition: 'background-color 200ms ease-in',
            borderRadius: '0.25rem',
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
                transition: 'background-color 200ms ease-out',
            },
            '&.Mui-selected': {
                backgroundColor: theme.palette.primary.light,
                '&:hover': {
                    backgroundColor: theme.palette.primary.light,
                },
            },
        },
    }),
);

interface EventHandlerFormNameProps {
    name: string;
    value: string;
    onChange: (value: string) => void;
}

const EventHandlerFormName = ({ name, value, onChange }: EventHandlerFormNameProps) => {
    return (
        <EventHandlerEditFormRow label={name} htmlFor="name">
            <TextInput id="name" value={value} handleOnChange={onChange} fullWidth />
        </EventHandlerEditFormRow>
    );
};

interface EventHandlerFormSelectProps {
    name: string;
    value: string;
    options: readonly Entity[];
    onChange: (value: string) => void;
}

const inputProps = { 'aria-label': 'select' };
const menuProps = {
    PaperProps: {
        style: {
            maxHeight: '10rem',
            width: '10rem',
            variant: 'selectedMenu',
            marginLeft: '-9px',
            marginTop: '3px',
        },
    },
    MenuListProps: {
        style: {
            padding: '0.5rem',
        },
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
} as Partial<MenuProps>;

const EventHandlerFormSelect = ({ name, value, options, onChange }: EventHandlerFormSelectProps) => {
    const [opened, setOpened] = useState(false);

    const handleChange = (e: BaseSyntheticEvent) => {
        const value = e.target.value;
        onChange(value);
    };

    const { focusedList, list, listItem } = useStyles();

    const classes = cx(list, {
        [focusedList]: opened,
    });

    return (
        <EventHandlerEditFormRow label={name} htmlFor="select">
            <Select
                id="select"
                value={value}
                displayEmpty
                inputProps={inputProps}
                className={classes}
                MenuProps={menuProps}
                onChange={handleChange}
                onClose={() => setOpened(false)}
                onOpen={() => setOpened(true)}
            >
                {!!options &&
                    options.map((option) => (
                        <MenuItem className={listItem} key={option.name} value={option.name}>
                            {option.name}
                        </MenuItem>
                    ))}
            </Select>
        </EventHandlerEditFormRow>
    );
};
