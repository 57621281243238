import { Button, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { selectCurrentUser, selectCurrentUserLoading } from '../account/accountSlice';
import { User } from '../../repository/models/User';
import { AppState } from '../../store';

type AuthLoginProps = {
    currentUserLoading: boolean;
    currentUser: User | null;
    children: ReactNode;
};
function AuthLogin({ children, currentUser, currentUserLoading }: AuthLoginProps) {
    const auth = useAuth();
    const [signinAttempted, setSigninAttempted] = useState<boolean>(false);
    useEffect(() => {
        if (!auth.isAuthenticated && !signinAttempted) {
            auth.signinSilent();
            setSigninAttempted(true);
        }
    }, [signinAttempted, setSigninAttempted, auth]);

    const classes = useStyles();

    if (currentUserLoading) {
        return (
            <div className={classes.center}>
                <CircularProgress />
            </div>
        );
    }

    if (!auth.isAuthenticated || !currentUser) {
        return (
            <div className={classes.center}>
                <Typography>You must log in before using the application.</Typography>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                        void auth.signinRedirect({
                            redirect_uri: window.location.href,
                        })
                    }
                >
                    Login
                </Button>
            </div>
        );
    }

    return <>{children}</>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        center: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            '& > *': {
                marginBottom: theme.spacing(2),
            },
        },
    }),
);

export default connect((state: AppState) => ({
    currentUserLoading: selectCurrentUserLoading(state),
    currentUser: selectCurrentUser(state),
}))(AuthLogin);
