import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom';

import { Project } from '../../repository/models/Project';
import { AppDispatch, AppState } from '../../store';
import GridCard from '../../ui/GridCard';
import { HighlightedComponent, projectSelectLocation } from '../tour';
import { projectSetCurrentProject } from './projectSlice';
import { CREATE_PROJECT, ORGANIZATION_ROOT } from '../routes';
import { useGetProjectListForOrgQuery } from './api';
import { Organization } from '../../repository/models/Organization';
import { selectCurrentOrganization } from '../account/accountSlice';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { RequestStateHandler } from '../../ui/RequestStateHandler';

type ProjectListProps = {
    org: Organization | null;
    handleSetCurrentProject: (project: Project) => void;
};

function ProjectList({ org, handleSetCurrentProject }: ProjectListProps) {
    const { data, isLoading, error } = useGetProjectListForOrgQuery(org ?? skipToken);
    if (!org) {
        return null;
    }

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    return (
        <>
            {!data?._embedded || data?._embedded?.projects?.length === 0 ? <NoProjects /> : null}

            {data ? (
                <Box mb={2}>
                    <HighlightedComponent location={projectSelectLocation}>
                        <Button
                            component={NavLink}
                            to={ORGANIZATION_ROOT.wildcard.relativePatternFor(CREATE_PROJECT)}
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            Create Project
                        </Button>
                    </HighlightedComponent>
                </Box>
            ) : null}

            <Grid container spacing={1}>
                {data?._embedded?.projects?.map((project) => (
                    <ProjectCard
                        key={project.name}
                        project={project}
                        handleSetCurrentProject={handleSetCurrentProject}
                    />
                )) ?? null}
            </Grid>
        </>
    );
}

type ProjectCardProps = {
    project: Project;
    handleSetCurrentProject: (project: Project) => void;
};
function ProjectCard({ project, handleSetCurrentProject }: ProjectCardProps) {
    const onClick = useCallback(() => {
        handleSetCurrentProject(project);
    }, [handleSetCurrentProject, project]);

    return (
        <HighlightedComponent location={projectSelectLocation}>
            <GridCard onClick={onClick} primaryText={project.name} typeText="Project" />
        </HighlightedComponent>
    );
}

export default connect(
    (state: AppState) => ({
        org: selectCurrentOrganization(state),
    }),
    (dispatch: AppDispatch) => ({
        handleSetCurrentProject: bindActionCreators(projectSetCurrentProject, dispatch),
    }),
)(ProjectList);

function NoProjects() {
    return (
        <Box mb={2}>
            <Alert severity="info">
                <AlertTitle>Get started by creating a project</AlertTitle>
                <Typography variant="body2">
                    A project keeps your application design, permissions and running applications together.
                </Typography>
                <Typography variant="body2">
                    You can create as many or as few projects as necessary, but keep in mind that projects are totally
                    independent and do not share resources.
                </Typography>
                <Typography variant="body2">
                    It is recommended to create separate projects for unrelated subjects that will not reference each
                    other.
                </Typography>
            </Alert>
        </Box>
    );
}
