import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography, styled } from '@material-ui/core';
import { SerializedError } from '@reduxjs/toolkit';
import { Alert } from '@material-ui/lab';

import { Row } from './Row';
import { RequestStateHandler } from '../RequestStateHandler';

interface IamTableProps {
    isLoading: boolean;
    error?: SerializedError;
    headers: ReactNode;
    children: ReactNode;
    noRecords?: boolean;
    noRecordsText?: string;
}

export const IamTable = ({ isLoading, error, headers, children, noRecords, noRecordsText }: IamTableProps) => {
    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    if (noRecords) {
        return <Alert severity="info">{noRecordsText}</Alert>;
    }

    return (
        <Box>
            <Row borders="horizontal">{headers}</Row>

            {children}
        </Box>
    );
};

export const StyledIamNavLink = styled(NavLink)(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
    },
}));

export const IamTypographyLabel = ({ label, text }: { label: string; text: string }) => {
    return (
        <Typography variant="body2" color="textSecondary" gutterBottom>
            <Box component="span" display="inline-block" width={100}>
                {label}:
            </Box>
            <Typography color="textPrimary" component="span">
                {text}
            </Typography>
        </Typography>
    );
};
