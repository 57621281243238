import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface CardTitleProps {
    children: ReactNode;
}

export const CardTitle = ({ children }: CardTitleProps) => {
    const { title } = useStyles();
    return (
        <Typography className={title} variant="body1" component="h6">
            <Box sx={{ fontWeight: 600 }}>{children}</Box>
        </Typography>
    );
};

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            marginBottom: '1rem',
        },
    }),
);
