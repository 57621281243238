import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { ReactChild } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BLUEPRINT_MODEL, BLUEPRINT_ROOT } from '../routes';

interface DataModelRequiredProps {
    systemName?: string;
    children?: ReactChild;
}

export default function DataModelRequired(props: DataModelRequiredProps) {
    const path = useLocation().pathname;

    const dataModelPath = BLUEPRINT_ROOT.wildcard.navigate(path, BLUEPRINT_MODEL);

    return (
        <Alert
            severity="warning"
            action={
                <Button
                    component={Link}
                    to={dataModelPath!}
                    variant="contained"
                    color="primary"
                    style={{ whiteSpace: 'nowrap', marginRight: '1em' }}
                    size="small"
                >
                    Data model
                </Button>
            }
        >
            <AlertTitle>
                A data model is required
                {props.systemName ? ` to set up ${props.systemName}` : null}.
            </AlertTitle>
            {props.children ?? null}
        </Alert>
    );
}
