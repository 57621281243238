import React, { useCallback, useState } from 'react';
import { Box, Button, IconButton, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { Check, Close, Edit } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { skipToken } from '@reduxjs/toolkit/query';

import { IamRealm } from '../../repository/models/IamRealm';
import { Overview, OverviewItem, OverviewRow, OverviewSubItem } from '../../ui/Overview';
import { useGetRealmOverviewQuery, useRenameIamRealmMutation } from './api';
import RelativeTime from '@yaireo/relative-time';
import { CopyText } from '../../ui/CopyText';
import { RequestStateHandler } from '../../ui/RequestStateHandler';
import { resolveTemplate } from '../../hal';
import { ControlsWrapper } from '../../ui/input/ControlsWrapper';
import TextInput from '../../ui/input/TextInput';
import { CircularProgress } from '@material-ui/core';
import RemapPalette from '../../ui/theme/RemapPalette';
import { ServerErrorMessage } from '../../ui/ServerErrorMessage';

interface IamRealmOverviewProps {
    realm?: IamRealm;
}

const relativeTime = new RelativeTime();

export const IamRealmOverview = ({ realm }: IamRealmOverviewProps) => {
    const {
        data: overview,
        isLoading,
        error,
    } = useGetRealmOverviewQuery(realm ?? skipToken, {
        refetchOnMountOrArgChange: true,
    }) ?? realm;

    if (isLoading || !!error) {
        <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    const absentValue = isLoading ? <CircularProgress size={16} /> : <span>—</span>;
    const [created, updated] = React.useMemo(
        () => [
            !!overview?.created_at ? relativeTime.from(new Date(overview.created_at)) : null,
            !!overview?.updated_at ? relativeTime.from(new Date(overview.updated_at)) : null,
        ],
        [overview],
    );

    if (!overview) {
        return null;
    }

    return (
        <Overview title="IAM Realm Information">
            <OverviewRow>
                <OverviewItem grow={1} name="Name">
                    {!!realm ? <RealmName realm={realm} /> : null}
                </OverviewItem>
                <OverviewItem grow={1} name="Zone">
                    {overview.zone ? overview.zone : absentValue}
                </OverviewItem>
            </OverviewRow>
            <OverviewRow>
                <OverviewItem name="Users">{overview.stats?.users ?? absentValue}</OverviewItem>
                <OverviewItem name="Groups">{overview.stats?.groups ?? absentValue}</OverviewItem>
                <OverviewItem name="Attributes">{overview.stats?.attributes ?? absentValue}</OverviewItem>
                <OverviewItem name="Clients">{overview.stats?.clients ?? absentValue}</OverviewItem>
                <OverviewItem name="SSO IdP">{overview.stats?.sso ?? absentValue}</OverviewItem>
            </OverviewRow>
            <OverviewRow>
                <OverviewItem name="Created">{created ?? absentValue}</OverviewItem>
                <OverviewItem name="Last Update">{updated ?? absentValue}</OverviewItem>
            </OverviewRow>
            <OverviewRow>
                <Box flexGrow={1}>
                    <OverviewSubItem label="Issuer URI">
                        <CopyText text={overview._links['issuer-uri'].href} />
                    </OverviewSubItem>
                    <OverviewSubItem label="Internal Reference">
                        {overview?.internal_reference ? (
                            <CopyText text={overview.internal_reference}></CopyText>
                        ) : (
                            absentValue
                        )}
                    </OverviewSubItem>
                </Box>
            </OverviewRow>
        </Overview>
    );
};

interface RealmNameProps {
    realm: IamRealm;
}

const RealmName = ({ realm }: RealmNameProps) => {
    const name = realm.name;
    const [newName, setNewName] = useState<string | null>(null);
    const [renameIamRealmName, { isLoading, error, reset }] = useRenameIamRealmMutation();

    const handleClose = useCallback(() => {
        setNewName(null);
        reset();
    }, [setNewName, reset]);

    const handleRename = useCallback(async () => {
        if (!!newName && newName !== name) {
            await renameIamRealmName({ realm, newName: newName }).unwrap();
            handleClose();
        }
    }, [newName, name, realm, renameIamRealmName, handleClose]);

    const classes = useStyles();

    const template = resolveTemplate(realm, 'default');

    return (
        <Box>
            {newName === null ? (
                <div className={classes.nameWrapper}>
                    <Typography variant="body2">{name}</Typography>
                    {!!template ? (
                        <IconButton className={classes.edit} size="small" onClick={() => setNewName(name)}>
                            <Edit fontSize="inherit" color="disabled" />
                        </IconButton>
                    ) : null}
                </div>
            ) : (
                <>
                    <ControlsWrapper isInlineEditing>
                        <TextInput
                            style={{
                                minWidth: '100px',
                                width: newName ? newName.length + 'ch' : 'auto',
                            }}
                            value={newName}
                            autoFocus
                            handleOnChange={setNewName}
                        />
                        <Button variant="outlined" color="primary" onClick={handleRename}>
                            {isLoading ? <CircularProgress size={16} /> : <Check fontSize="inherit" color="inherit" />}
                        </Button>

                        <RemapPalette from="danger" to="primary">
                            <Button variant="outlined" color="primary" onClick={handleClose}>
                                <Close fontSize="inherit" color="inherit" />
                            </Button>
                        </RemapPalette>
                    </ControlsWrapper>
                    {!!error ? <ServerErrorMessage textView error={error} className={classes.error} /> : null}
                </>
            )}
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        edit: {
            height: theme.spacing(3),
            width: theme.spacing(3),
            marginLeft: theme.spacing(0.5),
            transition: 'none',
            '&:hover': {
                boxShadow: '0 0 0 4px ' + theme.palette.grey[100],
            },
        },
        nameWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        error: {
            fontSize: theme.typography.overline.fontSize,
        },
    }),
);
