import {
    Box,
    Dialog,
    DialogActions,
    Table,
    TableCell,
    TableRow,
    Typography,
    styled,
    Checkbox,
    DialogTitle,
    Button,
    TableHead,
    TableBody,
    FormControlLabel,
} from '@material-ui/core';
import React, { useState } from 'react';
import cx from 'classnames';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Alert } from '@material-ui/lab';

import { IamRealm } from '../../../../repository/models/IamRealm';
import { useGetRealmGroupsQuery, useJoinRealmGroupsMutation } from '../../api';
import { RequestStateHandler } from '../../../../ui/RequestStateHandler';
import { IamGroup, IamGroupList } from '../../../../repository/models/IamGroup';
import BusyButton from '../../../../BusyButton';
import { IamUser, IamUserGroupList } from '../../../../repository/models/IamUser';
import { TypedLink } from '../../../../hal';
import { NavigationButtons } from '../../components/NavigationButtons';

interface GroupsDialogProps {
    user: IamUser | null;
    list: IamUserGroupList | null;
    realm?: IamRealm;
    opened: boolean;
    onClose: () => void;
}

export const GroupsDialog = ({ user, list: userGroupList, realm, opened, onClose }: GroupsDialogProps) => {
    const [selectedGroups, setSelectedGroups] = useState<Omit<IamGroup, 'attributes'>[]>([]);
    const [link, setLink] = useState<TypedLink<IamGroupList>>();
    const [joinRealmGroups, { isLoading: isAddGroupsLoading, error: errorAddGroups }] = useJoinRealmGroupsMutation();

    const {
        data: groupList,
        isLoading,
        error,
    } = useGetRealmGroupsQuery(link ?? realm?._links?.groups?.href ?? skipToken);

    const groups = groupList?._embedded?.groups ?? [];
    const belongedGroups = userGroupList?._embedded?.groups ?? [];

    const handleClick = async () => {
        if (!!selectedGroups.length && !!userGroupList) {
            const links = selectedGroups.map((item) => item._links.self.href);
            await joinRealmGroups({
                groupList: userGroupList,
                groups: links,
            }).unwrap();
            handleClose();
        }
    };

    const handleChange = (group: Omit<IamGroup, 'attributes'>) => {
        const groupLink = group._links.self.href;
        if (!!selectedGroups.find((item) => item._links.self.href === groupLink)) {
            setSelectedGroups(selectedGroups.filter((item) => item._links.self.href !== groupLink));
            return;
        }
        setSelectedGroups([...selectedGroups, group]);
    };

    const handleClose = () => {
        setSelectedGroups([]);
        onClose();
    };

    const handleNext = () => {
        if (!!groupList?._links?.next?.href) {
            setLink(groupList?._links?.next.href);
        }
    };

    const handlePrev = () => {
        if (!!groupList?._links?.prev?.href) {
            setLink(groupList?._links?.prev.href);
        }
    };

    return (
        <Dialog open={opened} maxWidth="lg" onClose={handleClose}>
            <DialogTitle>
                Add <b>{user?.username}</b> to groups
            </DialogTitle>
            <Box marginBottom={3}>
                {isLoading || !!error || !!errorAddGroups ? (
                    <RequestStateHandler isLoading={isLoading} error={error || errorAddGroups} />
                ) : !groups.length ? (
                    <Alert severity="info">You dont have any groups!</Alert>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Box paddingLeft={4}>
                                        <Typography variant="body2">
                                            <b>Name</b>
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groups.map((item) => {
                                const selectedBefore = !!belongedGroups.find(
                                    (belongedGroup) => belongedGroup.groupId === item.groupId,
                                );
                                const classes = cx({
                                    'is-disabled': selectedBefore,
                                });
                                const checked =
                                    !!selectedGroups.find(
                                        (group) => group._links.self.href === item._links.self.href,
                                    ) || selectedBefore;
                                return (
                                    <StyledRow className={classes} key={item.groupId}>
                                        <TableCell size="small">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        style={{ padding: 8 }}
                                                        checked={checked}
                                                        color="primary"
                                                        onClick={() => handleChange(item)}
                                                    />
                                                }
                                                label={<Typography variant="body2">{item.name}</Typography>}
                                            />
                                        </TableCell>
                                    </StyledRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </Box>
            {!!groupList?._links.next || !!groupList?._links.prev ? (
                <NavigationButtons
                    prevDisabled={!groupList?._links.prev?.href}
                    nextDisabled={!groupList._links.next?.href}
                    onPrevClick={handlePrev}
                    onNextClick={handleNext}
                />
            ) : null}
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <BusyButton
                    color="primary"
                    busy={isAddGroupsLoading}
                    variant="contained"
                    disableElevation
                    disabled={!selectedGroups.length}
                    onClick={handleClick}
                >
                    Add
                </BusyButton>
            </DialogActions>
        </Dialog>
    );
};

const StyledRow = styled(TableRow)({
    '&.is-disabled': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
});
