import {
    HalFormsTemplate as HalFormsTemplateModel,
    HalFormsProperty as HalFormsPropertyModel,
    HalEntityWithTemplate,
    TemplateType,
} from './model';
import { unsafeCast } from '../typedLinks';
import { HalResourceObject } from '../resource';
import { HalError } from '../errors';
import { HalFormsProperty, HalFormsTemplate, RequestSpec } from './api';
import { MATCH_ANYTHING, MATCH_NOTHING } from './_internal';

export class HalFormsTemplateImpl<Target> implements HalFormsTemplate<Target> {
    public constructor(
        private readonly entity: HalResourceObject<any>,
        private readonly model: HalFormsTemplateModel<Target>,
    ) {}

    public get request(): RequestSpec<Target> {
        return {
            method: this.model.method,
            url: this.model.target ?? unsafeCast<Target>(this.entity._links.self.href),
        };
    }

    public property(propertyName: string): HalFormsProperty {
        var propertyModel = this.model.properties.find((prop) => prop.name === propertyName);

        return new HalFormsPropertyImpl(propertyName, propertyModel);
    }

    public get properties(): readonly HalFormsProperty[] {
        return this.model.properties.map((prop) => new HalFormsPropertyImpl(prop.name, prop));
    }
}

export class HalFormsPropertyImpl implements HalFormsProperty {
    public constructor(
        public readonly name: string,
        private readonly model: HalFormsPropertyModel | undefined,
    ) {}

    get hint(): string | undefined {
        return this.model?.options?.hint;
    }

    get enabled(): boolean {
        if (!this.model) {
            return false;
        }
        if (this.model.readOnly) {
            return false;
        }
        return true;
    }

    get type(): string | undefined {
        return this.model?.type;
    }

    get options(): ReadonlyArray<{
        readonly prompt: string;
        readonly value: string;
    }> {
        if (!this.model?.options?.inline) {
            return [];
        }
        if (Array.isArray(this.model.options.inline)) {
            return this.model.options.inline.map((opt) => ({
                prompt: opt,
                value: opt,
            }));
        }
        return []; // TODO: implement prompt/value when it becomes necessary
    }

    get regex(): RegExp {
        if (!this.model) {
            return MATCH_NOTHING;
        }
        if (!this.model.regex) {
            return MATCH_ANYTHING;
        }
        // See https://html.spec.whatwg.org/multipage/input.html#the-pattern-attribute
        return new RegExp('^(?:' + this.model.regex + ')$', 'u');
    }

    get minLength(): number {
        if (!this.model || !this.model.minLength) {
            return 0;
        }
        return this.model.minLength;
    }

    get maxLength(): number {
        return this.model?.maxLength ?? Number.MAX_SAFE_INTEGER;
    }

    get prompt(): string | undefined {
        return this.model?.prompt;
    }
}

export class HalTemplateNotFoundError extends HalError {
    public constructor(readonly template: string) {
        super(`Template ${template} was not found`);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = HalTemplateNotFoundError.name;
    }
}

type ExtractTemplateTarget<
    TemplateName extends string,
    Entity extends HalEntityWithTemplate<any, TemplateName, any>,
> = TemplateType<Exclude<Exclude<Entity['_templates'], undefined>[TemplateName], undefined>>;

export function resolveTemplate<
    TemplateName extends string,
    Entity extends HalEntityWithTemplate<any, TemplateName, Target>,
    Target = ExtractTemplateTarget<TemplateName, Entity>,
>(entity: Entity, name: TemplateName): HalFormsTemplate<Target> | null {
    const template = entity._templates?.[name];
    if (!template) {
        return null;
    }
    return new HalFormsTemplateImpl(entity, template);
}

export function resolveTemplateRequired<
    TemplateName extends string,
    Entity extends HalEntityWithTemplate<any, TemplateName, Target>,
    Target = ExtractTemplateTarget<TemplateName, Entity>,
>(entity: Entity, name: TemplateName): HalFormsTemplate<Target> {
    const template = resolveTemplate(entity, name);
    if (template === null) {
        throw new HalTemplateNotFoundError(name);
    }
    return template;
}
