import { Box, CircularProgress, Tooltip } from '@material-ui/core';
import { Check, Schedule, Warning } from '@material-ui/icons';
import React from 'react';

import { Application } from '../../../repository/models/Application';
import { capitalize } from '../../../helpers';

interface ApplicationStateProps {
    state: Application['state'];
}

export default function ApplicationState({ state }: ApplicationStateProps) {
    const tooltipText =
        state.name === 'provisioning'
            ? 'Provisioning database and storage bucket'
            : state.name === 'provisioned'
              ? 'The database and storage bucket are ready'
              : ''; // empty string tooltips are not displayed

    switch (state.name) {
        case 'broken':
            return (
                <>
                    <Warning fontSize="small" color="error" />
                    <span>Broken</span>
                </>
            );
        case 'destroying_timed_wait':
            return (
                <>
                    <Schedule fontSize="small" />
                    <span>Destroying: waiting before deleting data</span>
                </>
            );
        default:
            return (
                <Tooltip title={tooltipText}>
                    <>
                        {state.progressing ? (
                            <Box component="span" paddingRight={1}>
                                <CircularProgress size="1em" />
                            </Box>
                        ) : (
                            <Check fontSize="small" />
                        )}
                        <span>{capitalize(state.name)}</span>
                    </>
                </Tooltip>
            );
    }
}
