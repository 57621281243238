import { combineReducers, createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../store';

export const authenticationSignIn = createAction('authentication/signIn');
export const authenticationSignOut = createAction('authentication/signOut');
export const authenticationTokenExpired = createAction('authentication/tokenExpired');
export const authenticationRenewError = createAction('authentication/renewError');

const isAuthenticatedReducer = createReducer(false, (builder) => {
    builder
        .addCase(authenticationSignIn, () => true)
        .addCase(authenticationSignOut, () => false)
        .addCase(authenticationTokenExpired, () => false);
});

const isExpiredReducer = createReducer(false, (builder) => {
    builder
        .addCase(authenticationSignIn, () => false)
        .addCase(authenticationSignOut, () => false)
        .addCase(authenticationTokenExpired, () => true);
});

export default combineReducers({
    isAuthenticated: isAuthenticatedReducer,
    isExpired: isExpiredReducer,
});

function selectSlice(state: AppState) {
    return state.authentication;
}

export const selectIsAuthenticated = createSelector(
    selectSlice,
    (authenticationState) => authenticationState.isAuthenticated,
);

export const selectIsExpired = createSelector(selectSlice, (authenticationState) => authenticationState.isExpired);
