import { useRef } from 'react';
import { Blueprint } from '../../../repository/models/Blueprint';
import { PolicyConditionSuggestionDescription } from '../../../repository/models/PolicyConditionSuggestion';
import { ExpressionDto } from '../../../repository/models/PolicyDto';
import api, { useGetPolicyConditionSuggestionsQuery } from '../api';
import { SerializedError } from '@reduxjs/toolkit';
import { PolicyConditionSuggestion } from '../../../repository/models/PolicyConditionSuggestion';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';

interface UsePolicySuggestionProps {
    blueprint: Blueprint;
    entity: string;
    condition: ExpressionDto | undefined;
    description: PolicyConditionSuggestionDescription | undefined;
}

type UsePolicySuggestionResponse =
    | {
          isLoading: false;
          isUninitialized: boolean;
          isError: false;
          error: undefined;
          isFetching: boolean;
          properties: PolicyConditionSuggestion['properties'];
          condition: ExpressionDto | undefined;
          description: PolicyConditionSuggestionDescription;
      }
    | {
          isLoading: true;
          isUninitialized: boolean;
          isError: false;
          error: SerializedError | undefined;
          isFetching: boolean;
          properties?: never;
          condition?: never;
          description?: never;
      }
    | {
          isLoading: false;
          isUninitialized: boolean;
          isError: true;
          error: SerializedError;
          isFetching: boolean;
          properties?: never;
          condition?: never;
          description?: never;
      };

type UsePolicySuggestionResponseAwait = {
    completion: () => Promise<UsePolicySuggestionResponse>;
};

type UsePolicySuggestionOptions = {
    skip?: boolean;
};

export default function usePolicySuggestion(
    data: UsePolicySuggestionProps,
    options?: UsePolicySuggestionOptions,
): UsePolicySuggestionResponse & UsePolicySuggestionResponseAwait {
    const cachedCondition = useRef<ExpressionDto | null | undefined>(null);
    const cachedDescription = useRef<PolicyConditionSuggestionDescription | null>(null);

    const shouldFetch =
        JSON.stringify(cachedCondition.current) !== JSON.stringify(data.condition) ||
        JSON.stringify(cachedDescription.current) !== JSON.stringify(data.description);

    const queryResponse = useGetPolicyConditionSuggestionsQuery(data, {
        skip: !shouldFetch || options?.skip,
    });

    const dispatch = useDispatch<AppDispatch>();

    const transformedResponse = transformQueryData(queryResponse);

    if (queryResponse.isSuccess) {
        cachedCondition.current = transformedResponse.condition;
        cachedDescription.current = transformedResponse.description!;
    }

    const completion = async () => {
        const result = await dispatch(api.util.getRunningQueryThunk('getPolicyConditionSuggestions', data));

        if (result === undefined) {
            return transformedResponse;
        }

        return transformQueryData(result);
    };

    if (!shouldFetch) {
        const result: UsePolicySuggestionResponse = {
            isLoading: false,
            isUninitialized: false,
            isError: false,
            error: undefined,
            isFetching: false,
            properties: transformedResponse.properties!,
            condition: cachedCondition.current!,
            description: cachedDescription.current!,
        };
        return {
            ...result,
            completion,
        };
    }

    return {
        ...transformedResponse,
        completion,
    };
}

function transformQueryData({
    data: policySuggestion,
    isLoading,
    isFetching,
    isUninitialized,
    isError,
    error,
}: Omit<ReturnType<typeof useGetPolicyConditionSuggestionsQuery>, 'refetch'>): UsePolicySuggestionResponse {
    if (isLoading) {
        return { isLoading, isUninitialized, isFetching, isError, error };
    }

    if (isError) {
        return { isLoading, isUninitialized, isFetching, isError, error };
    }

    return {
        isLoading,
        isUninitialized,
        isError,
        error,
        isFetching,
        properties: policySuggestion?.properties,
        condition: policySuggestion?.condition,
        description: policySuggestion?.description,
    };
}
