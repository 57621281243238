import { combineReducers, createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { withTypedPayload } from '@xenit/redux-utils';
import { AppState } from '../../store';
import {
    applicationCreateLocation,
    applicationDeployLocation,
    applicationVisitLocation,
    blueprintSelectLocation,
    datamodelAddAttributeLocation,
    datamodelAddEntityLocation,
    iamCreateUserLocation,
    organizationJoinLocation,
    permissionAddPolicyLocation,
    projectSelectLocation,
    releasesCreateLocation,
    webhooksNavigationLocation,
} from './locations';
import { TourLocation } from './TourLocation';
import { BLUEPRINT_ROOT, INSTANCE_ROOT, ORGANIZATION_ROOT, PROJECT_ROOT } from '../routes';
import { isRouterUrlVisit } from '../routes/routeSlice';
function selectTour(state: AppState) {
    return state.tour;
}

const TOUR_LOCATION_START = [organizationJoinLocation];
const TOUR_LOCATION_NEXT: Record<TourLocation, readonly TourLocation[]> = {
    [organizationJoinLocation]: [projectSelectLocation],
    [projectSelectLocation]: [blueprintSelectLocation],
    [blueprintSelectLocation]: [datamodelAddEntityLocation],
    [datamodelAddEntityLocation]: [datamodelAddAttributeLocation],
    [datamodelAddAttributeLocation]: [permissionAddPolicyLocation, webhooksNavigationLocation],
    [permissionAddPolicyLocation]: [releasesCreateLocation],
    [releasesCreateLocation]: [applicationCreateLocation],
    [applicationCreateLocation]: [iamCreateUserLocation, applicationDeployLocation],
    [iamCreateUserLocation]: [applicationVisitLocation],
};

export const selectVisitedLocations = createSelector(selectTour, (tour) => tour.visitedLocations);
export const selectCurrentLocations = createSelector(selectVisitedLocations, (visited) => {
    var nextLocations = new Set<TourLocation>();
    TOUR_LOCATION_START.forEach((loc) => nextLocations.add(loc));
    visited.forEach((loc) => {
        var nextLocationForThisLocation = TOUR_LOCATION_NEXT[loc] ?? [];
        nextLocationForThisLocation.forEach((nextLoc) => {
            nextLocations.add(nextLoc);
        });
    });

    visited.forEach((loc) => {
        nextLocations.delete(loc);
    });

    return nextLocations;
});

export const visitTourLocation = createAction('tour/visit', withTypedPayload<readonly TourLocation[]>());
export const reloadVisitedTourLocations = createAction('tour/visit/reload', withTypedPayload<void>());

function markLocationVisited(...location: readonly TourLocation[]): (state: TourLocation[]) => readonly TourLocation[] {
    return (locations) => [...locations, ...location].filter((loc, i, arr) => arr.indexOf(loc) === i);
}

const visitedLocationsReducer = createReducer(loadTourLocations, (builder) =>
    builder
        .addCase(visitTourLocation, (locations, action) => markLocationVisited(...action.payload)(locations))
        .addCase(reloadVisitedTourLocations, () => loadTourLocations())
        .addMatcher(isRouterUrlVisit(ORGANIZATION_ROOT.wildcard), markLocationVisited(organizationJoinLocation))
        .addMatcher(isRouterUrlVisit(PROJECT_ROOT.wildcard), markLocationVisited(projectSelectLocation))
        .addMatcher(isRouterUrlVisit(BLUEPRINT_ROOT.wildcard), markLocationVisited(blueprintSelectLocation))
        .addMatcher(isRouterUrlVisit(INSTANCE_ROOT.wildcard), markLocationVisited(applicationCreateLocation)),
);
export default combineReducers({
    visitedLocations: visitedLocationsReducer,
});

function loadTourLocations(): readonly TourLocation[] {
    const locationsString = localStorage.getItem('tour.visitedLocations');
    if (locationsString) {
        return JSON.parse(locationsString);
    }

    return [];
}
