import { CircularProgress } from '@material-ui/core';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { selectCurrentProject } from '../../project/projectSlice';
import { Deployment } from '../../../repository/models/Deployment';
import { AppState } from '../../../store';
import { Project } from '../../../repository/models/Project';
import { useGetApplicationsForProjectQuery, useGetDeploymentsForApplicationQuery, useGetZoneQuery } from '../api';
import useInterval from '../../interval';
import { InstanceSettings } from './components/InstanceSettings';
import { RequestStateHandler } from '../../../ui/RequestStateHandler';
import { InstanceOverview } from './components/InstanceOverview/InstanceOverview';
import { InstanceHistory } from './components/InstanceHistory/InstanceHistory';
import LinkTabs from '../../../ui/tabs/LinkTabs';
import { INSTANCES_ROOT, INSTANCE_HISTORY, INSTANCE_OVERVIEW, INSTANCE_ROOT, INSTANCE_SETTINGS } from '../../routes';
import LinkTab from '../../../ui/tabs/LinkTab';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import AutoBreadcrumbs from '../../routes/AutoBreadcrumbs';
import Link from '../../../ui/Link';
import { skipToken } from '@reduxjs/toolkit/query';
import { Alert, AlertTitle } from '@material-ui/lab';

const polling = 3000;

type InstancePageProps = {
    project: Project | null;
};
type InstancePagePropsNotNull = {
    project: Project;
};

function InstancePage({ project }: InstancePagePropsNotNull) {
    const location = useLocation();
    const match = INSTANCE_ROOT.wildcard.match(location.pathname)!;

    const {
        data: application,
        isFetching: applicationIsFetching,
        error: applicationError,
        refetch: refetchInstanceData,
    } = useGetApplicationsForProjectQuery(project, {
        selectFromResult: ({ data, ...props }) => ({
            data: data?.find((app) => app.name === match.params.application),
            ...props,
        }),
    });
    const {
        data: deploymentList,
        refetch,
        error: deploymentListError,
        isLoading: isDeploymentLoading,
    } = useGetDeploymentsForApplicationQuery(application ?? skipToken);
    const {
        data: zone,
        error: zoneError,
        isLoading: isZoneLoading,
    } = useGetZoneQuery(application?._links.zone.href ?? skipToken);

    const deployments = useMemo(() => {
        const list = deploymentList?.slice() ?? [];
        return list.sort(
            (a: Deployment, b: Deployment) => new Date(b.created).getTime() - new Date(a.created).getTime(),
        );
    }, [deploymentList]);

    const deploying = (deploymentList ?? []).filter((d) => !d.status.finished).length > 0;

    const checkDeployments = () => {
        if (deploying) {
            refetch();
        }
    };

    useInterval(() => {
        checkDeployments();
        if (application?.state.progressing) {
            refetchInstanceData();
        }
    }, polling);

    if (!application) {
        return (
            <>
                <AutoBreadcrumbs>
                    <Link to={INSTANCE_ROOT.navigate(location.pathname, INSTANCES_ROOT)!}>Applications</Link>
                    <>{match.params.application}</>
                </AutoBreadcrumbs>
                {applicationIsFetching || applicationError ? (
                    <RequestStateHandler isLoading={applicationIsFetching} error={applicationError} />
                ) : (
                    <Alert severity="error">
                        <AlertTitle>Application not found</AlertTitle>
                        Application '{match.params.application}' does not exist on project {project.slug}.
                    </Alert>
                )}
            </>
        );
    }

    return (
        <>
            <AutoBreadcrumbs>
                <Link to={INSTANCE_ROOT.navigate(location.pathname, INSTANCES_ROOT)!}>Applications</Link>
                <>{application.name}</>
            </AutoBreadcrumbs>
            <LinkTabs indicatorColor="primary">
                <LinkTab label="Overview" from={INSTANCE_ROOT} to={INSTANCE_OVERVIEW} activeRoutes={[INSTANCE_ROOT]} />
                <LinkTab label="History" from={INSTANCE_ROOT} to={INSTANCE_HISTORY} />
                <LinkTab label="Settings" from={INSTANCE_ROOT} to={INSTANCE_SETTINGS} />
            </LinkTabs>

            <RequestStateHandler
                isLoading={isDeploymentLoading || isZoneLoading}
                error={deploymentListError || zoneError}
            />

            <Routes>
                <Route
                    index
                    element={
                        <Navigate to={INSTANCE_ROOT.navigate(location.pathname, INSTANCE_OVERVIEW)!} replace={true} />
                    }
                />
                <Route
                    path={INSTANCE_ROOT.wildcard.relativePatternFor(INSTANCE_OVERVIEW)}
                    element={
                        <InstanceOverview
                            application={application ?? application}
                            project={project}
                            deploymentList={deploymentList}
                            zone={zone}
                        />
                    }
                />
                <Route
                    path={INSTANCE_ROOT.wildcard.relativePatternFor(INSTANCE_HISTORY)}
                    element={<InstanceHistory deployments={deployments} />}
                />
                <Route
                    path={INSTANCE_ROOT.wildcard.relativePatternFor(INSTANCE_SETTINGS)}
                    element={<InstanceSettings application={application ?? application} />}
                />
            </Routes>
        </>
    );
}

export default connect((state: AppState) => ({
    project: selectCurrentProject(state),
}))((props: InstancePageProps) => {
    if (props.project === null) {
        return <CircularProgress />;
    } else {
        return <InstancePage project={props.project} />;
    }
});
