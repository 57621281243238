import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AppLayout from './AppLayout';
import Blueprints from './features/blueprint/Blueprints';
import DataModel from './features/datamodel/DataModel';
import {
    BLUEPRINT_EVENTHANDLERS,
    BLUEPRINT_MODEL,
    BLUEPRINT_PERMISSIONS,
    BLUEPRINT_ROOT,
    CREATE_ORGANIZATION,
    CREATE_PROJECT,
    EVENTHANDLERS_EDIT,
    EVENTHANDLERS_NEW,
    ORGANIZATION_ROOT,
    ORGANIZATION_SETTINGS,
    PERMISSIONS_EDIT,
    PERMISSIONS_NEW,
    PROJECT_BLUEPRINTS,
    PROJECT_INSTANCES,
    RELEASE_SHOW_OPENAPI,
    ROOT,
    IAM_ROOT,
    RELEASE_SHOW,
    ORGANIZATION_MEMBERS,
    PROJECT_DASHBOARD,
    PROJECT_RELEASES,
    INSTANCE_ROOT,
    RELEASE_CREATE,
    IAM_REALM,
    RELEASE_CREATE_WITHOUT_SELECTED_BLUEPRINT,
    DATAMODEL_ATTRIBUTE,
} from './features/routes';
import PermissionModel from './features/permissions/PermissionModel';
import IDataFetcher from './repository/IDataFetcher';
import RootDashboard from './RootDashboard';
import PolicyModelCreate from './features/permissions/PolicyModelCreate';
import PolicyModelEdit from './features/permissions/PolicyModelEdit';
import OpenApiDialog from './features/release/OpenApiDialog';
import InstancePage from './features/application/InstancePage/InstancePage';
import Instances from './features/application/Instances/Instances';
import EventHandlers from './features/eventhandlers/EventHandlers';
import EventHandlerEdit from './features/eventhandlers/EventHandlerEdit';
import EventHandlerCreate from './features/eventhandlers/EventHandlerCreate';
import OrganizationDashboard from './features/organization/OrganizationDashboard';
import CreateOrganization from './features/organization/CreateOrganization';
import CreateProject from './features/project/CreateProject';
import IamPage from './features/iam/Iam';
import ReleaseDetailsPage from './features/release/ReleaseDetailsPage';
import CreateRelease from './features/release/CreateRelease';
import ProjectList from './features/project/ProjectList';
import MembersTab from './features/organization/members/MembersTab';
import BlueprintsTab from './features/blueprint/BlueprintsTab';
import ReleasesTab from './features/blueprint/ReleasesTab';
import IamRealmPage from './features/iam/IamRealmPage';
import AttributeModelEdit from './features/datamodel/AttributeModelEdit';

type AppProps = {
    fetcher: IDataFetcher;
};

export default function App(props: AppProps) {
    return (
        <Routes>
            <Route path={ROOT.wildcard.pattern} element={<AppLayout />}>
                <Route index element={<RootDashboard />} />
                <Route path={ROOT.wildcard.relativePatternFor(CREATE_ORGANIZATION)} element={<CreateOrganization />} />
                <Route
                    path={ROOT.wildcard.relativePatternFor(ORGANIZATION_ROOT.wildcard)}
                    element={<OrganizationDashboard />}
                >
                    <Route index element={<ProjectList />} />
                    <Route
                        path={ORGANIZATION_ROOT.wildcard.relativePatternFor(ORGANIZATION_MEMBERS)}
                        element={<MembersTab />}
                    />
                    <Route
                        path={ORGANIZATION_ROOT.wildcard.relativePatternFor(ORGANIZATION_SETTINGS)}
                        element={<p>Organization Settings, coming soon!</p>}
                    />
                </Route>
                {/* Project */}
                <Route path={ROOT.wildcard.relativePatternFor(CREATE_PROJECT)} element={<CreateProject />} />
                {/*<Route path={ROOT.wildcard.relativePatternFor(PROJECT_DASHBOARD)} element={<ProjectDashboard fetcher={props.fetcher} />} /> */}
                <Route path={ROOT.wildcard.relativePatternFor(PROJECT_DASHBOARD)} element={<Blueprints />}>
                    <Route index element={<BlueprintsTab />} />
                    <Route
                        path={PROJECT_DASHBOARD.wildcard.relativePatternFor(BLUEPRINT_ROOT)}
                        element={<BlueprintsTab />}
                    />
                    <Route
                        path={PROJECT_DASHBOARD.wildcard.relativePatternFor(PROJECT_BLUEPRINTS)}
                        element={<BlueprintsTab />}
                    />
                    <Route
                        path={PROJECT_DASHBOARD.wildcard.relativePatternFor(PROJECT_RELEASES)}
                        element={<ReleasesTab />}
                    />
                </Route>
                <Route path={ROOT.wildcard.relativePatternFor(PROJECT_BLUEPRINTS)} element={<Blueprints />} />
                {/* Blueprint */}
                <Route path={ROOT.wildcard.relativePatternFor(BLUEPRINT_ROOT)} element={<Blueprints />} />
                {/* Blueprint: Data Model */}
                <Route path={ROOT.wildcard.relativePatternFor(DATAMODEL_ATTRIBUTE)} element={<AttributeModelEdit />} />
                <Route path={ROOT.wildcard.relativePatternFor(BLUEPRINT_MODEL)} element={<DataModel />} />
                {/* Blueprint: Permissions */}
                <Route
                    path={ROOT.wildcard.relativePatternFor(BLUEPRINT_PERMISSIONS)}
                    element={<PermissionModel fetcher={props.fetcher} />}
                />
                <Route path={ROOT.wildcard.relativePatternFor(PERMISSIONS_NEW)} element={<PolicyModelCreate />} />
                <Route path={ROOT.wildcard.relativePatternFor(PERMISSIONS_EDIT)} element={<PolicyModelEdit />} />
                {/* Blueprint: EventHandlers */}
                <Route path={ROOT.wildcard.relativePatternFor(BLUEPRINT_EVENTHANDLERS)} element={<EventHandlers />} />
                <Route
                    path={ROOT.wildcard.relativePatternFor(EVENTHANDLERS_NEW)}
                    element={<EventHandlerCreate fetcher={props.fetcher} />}
                />
                <Route path={ROOT.wildcard.relativePatternFor(EVENTHANDLERS_EDIT)} element={<EventHandlerEdit />} />
                {/* Releases */}
                {/*<Route path={ROOT.wildcard.relativePatternFor(PROJECT_RELEASES)} element={<Releases fetcher={props.fetcher} />} /> */}
                <Route path={ROOT.wildcard.relativePatternFor(RELEASE_CREATE)} element={<CreateRelease />} />
                <Route
                    path={ROOT.wildcard.relativePatternFor(RELEASE_CREATE_WITHOUT_SELECTED_BLUEPRINT)}
                    element={<CreateRelease />}
                />
                <Route path={ROOT.wildcard.relativePatternFor(RELEASE_SHOW)} element={<ReleaseDetailsPage />} />
                <Route path={ROOT.wildcard.relativePatternFor(RELEASE_SHOW_OPENAPI)} element={<OpenApiDialog />} />
                {/* Applications */}
                <Route path={ROOT.wildcard.relativePatternFor(PROJECT_INSTANCES)} element={<Instances />} />
                <Route path={ROOT.wildcard.relativePatternFor(INSTANCE_ROOT.wildcard)} element={<InstancePage />} />
                {/* IAM */}
                <Route path={ROOT.wildcard.relativePatternFor(IAM_ROOT)} element={<IamPage />} />
                <Route path={ROOT.wildcard.relativePatternFor(IAM_REALM.wildcard)} element={<IamRealmPage />} />
            </Route>
        </Routes>
    );
}
