import { IconButton, styled } from '@material-ui/core';
import React from 'react';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';

interface CollapseSwitcherProps {
    opened: boolean;
    onClick: () => void;
}

export const CollapseSwitcher = ({ opened, onClick }: CollapseSwitcherProps) => {
    return (
        <StyledIconWrapper>
            <StyledIcon color="primary" onClick={onClick}>
                {opened ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
            </StyledIcon>
        </StyledIconWrapper>
    );
};

const StyledIconWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    height: '2px',
    background: theme.palette.grey[200],
    marginBottom: '2rem',
}));

const StyledIcon = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    background: '#eee',
    padding: '4px',
    left: 0,
    right: 0,
    margin: '0 auto',
    top: '-16px',
    backgroundColor: theme.palette.grey[200],
    width: 'max-content',
    '&:hover': {
        backgroundColor: theme.palette.grey[300],
    },
}));
