import { skipToken } from '@reduxjs/toolkit/query/react';
import React, { useMemo, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Organization } from '../../../repository/models/Organization';
import { useGetRealmGroupsQuery } from '../api';
import { IamRealm } from '../../../repository/models/IamRealm';
import { IamTable, StyledIamNavLink } from '../../../ui/table/IamTable';
import { Cell } from '../../../ui/table/Cell';
import { TableHeader } from '../../../ui/typography/TableHeader';
import { Row } from '../../../ui/table/Row';
import { IAM_REALM_CREATE_GROUP, IAM_REALM_GROUP } from '../../routes';
import { IamGroupList } from '../../../repository/models/IamGroup';
import { TypedLink, resolveTemplate } from '../../../hal';
import { NavigationButtons } from '../components/NavigationButtons';

interface IamRealmGroupsProps {
    org: Organization | null;
    realm?: IamRealm;
}

export const IamRealmGroups = ({ org, realm }: IamRealmGroupsProps) => {
    const [link, setLink] = useState<TypedLink<IamGroupList>>();
    const {
        data: groupList,
        isLoading,
        error,
    } = useGetRealmGroupsQuery(link ?? realm?._links?.groups?.href ?? skipToken);
    const groups = groupList?._embedded?.groups ?? [];

    const createGroupTemplate = useMemo(() => {
        if (!!groupList) {
            return resolveTemplate(groupList!, 'default');
        }
        return null;
    }, [groupList]);

    const handleNext = () => {
        if (!!groupList?._links?.next?.href) {
            setLink(groupList?._links?.next.href);
        }
    };

    const handlePrev = () => {
        if (!!groupList?._links?.prev?.href) {
            setLink(groupList?._links?.prev.href);
        }
    };

    return (
        <>
            {!!createGroupTemplate && (
                <Box marginBottom={2} display="flex" justifyContent="flex-end">
                    <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={IAM_REALM_CREATE_GROUP.generate({
                            org: org?.name!,
                            realm: realm?.id!,
                        })}
                    >
                        Create group
                    </Button>
                </Box>
            )}

            <IamTable
                isLoading={isLoading || !org}
                error={error}
                noRecords={groups.length === 0}
                noRecordsText="No groups."
                headers={
                    <>
                        <TableHeader>Group name</TableHeader>
                    </>
                }
            >
                {(groups ?? []).map((group) => (
                    <Row key={group._links.self.href} borders="horizontal">
                        <Cell>
                            <StyledIamNavLink
                                to={IAM_REALM_GROUP.generate({
                                    org: org!.name,
                                    realm: realm?.id ?? '',
                                    group: group.groupId,
                                })}
                            >
                                {group.name}
                            </StyledIamNavLink>
                        </Cell>
                    </Row>
                ))}
            </IamTable>

            {!!groupList?._links.next || !!groupList?._links.prev ? (
                <NavigationButtons
                    prevDisabled={!groupList?._links.prev?.href}
                    nextDisabled={!groupList._links.next?.href}
                    onPrevClick={handlePrev}
                    onNextClick={handleNext}
                />
            ) : null}
        </>
    );
};
