import { createStyles, Fade, makeStyles, Popover, Theme } from '@material-ui/core';
import classNames from 'classnames';
import React, {
    CSSProperties,
    ForwardedRef,
    ReactElement,
    ReactInstance,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createPortal, findDOMNode } from 'react-dom';
import { useTourLocation } from './hooks';
import { TourLocation } from './TourLocation';

export interface HighlightedComponentProps {
    location: TourLocation | readonly TourLocation[];
    markVisitedOnClick?: boolean;
    disabled?: boolean;
    tip?: ReactElement;
    children: ReactElement | null;
    attachToParent?: number;
}

const attachedWindowEvents: ReadonlyArray<keyof WindowEventMap> = ['resize', 'scroll'];

function findTargetDomNode(react: ReactInstance, attachToParent: number | undefined) {
    const highlightTargetDomNode = findDOMNode(react);
    if (!attachToParent) {
        return highlightTargetDomNode;
    }
    let parent = highlightTargetDomNode?.parentElement;
    for (let i = 1; i < attachToParent; i++) {
        parent = parent?.parentElement;
    }
    return parent;
}

export default function HighlightedComponent(props: HighlightedComponentProps) {
    const child = React.Children.only(props.children);
    const highlightTargetRef = useRef<ReactInstance>(null);
    const highlightRef = useRef<HTMLElement>(null);

    useLayoutEffect(() => {
        if (highlightTargetRef.current) {
            const target = findTargetDomNode(highlightTargetRef.current, props.attachToParent);
            if (target instanceof HTMLElement) {
                const updateHighlightLocation = () => {
                    window.requestAnimationFrame(() => {
                        if (highlightRef.current) {
                            const pos = calculateAbsolutePosition(target);
                            pos.left += target.offsetWidth - highlightRef.current.offsetWidth;
                            highlightRef.current.style.top = pos.top + 'px';
                            highlightRef.current.style.left = pos.left + 'px';
                        }
                    });
                };
                updateHighlightLocation();
                for (var event of attachedWindowEvents) {
                    window.addEventListener(event, updateHighlightLocation);
                }

                return () => {
                    for (var event of attachedWindowEvents) {
                        window.removeEventListener(event, updateHighlightLocation);
                    }
                };
            }
        }
        return void 0;
    });

    const [isVisible, markVisited] = useTourLocation(props.location);

    useEffect(() => {
        if (highlightTargetRef.current && !props.tip && props.markVisitedOnClick) {
            const elem = findTargetDomNode(highlightTargetRef.current, props.attachToParent);
            if (elem instanceof HTMLElement) {
                elem.addEventListener('click', markVisited, {
                    capture: true,
                    passive: true,
                });
                return () => elem.removeEventListener('click', markVisited);
            }
        }
        return void 0;
    }, [markVisited, props.tip, props.markVisitedOnClick, props.attachToParent]);

    const [popoverVisible, setPopoverVisible] = useState(false);

    const showTooltip = useMemo(() => () => setPopoverVisible(true), [setPopoverVisible]);
    const closeTooltip = useMemo(
        () => () => {
            setPopoverVisible(false);
            if (props.markVisitedOnClick) {
                markVisited();
            }
        },
        [setPopoverVisible, markVisited, props.markVisitedOnClick],
    );

    const hijackedChild = child
        ? React.cloneElement(child, {
              ref: highlightTargetRef,
          })
        : null;
    return (
        <>
            {hijackedChild}
            {props.tip && (
                <Popover
                    open={popoverVisible}
                    anchorEl={highlightRef.current}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'center',
                    }}
                    transformOrigin={{
                        horizontal: 'left',
                        vertical: 'top',
                    }}
                    onClose={closeTooltip}
                >
                    {props.tip}
                </Popover>
            )}
            {createPortal(
                <Fade in={isVisible && !props.disabled} mountOnEnter unmountOnExit timeout={1000}>
                    <HighlightedDot ref={highlightRef} onClick={props.tip ? showTooltip : undefined} />
                </Fade>,
                document.body,
            )}
        </>
    );
}

function calculateAbsolutePosition(element: HTMLElement): {
    top: number;
    left: number;
} {
    let top = 0,
        left = 0;
    do {
        top += element.offsetTop ?? 0;
        left += element.offsetLeft ?? 0;
        element = element.offsetParent as HTMLElement;
    } while (element);
    return { top, left };
}

interface HighlightedDotProps {
    style?: CSSProperties;
    onClick?: () => void;
}

export const HighlightedDot = React.forwardRef((props: HighlightedDotProps, ref: ForwardedRef<any>) => {
    const styles = useHighlightingStyles();
    const rootClasses = classNames(styles.root, {
        [styles.clickable]: props.onClick,
    });
    return (
        <div ref={ref} className={rootClasses} style={props.style} onClick={props.onClick}>
            <div className={styles.dot} />
        </div>
    );
});

const useHighlightingStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: 20,
            height: 20,
            borderRadius: '100%',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            pointerEvents: 'none',
            '&::after': rippleRings('3s', '0s', theme),
            '&::before': rippleRings('3s', '0.5s', theme),
        },
        dot: {
            backgroundColor: theme.palette.warning.main,
            height: '50%',
            width: '50%',
            borderRadius: '100%',
        },
        clickable: {
            pointerEvents: 'auto',
            cursor: 'help',
        },
        '@keyframes ripples': {
            from: {
                opacity: 1,
                transform: 'scale3d(0.5,0.5,1)',
            },
            to: {
                opacity: 0,
                transform: 'scale3d(1.5,1.5,1)',
            },
        },
    }),
);

function rippleRings(duration: string, delay: string, theme: Theme): CSSProperties {
    return {
        opacity: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        content: "''",
        height: '100%',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.warning.main,
        borderRadius: '100%',
        animationName: '$ripples',
        animationDuration: duration,
        animationDelay: delay,
        animationIterationCount: 'infinite',
        MozAnimationDirection: 'both',
        animationTimingFunction: theme.transitions.easing.easeInOut,
        zIndex: -1,
    };
}
