import { TypedLink } from '../typedLinks';
import { HalFormsProperty, HalFormsPropertyOption, HalFormsTemplate, RequestSpec } from './api';
import { MATCH_ANYTHING, MATCH_NOTHING } from './_internal';

export class HalFormsTemplateBuilder<Target> implements HalFormsTemplate<Target> {
    private constructor(
        public readonly request: RequestSpec<Target>,
        public readonly properties: HalFormsProperty[] = [],
    ) {}

    public static from<T>(request: RequestSpec<T>): HalFormsTemplateBuilder<T> {
        return new HalFormsTemplateBuilder(request);
    }

    public property(propertyName: string): HalFormsProperty {
        return (
            this.properties.find((property) => property.name === propertyName) ??
            new HalFormsPropertyBuilder(propertyName, undefined, false, [], MATCH_NOTHING)
        );
    }

    public addProperty(
        propertyName: string,
        factory: (builder: HalFormsPropertyBuilder) => HalFormsProperty,
    ): HalFormsTemplateBuilder<Target> {
        const newPropertyBuilder = new HalFormsPropertyBuilder(propertyName);

        const builtProperty = factory(newPropertyBuilder);

        return new HalFormsTemplateBuilder(this.request, this.properties.concat([builtProperty]));
    }
}

export class HalFormsPropertyBuilder implements HalFormsProperty {
    public constructor(
        public readonly name: string,
        public readonly type: string | undefined = undefined,
        public readonly enabled: boolean = true,
        public readonly options: readonly HalFormsPropertyOption[] = [],
        public readonly regex: RegExp = MATCH_ANYTHING,
        public readonly minLength: number = 0,
        public readonly maxLength: number = Number.MAX_SAFE_INTEGER,
    ) {}

    public withType(type: string): HalFormsPropertyBuilder {
        return new HalFormsPropertyBuilder(this.name, type, this.enabled, this.options, this.regex);
    }

    public withEnabled(enabled: boolean): HalFormsPropertyBuilder {
        return new HalFormsPropertyBuilder(this.name, this.type, enabled, this.options, this.regex);
    }

    public withOptions(options: readonly HalFormsPropertyOption[]): HalFormsPropertyBuilder {
        return new HalFormsPropertyBuilder(this.name, this.type, this.enabled, options, this.regex);
    }

    public addOption(option: HalFormsPropertyOption): HalFormsPropertyBuilder;
    public addOption(value: string, prompt?: string): HalFormsPropertyBuilder;

    public addOption(optionOrValue: HalFormsPropertyOption | string, prompt?: string): HalFormsPropertyBuilder {
        const option =
            typeof optionOrValue === 'object'
                ? optionOrValue
                : { value: optionOrValue, prompt: prompt ?? optionOrValue };

        return this.withOptions(this.options.concat([option]));
    }

    public withRegex(regex: RegExp | null): HalFormsPropertyBuilder {
        return new HalFormsPropertyBuilder(this.name, this.type, this.enabled, this.options, regex ?? MATCH_ANYTHING);
    }

    public withLength(min: number, max: number) {
        return new HalFormsPropertyBuilder(this.name, this.type, this.enabled, this.options, this.regex, min, max);
    }
}

export function buildTemplate<T>(method: string, url: TypedLink<T>): HalFormsTemplateBuilder<T> {
    return HalFormsTemplateBuilder.from({ method, url });
}
