import React, { ForwardedRef } from 'react';

import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

interface BusyButtonProps extends ButtonProps {
    busy: boolean;
}

const BusyButton = React.forwardRef((props: BusyButtonProps, ref: ForwardedRef<any>) => {
    const { busy, ...buttonProps } = props;
    return (
        <Button {...buttonProps} disabled={props.disabled || busy} ref={ref}>
            {busy ? <CircularProgress size={24} /> : props.children}
        </Button>
    );
});

export default BusyButton;
