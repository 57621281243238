import { Box, Button, FormControlLabel, Typography, styled, Checkbox as UICheckbox } from '@material-ui/core';
import React, { useReducer } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { IamRealm } from '../../../repository/models/IamRealm';
import { RForm, resolveTemplate } from '../../../hal';
import { useEditRealmUserMutation, useGetRealmUserByIdQuery } from '../api';
import { inheritHalProperty, withHalProperty } from '../../../hal/forms/react';
import ValidatedTextInput from '../../../hal/forms/ValidatedTextInput';
import { useNavigate, useParams } from 'react-router-dom';
import { IAM_REALM_USER } from '../../routes';
import { Organization } from '../../../repository/models/Organization';
import BusyButton from '../../../BusyButton';
import { ServerErrorMessage } from '../../../ui/ServerErrorMessage';
import { PageTitle } from '../../../ui/typography/PageTitle';
import { IamUser } from '../../../repository/models/IamUser';
import { RequestStateHandler } from '../../../ui/RequestStateHandler';

interface IamRealmEditUserWrapperProps {
    realm?: IamRealm;
    org: Organization | null;
}

export default function IamRealmEditUserWrapper({ realm, org }: IamRealmEditUserWrapperProps) {
    const { user: userId } = useParams();
    const userQueryParam = realm === undefined || userId === undefined ? skipToken : { realm, userId };
    const { data: user, isLoading, error } = useGetRealmUserByIdQuery(userQueryParam);

    return (
        <>
            <PageTitle>Edit user</PageTitle>
            {!!error || isLoading ? (
                <RequestStateHandler isLoading={isLoading} error={error} />
            ) : !!user ? (
                <IamRealmEditUser user={user} realm={realm} org={org} />
            ) : null}
        </>
    );
}

interface IamRealmEditUserProps {
    realm?: IamRealm;
    org: Organization | null;
    user: IamUser;
}

const IamRealmEditUser = ({ user, realm, org }: IamRealmEditUserProps) => {
    const [editUser, { error, isLoading }] = useEditRealmUserMutation();
    const navigate = useNavigate();

    const userTemplate = resolveTemplate(user, 'default');

    const defaultState = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        enabled: user.enabled,
    };

    const [formState, setFormState] = useReducer((state: typeof defaultState, action: Partial<typeof defaultState>) => {
        return { ...state, ...action };
    }, defaultState);

    const handleSubmit = async () => {
        await editUser({ templateUser: user, user: formState }).unwrap();
        navigate(
            IAM_REALM_USER.generate({
                org: org?.name!,
                realm: realm?.id!,
                user: user.userId,
            }),
        );
    };

    return (
        <>
            {!!error ? <ServerErrorMessage error={error} /> : null}
            <Box marginTop={2}>
                {userTemplate !== null && (
                    <RForm template={userTemplate}>
                        <StyledWrapper>
                            <Typography variant="body2" noWrap component="label" htmlFor="email">
                                Email
                            </Typography>
                            <HalValidatedTextInput
                                displayName="Email"
                                name="email"
                                id="email"
                                fullWidth
                                autoFocus
                                value={formState.email ?? ''}
                                handleOnChange={(val) => setFormState({ email: val })}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Typography variant="body2" noWrap component="label" htmlFor="first_name">
                                First name
                            </Typography>
                            <HalValidatedTextInput
                                displayName="First name"
                                name="first_name"
                                id="first_name"
                                fullWidth
                                value={formState.first_name ?? ''}
                                handleOnChange={(val) => setFormState({ first_name: val })}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Typography variant="body2" noWrap component="label" htmlFor="last_name">
                                Last name
                            </Typography>
                            <HalValidatedTextInput
                                displayName="Last name"
                                name="last_name"
                                id="last_name"
                                fullWidth
                                value={formState.last_name ?? ''}
                                handleOnChange={(val) => setFormState({ last_name: val })}
                            />
                        </StyledWrapper>
                        <StyledWrapper>
                            <Box marginLeft={14}></Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="enabled"
                                        checked={formState.enabled}
                                        onChange={(e) =>
                                            setFormState({
                                                enabled: e.target.checked,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography component="span" variant="body2" noWrap>
                                        User active
                                    </Typography>
                                }
                            />
                        </StyledWrapper>
                        <Box display="flex" justifyContent="flex-end">
                            <Box marginRight={2}>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        navigate(
                                            IAM_REALM_USER.generate({
                                                org: org?.name!,
                                                realm: realm?.id!,
                                                user: user.userId,
                                            }),
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <BusyButton
                                busy={isLoading}
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Save
                            </BusyButton>
                        </Box>
                    </RForm>
                )}
            </Box>
        </>
    );
};

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);
const Checkbox = withHalProperty(UICheckbox);

const StyledWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& .MuiTypography-root': {
        width: theme.spacing(16),
    },
}));
