import { Dialog, DialogActions, DialogTitle, Button, DialogContent, DialogContentText } from '@material-ui/core';
import React from 'react';
import { toast } from 'react-toastify';

import BusyButton from '../../../../BusyButton';
import { IamUser } from '../../../../repository/models/IamUser';
import { resolveTemplate } from '../../../../hal';
import { useResetRealmUserPasswordMutation } from '../../api';
import { ServerErrorMessage } from '../../../../ui/ServerErrorMessage';

interface ResetPasswordDialogProps {
    user: IamUser;
    opened: boolean;
    onClose: () => void;
}

export const ResetPasswordDialog = ({ user, opened, onClose }: ResetPasswordDialogProps) => {
    const [resetPassword, { isLoading, error }] = useResetRealmUserPasswordMutation();

    const resetTemplate = resolveTemplate(user, 'reset-password');

    const handleClick = async () => {
        await resetPassword(user).unwrap();
        showChangesSavedToast();
        onClose();
    };

    return (
        <Dialog open={opened} maxWidth="lg" onClose={onClose}>
            {!!error ? <ServerErrorMessage error={error} /> : null}

            <DialogTitle>Password Reset</DialogTitle>

            <DialogContent>
                <DialogContentText>
                    You are about to send a password reset email to <b>{user.username}</b>
                </DialogContentText>
                <DialogContentText>The password reset link will expire in 24 hours.</DialogContentText>
                <DialogContentText>
                    The current credentials remain active until the user completes the password reset process.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <BusyButton
                    color="primary"
                    busy={isLoading}
                    variant="contained"
                    disableElevation
                    disabled={!resetTemplate}
                    onClick={handleClick}
                >
                    Send
                </BusyButton>
            </DialogActions>
        </Dialog>
    );
};

const showChangesSavedToast = () => {
    toast.success('Message was sent successfully', {
        toastId: 'reset-password',
    });
};
