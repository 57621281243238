import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Application } from '../../../../../../repository/models/Application';
import { Release } from '../../../../../../repository/models/Release';

import { useDeployChangesetToAppMutation } from '../../../../api';
import ReleaseDetails from '../../../../../release/components/ReleaseDetails';
import ReleaseChanges from '../../../../../release/components/ReleaseChanges';
import { DirectionsBoat } from '@material-ui/icons';
import BusyButton from '../../../../../../BusyButton';
import { ServerErrorMessage } from '../../../../../../ui/ServerErrorMessage';
import { Alert } from '@material-ui/lab';
import { SerializedError } from '@reduxjs/toolkit';
import { RequestStateHandler } from '../../../../../../ui/RequestStateHandler';
import { Changeset } from '../../../../../../repository/models/Changeset';
import InstancePageLastReleaseIndicator from './InstancePageLastReleaseIndicator';
import { applicationDeployLocation, HighlightedComponent } from '../../../../../tour';
import useQuery from '../../../../../useQuery';

type InstancePageDeployDialogProps = {
    application: Application;
    open: boolean;
    isLoading: boolean;
    error: SerializedError | undefined;
    releases: readonly Release[];
    currentChangeset: Changeset | null;
    onClose: () => void;
};

export default function InstancePageDeployDialog({
    application,
    open,
    releases,
    isLoading,
    error,
    onClose,
    currentChangeset,
}: InstancePageDeployDialogProps) {
    const [deployChangesetToApp, { isLoading: deploymentExecuting, error: deploymentError }] =
        useDeployChangesetToAppMutation();

    const query = useQuery();

    const [selectReleaseOpen, setSelectReleaseOpen] = useState(false);
    const [selectedRelease, setSelectedRelease] = useState<Release | null>(null);

    const selectDefaultRelease = useCallback(() => {
        const deployingLabel = query.get('deploying');
        const deployingRelease = deployingLabel
            ? releases.filter((release) => release.label === deployingLabel)[0]
            : null;
        setSelectedRelease(deployingRelease ?? releases[0] ?? null);
    }, [setSelectedRelease, releases, query]);

    useEffect(() => {
        selectDefaultRelease();
    }, [releases, selectDefaultRelease]);

    const onCloseFn = useCallback(() => {
        // We want to reset the selected release when closing and re-opening the dialog
        selectDefaultRelease();
        onClose();
    }, [onClose, selectDefaultRelease]);

    const doDeploy = useCallback(
        async (app: Application, release: Release) => {
            const csLink = release._links.changeset.href;
            await deployChangesetToApp({
                application: app,
                changeset: csLink,
            }).unwrap();
            onCloseFn();
        },
        [deployChangesetToApp, onCloseFn],
    );

    const isRedeploy = selectedRelease?._links.changeset.href === currentChangeset?._links.self.href;

    return (
        <>
            <Dialog open={open} onClose={onCloseFn} maxWidth="md">
                <DialogTitle>Deploy to {application.name}</DialogTitle>

                <DialogContent>
                    <RequestStateHandler isLoading={isLoading} error={error} />
                    <ServerErrorMessage error={deploymentError} />
                    {selectedRelease ? (
                        <>
                            <Typography>
                                You will be {isRedeploy ? 're-deploying' : 'deploying'} release {selectedRelease.label}
                                &emsp;
                                {releases.length > 1 ? (
                                    <Link variant="body2" component="button" onClick={() => setSelectReleaseOpen(true)}>
                                        (Change)
                                    </Link>
                                ) : null}
                            </Typography>
                            <Card variant="outlined">
                                <CardContent>
                                    <ReleaseDetails release={selectedRelease} baseChangeset={currentChangeset} />
                                    {currentChangeset !== null && !isRedeploy ? (
                                        <Typography component="div" variant="h6">
                                            Changes between currently deployed{' '}
                                            <InstancePageLastReleaseIndicator
                                                isLoading={isLoading}
                                                error={undefined}
                                                releases={releases}
                                                changeset={currentChangeset}
                                            />{' '}
                                            and {selectedRelease.label}
                                        </Typography>
                                    ) : null}
                                    {isRedeploy ? (
                                        <Typography variant="h6">Changes in {selectedRelease.label}</Typography>
                                    ) : null}
                                    <ReleaseChanges
                                        release={selectedRelease}
                                        baseChangeset={isRedeploy ? undefined : currentChangeset}
                                    />
                                </CardContent>
                            </Card>
                        </>
                    ) : (
                        <Alert severity="warning">You have no releases to deploy</Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="space-between">
                        <Button onClick={onCloseFn} disabled={deploymentExecuting}>
                            Cancel
                        </Button>
                        <HighlightedComponent location={applicationDeployLocation} markVisitedOnClick>
                            <BusyButton
                                color="primary"
                                variant="contained"
                                disabled={selectedRelease === null}
                                onClick={() => doDeploy(application, selectedRelease!)}
                                startIcon={<DirectionsBoat />}
                                busy={deploymentExecuting}
                            >
                                Deploy {selectedRelease?.label}
                            </BusyButton>
                        </HighlightedComponent>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={selectReleaseOpen} onClose={() => setSelectReleaseOpen(false)}>
                <DialogTitle>Select release</DialogTitle>
                <List>
                    {releases.map((rel) => (
                        <ListItem
                            key={rel.label}
                            button
                            onClick={() => {
                                setSelectedRelease(rel);
                                setSelectReleaseOpen(false);
                            }}
                        >
                            <ListItemText primary={rel.label} secondary={rel.message} />
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </>
    );
}
