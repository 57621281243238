import { itemTags, tags, wrapPromise } from '../../repository/rtk-query';
import { Blueprint } from '../../repository/models/Blueprint';
import fetcher from '../../repository';
import {
    EventHandlerCreationDto,
    EventHandlerDto,
    EventHandlerUpdateDto,
} from '../../repository/models/EventHandlerDto';
import api from '../../store/api';

const eventHandlersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEventHandlers: build.query<ReadonlyArray<EventHandlerDto>, Blueprint>({
            queryFn: (blueprint) => wrapPromise(fetcher.getEventHandlers(blueprint)),
            providesTags: (eventHandlers, _error, blueprint) =>
                tags('EventHandlers').listFor(blueprint).withItems(eventHandlers).toArray(),
        }),
        addEventHandler: build.mutation<
            EventHandlerDto,
            { blueprint: Blueprint; eventHandler: EventHandlerCreationDto }
        >({
            queryFn: ({ blueprint, eventHandler }) => wrapPromise(fetcher.addEventHandler(blueprint, eventHandler)),
            invalidatesTags: (_eventHandler, _error, { blueprint }) => [
                ...tags('EventHandlers').listFor(blueprint).toArray(),
                ...itemTags('Blueprint', blueprint),
            ],
        }),
        updateEventHandler: build.mutation<
            EventHandlerDto,
            { eventhandler: EventHandlerDto; update: EventHandlerUpdateDto }
        >({
            queryFn: ({ eventhandler, update }) => wrapPromise(fetcher.updateEventHandler(eventhandler, update)),
            invalidatesTags: (eventhandler) => [
                ...itemTags('EventHandlers', eventhandler),
                // FIXME: Invalidate the specific blueprint only, needs a link back from the event handler
                { type: 'Blueprint' },
            ],
        }),
        deleteEventHandler: build.mutation<void, EventHandlerDto>({
            queryFn: (eventhandler) => wrapPromise(fetcher.deleteEventHandler(eventhandler)),
            invalidatesTags: (_, _e, eventhandler) => [
                ...itemTags('EventHandlers', eventhandler),
                // FIXME: Invalidate the specific blueprint only, needs a link back from the event handler
                { type: 'Blueprint' },
            ],
        }),
    }),
});

export const {
    useGetEventHandlersQuery,
    useAddEventHandlerMutation,
    useUpdateEventHandlerMutation,
    useDeleteEventHandlerMutation,
} = eventHandlersApi;
