import { Box, IconButton, Theme, Tooltip, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Check, Error, FileCopyOutlined as CopyIcon } from '@material-ui/icons';

interface CopyTextProps {
    text: string;
}

export const CopyText = ({ text }: CopyTextProps) => {
    const [copied, setCopied] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const onCopy = () => {
        if (!!navigator?.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => setCopied(true))
                .catch((reason) => {
                    console.error('Could not copy', reason);
                    setError(reason as string);
                });
        } else {
            setError('Clipboard not available');
        }
    };

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 1000);
        }
    }, [copied, setCopied]);

    const classes = useStyles();

    return (
        <Box position="relative" paddingRight={4}>
            <Typography component="span" color="textPrimary" variant="body2">
                {text}
            </Typography>
            {error !== undefined ? (
                <Tooltip arrow placement="right" title={error}>
                    <div className={classes.icon}>
                        <Error color="error" fontSize="inherit" />
                    </div>
                </Tooltip>
            ) : (
                <IconButton className={classes.icon} color="inherit" onClick={onCopy} size="small">
                    {' '}
                    {!copied ? (
                        <CopyIcon color="action" fontSize="inherit" />
                    ) : (
                        <Check color="primary" fontSize="inherit" />
                    )}
                </IconButton>
            )}
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            verticalAlign: 'middle',
            padding: theme.spacing(0.5),
            display: 'inline-flex',
            fontSize: theme.typography.body2.fontSize,
        },
    }),
);
