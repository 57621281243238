import { Select, alpha, styled } from '@material-ui/core';

export const StyledSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-inputMarginDense': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.divider,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[400],
    },
    '&.Mui-focused': {
        borderColor: theme.palette.primary.main,
        boxShadow: alpha(theme.palette.primary.main, 0.5) + ' 0 0 0px 3px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
    },
    '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
    },
}));
