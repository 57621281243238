import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';

export default createApi({
    // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#customizing-queries-with-queryfn
    baseQuery: null as unknown as BaseQueryFn<unknown, unknown, SerializedError>,
    tagTypes: [
        'Project',
        'Organization',
        'OrganizationMembers',
        'OrganizationInvites',
        'InvitesToOrganization',
        'Release',
        'Changeset',
        'Policy',
        'IamRealm',
        'IamUsers',
        'IamGroups',
        'IamGroupMembers',
        'IamAttributes',
        'IamUserGroups',
        'EventHandlers',
        'Entity',
        'Attribute',
        'Relation',
        'Blueprint',
        'Application',
        'ApplicationDeployments',
        'ApplicationConfig',
        'ApplicationWebapps',
        'Zone',
        'Changeset',
        'User',
        'Schema',
        'Assistant',
        'AssistantMessages',
    ],
    // https://redux-toolkit.js.org/rtk-query/usage/code-splitting
    endpoints: () => ({}),
});
