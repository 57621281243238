import { createStyles, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

interface ActionsProps {
    readonly title?: string;
    readonly disableSeparator?: boolean;
    readonly children: React.ReactNode;
}

export default function Actions(props: ActionsProps) {
    const classes = useStyles();
    return (
        <div
            className={classNames(classes.root, {
                [classes.separator]: !props.disableSeparator,
            })}
        >
            {props.title ? <Typography variant="h6">{props.title}</Typography> : null}
            {props.children}
        </div>
    );
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        separator: {
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            borderTopColor: theme.palette.divider,
        },
    }),
);
