import { Box, Button, CircularProgress, Typography, styled } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Link as RouterLink } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';

import { AppState } from '../../store';
import { selectCurrentOrganization } from '../account/accountSlice';
import { Organization } from '../../repository/models/Organization';
import AutoBreadcrumbs from '../routes/AutoBreadcrumbs';
import { HighlightedComponent, iamCreateUserLocation } from '../tour';
import { IAM_REALM } from '../routes';
import { useGetZonesQuery } from '../application/api';
import { Zone } from '../../repository/models/Zone';
import { useGetRealmsQuery } from './api';

interface IamProps {
    currentOrg: Organization | null;
}

const IamPage = ({ currentOrg }: IamProps) => {
    const { data: zones, isLoading, error } = useGetZonesQuery(currentOrg ?? skipToken);
    return (
        <Box>
            <AutoBreadcrumbs subtitle="Manage access to your ContentGrid applications">IAM</AutoBreadcrumbs>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : null}
            {error ? <Alert severity="error">Failed to load deployment targets: {error.message}</Alert> : null}

            <Box sx={{ padding: 0, margin: 0 }}>
                {(zones ?? []).map((item, index) => {
                    return <ZoneItem key={index} zone={item} org={currentOrg!} />;
                })}
            </Box>
        </Box>
    );
};

export default connect((state: AppState) => ({
    currentOrg: selectCurrentOrganization(state),
}))(IamPage);

interface ZoneItemProps {
    zone: Zone;
    org: Organization;
}

const ZoneItem = ({ zone, org }: ZoneItemProps) => {
    const { data: realms, isLoading, error } = useGetRealmsQuery(zone);

    return (
        <Box>
            <Typography>
                <Box component="span" sx={{ fontWeight: 500 }}>
                    {zone ? zone.name : '-'}
                </Box>
            </Typography>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : null}
            {error ? <Alert severity="error">Failed to load IAM realms: {error.message}</Alert> : null}

            <Box mt={1} mb={4}>
                {(realms ?? []).map((realm, index) => {
                    const id = realm.id;

                    return (
                        <StyledWrapper key={index}>
                            <Typography variant="body2">{realm.name}</Typography>

                            <div>
                                <HighlightedComponent location={iamCreateUserLocation}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        component={RouterLink}
                                        to={IAM_REALM.generate({
                                            org: org.name,
                                            realm: id,
                                        })}
                                    >
                                        Manage
                                    </Button>
                                </HighlightedComponent>
                            </div>
                        </StyledWrapper>
                    );
                })}
                {realms?.length === 0 ? <NoRealms /> : null}
            </Box>
        </Box>
    );
};

const StyledWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '2rem',
    borderBottom: '1px solid ' + theme.palette.grey[300],
    marginBottom: '1rem',
    padding: '0.5rem 0',
}));

function NoRealms() {
    return (
        <Alert severity="info">
            <AlertTitle>There are no IAM realms in this zone.</AlertTitle>
            <Typography variant="body2">
                An IAM realm is automatically provisioned when you create an application in this zone.
            </Typography>
            <Typography variant="body2">It is currently not possible to create an IAM realm manually.</Typography>
        </Alert>
    );
}
