import { HalResourceObject } from '../../hal';

function halSelfLink(resource: HalResourceObject<any>): string {
    return resource._links.self.href;
}

export type RTKQueryTag<T extends string> = { readonly type: T; id: string };

function listTags<T extends string>(
    type: T,
    resources: readonly HalResourceObject<any>[] | undefined,
): ReadonlyArray<RTKQueryTag<T>>;
function listTags<T extends string, U>(
    type: T,
    resources: readonly U[] | undefined,
    convertor: (u: U) => string,
): ReadonlyArray<RTKQueryTag<T>>;

function listTags<T extends string>(
    type: T,
    resources: readonly any[] | undefined,
    convertor: (u: any) => string = halSelfLink,
) {
    return [{ type, id: 'LIST' }].concat(
        (resources ?? []).map((resource) => ({
            type,
            id: convertor(resource),
        })),
    );
}

export { listTags };

function itemTags<T extends string>(
    type: T,
    resource: HalResourceObject<any> | undefined,
): ReadonlyArray<RTKQueryTag<T>>;
function itemTags<T extends string, U>(
    type: T,
    resource: U | undefined,
    convertor: (u: U) => string,
): ReadonlyArray<RTKQueryTag<T>>;
function itemTags<T extends string>(type: T, resource: any | undefined, convertor: (u: any) => string = halSelfLink) {
    if (resource === undefined) {
        return [];
    }
    return [{ type, id: convertor(resource) }];
}

export { itemTags };
