import fetcher from '../../repository';
import { itemTags, tags, wrapPromise } from '../../repository/rtk-query';
import {
    Application,
    ApplicationConfig,
    ApplicationHealth,
    ApplicationWebapp,
} from '../../repository/models/Application';
import { Project } from '../../repository/models/Project';
import { Zone } from '../../repository/models/Zone';
import { Changeset } from '../../repository/models/Changeset';
import { TypedLink } from '../../hal';
import { Deployment } from '../../repository/models/Deployment';
import { Organization } from '../../repository/models/Organization';
import api from '../../store/api';

const applicationsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getApplicationsForProject: build.query<ReadonlyArray<Application>, Project>({
            queryFn: (project) => wrapPromise(fetcher.getApplications(project)),
            providesTags: (applications, _error, project) =>
                tags('Application').listFor(project).withItems(applications).toArray(),
        }),
        addApplication: build.mutation<void, { project: Project; applicationName: string; zone: Zone }>({
            queryFn: ({ project, applicationName, zone }) =>
                wrapPromise(fetcher.addApplication(project, applicationName, zone)),
            invalidatesTags: (_, _error, { project }) => tags('Application').listFor(project).toArray(),
        }),
        deleteApplication: build.mutation<void, Application>({
            queryFn: (application) => wrapPromise(fetcher.deleteApplication(application)),
            invalidatesTags: (_void, _error, application) => [
                { type: 'Application', id: application._links.self.href },
                {
                    type: 'ApplicationConfig',
                    id: application._links.config.href,
                },
                {
                    type: 'ApplicationDeployments',
                    id: application._links.self.href,
                },
            ],
        }),
        getZone: build.query<Zone, TypedLink<Zone>>({
            queryFn: (zoneUrl) => wrapPromise(fetcher.getZone(zoneUrl)),
            providesTags: (zone) => itemTags('Zone', zone),
        }),
        getZones: build.query<Zone[], Organization>({
            queryFn: (org) => wrapPromise(fetcher.getZones(org)),
            providesTags: (zones, _error, org) => tags('Zone').listFor(org).withItems(zones).toArray(),
        }),
        // Changesets are immutable, so we never need to invalidate them, so we don't need any tags
        getChangeset: build.query<Changeset, TypedLink<Changeset>>({
            queryFn: (changeset) => wrapPromise(fetcher.getChangeset(changeset)),
        }),
        // Changesets and their ancestry are immutable, so we never need to invalidate them, so we don't need any tags
        getChangesetAncestry: build.query<
            readonly Changeset[],
            {
                changeset: TypedLink<Changeset> | Changeset;
                untilAncestor?: TypedLink<Changeset>;
            }
        >({
            queryFn: ({ changeset, untilAncestor }) =>
                wrapPromise(fetcher.getChangesetAncestry(changeset, untilAncestor)),
        }),
        getApplicationConfig: build.query<ApplicationConfig, Application>({
            queryFn: (application) => wrapPromise(fetcher.getApplicationConfig(application)),
            providesTags: (config, _error) => itemTags('ApplicationConfig', config),
        }),
        getWebapps: build.query<ReadonlyArray<ApplicationWebapp>, Application>({
            queryFn: (application) => wrapPromise(fetcher.getApplicationWebapps(application)),
            providesTags: (webapps, _error, application) => [
                ...tags('ApplicationWebapps').listFor(application).withItems(webapps).toArray(),
            ],
        }),
        updateApplicationConfig: build.mutation<void, ApplicationConfig>({
            queryFn: (config) => wrapPromise(fetcher.updateApplicationConfig(config)),
            invalidatesTags: (_app, _error, config) => itemTags('ApplicationConfig', config),
        }),
        deployChangesetToApp: build.mutation<void, { application: Application; changeset: TypedLink<Changeset> }>({
            queryFn: ({ application, changeset }) => wrapPromise(fetcher.deployChangesetToApp(application, changeset)),
            invalidatesTags: (_config, _error, deployArgs) => [
                {
                    type: 'ApplicationDeployments',
                    id: deployArgs.application._links.self.href,
                },
                {
                    type: 'Application',
                    id: deployArgs.application._links.self.href,
                },
            ],
        }),
        getDeploymentsForApplication: build.query<ReadonlyArray<Deployment>, Application>({
            queryFn: (application) => wrapPromise(fetcher.getDeploymentsForApplication(application)),
            providesTags: (_config, _error, application) => [
                {
                    type: 'ApplicationDeployments',
                    id: application._links.self.href,
                },
            ],
        }),
        getApplicationHealthInfo: build.query<ApplicationHealth, Application>({
            queryFn: (app) => wrapPromise(fetcher.getApplicationHealthInfo(app)),
        }),
    }),
});

export const {
    useGetApplicationsForProjectQuery,
    useGetZoneQuery,
    useGetZonesQuery,
    useGetChangesetQuery,
    useGetChangesetAncestryQuery,
    useAddApplicationMutation,
    useGetApplicationConfigQuery,
    useGetWebappsQuery,
    useGetDeploymentsForApplicationQuery,
    useDeployChangesetToAppMutation,
    useUpdateApplicationConfigMutation,
    useDeleteApplicationMutation,
    useGetApplicationHealthInfoQuery,
} = applicationsApi;
