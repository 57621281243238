import React from 'react';
import { Link as MuiLink, Theme, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { To } from 'react-router';
import { Link as NavLink } from 'react-router-dom';

type BackLinkProps = {
    to: To;
    children: React.ReactNode;
};

const useStyles = makeStyles((_theme: Theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
    },
}));

export default function BackLink({ to, children }: BackLinkProps) {
    const classes = useStyles();
    return (
        <MuiLink className={classes.root} component={NavLink} to={to}>
            <ArrowBack style={{marginRight : "5px"}}fontSize="inherit" />
            {children}
        </MuiLink>
    );
}
