import { itemTags, tags, wrapPromise } from '../../repository/rtk-query';
import { Blueprint } from '../../repository/models/Blueprint';
import fetcher from '../../repository';
import { ExpressionDto, PolicyCreationDto, PolicyDto, PolicyUpdateDto } from '../../repository/models/PolicyDto';
import api from '../../store/api';
import {
    PolicyConditionSuggestion,
    PolicyConditionSuggestionDescription,
} from '../../repository/models/PolicyConditionSuggestion';

const permissionsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPoliciesForEntity: build.query<ReadonlyArray<PolicyDto>, { blueprint: Blueprint; entity: string }>({
            queryFn: ({ blueprint, entity }) => wrapPromise(fetcher.getPoliciesForEntity(blueprint, entity)),
            providesTags: (policies, _error, { blueprint }) =>
                tags('Policy').listFor(blueprint).withItems(policies).toArray(),
        }),
        getPolicy: build.query<PolicyDto | undefined, { blueprint: Blueprint; id: string }>({
            queryFn: ({ blueprint, id }) => wrapPromise(fetcher.getPolicy(blueprint, id)),
            providesTags: (policy) => itemTags('Policy', policy),
        }),
        addPolicy: build.mutation<PolicyDto, { blueprint: Blueprint; policy: PolicyCreationDto }>({
            queryFn: ({ blueprint, policy }) => wrapPromise(fetcher.addPolicy(blueprint, policy)),
            invalidatesTags: (_policy, _error, { blueprint }) => [
                ...tags('Policy').listFor(blueprint).toArray(),
                ...itemTags('Blueprint', blueprint, (bp) => bp._links.self.href),
            ],
        }),
        updatePolicy: build.mutation<PolicyDto, { policy: PolicyDto; update: PolicyUpdateDto }>({
            queryFn: ({ policy, update }) => wrapPromise(fetcher.updatePolicy(policy, update)),
            invalidatesTags: (policy) => [
                ...itemTags('Policy', policy),
                ...itemTags('Blueprint', policy, (p) => p._links.blueprint.href),
            ],
        }),
        deletePolicy: build.mutation<void, PolicyDto>({
            queryFn: (policy) => wrapPromise(fetcher.deletePolicy(policy)),
            // FIXME: Only invalidate specific policies list and the deleted policy
            invalidatesTags: (_, _e, policy) => [
                { type: 'Policy' },
                ...itemTags('Blueprint', policy, (p) => p._links.blueprint.href),
            ],
        }),
        getPolicyConditionSuggestions: build.query<
            PolicyConditionSuggestion,
            {
                blueprint: Blueprint;
                entity: string;
                condition?: ExpressionDto;
                description?: PolicyConditionSuggestionDescription;
            }
        >({
            queryFn: ({ blueprint, entity, ...data }) =>
                wrapPromise(fetcher.getPolicyConditionSuggestion(blueprint, entity, data)),
            providesTags: (_suggestion, _error, { blueprint }) => [...itemTags('Blueprint', blueprint)],
        }),
    }),
});

export const {
    useGetPoliciesForEntityQuery,
    useGetPolicyQuery,
    useAddPolicyMutation,
    useUpdatePolicyMutation,
    useDeletePolicyMutation,
    useGetPolicyConditionSuggestionsQuery,
} = permissionsApi;

export default permissionsApi;
