import { CircularProgress, createStyles, List, makeStyles, Theme } from '@material-ui/core';
import React, { ComponentType } from 'react';
import { ReactNode } from 'react';
import { Entity } from '../repository/models/Entity';

interface EntityComponentProps<E extends Entity> {
    readonly entity: E;
    readonly selected: boolean;
}

export type EntitySelectorProps<E extends Entity> = {
    entities: readonly E[] | null;
    selectedEntity: E | null;
    EntityComponent: ComponentType<EntityComponentProps<E>>;
    actions?: ReactNode;
    children: ReactNode;
};

export default function EntitySelector<E extends Entity>(props: EntitySelectorProps<E>) {
    const classes = useStyles();
    return (
        <div className={classes.layout}>
            <nav className={classes.entityNav}>
                {props.entities === null ? (
                    <CircularProgress />
                ) : (
                    <List>
                        {props.entities.map((entity) => (
                            <props.EntityComponent
                                key={entity.name}
                                entity={entity}
                                selected={props.selectedEntity === entity}
                            />
                        ))}
                    </List>
                )}
                {props.actions ?? null}
            </nav>
            <div className={classes.entityContent}>{props.children}</div>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        layout: {
            display: 'flex',
            flexGrow: 1,
            gap: theme.spacing(3),
        },
        entityNav: {
            width: '200px',
            minWidth: '200px',
            borderRight: '1px solid',
            borderRightColor: theme.palette.divider,
        },
        entityContent: {
            flexGrow: 1,
        },
    }),
);
