import React, { lazy, Suspense } from 'react';
import { Box, ButtonBase, CircularProgress, Collapse, Fab, ListItemText, makeStyles, Paper } from '@material-ui/core';
import type { DatamodelAssistantProps } from './DataModelAssistant';
import { AIIcon } from '../../../ui/icons/AiIcon';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import UnfoldLess from '@material-ui/icons/UnfoldLess';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from '../../../store';
import { refuseAssistanceThunk, selectIsCollapsed, selectIsRefused, selectIsRequested, toggleAssistantCollapsedThunk } from './datamodelAssistantSlice';
import AssistantWelcomePopup from './AssistantWelcomePopup';

const DataModelAssistant = lazy(() => import('./DataModelAssistant'));

const useStyles = makeStyles((theme) => ({
    container: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: '500px',
        position: 'fixed',
        bottom: 0,
        right: theme.spacing(3),
        zIndex: 100,
        maxHeight: 'calc(100vh - 5em)',
        display: 'flex',
        flexDirection: 'column',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 100,
    },
    collapse: {
        padding: theme.spacing(0, 3, 0, 3),
        display: 'flex',
        flexDirection: 'column',
        '& > .MuiCollapse-wrapper': {
            minHeight: 0, // needed to make work the flex shrinking of the whole popup, where the messages container becomes scrollable
        },
    },
    openClose: {
        padding: theme.spacing(1, 2, 1, 2),
        textAlign: 'left',
        transition: theme.transitions.create('background-color', { duration: theme.transitions.duration.shortest }),
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
}));

// currentUser, currentThread, handleSetCurrentThread are handled by connect() wiring.
// Other props we inherit from DataModelAssistant and pass on to it
type Omitted = 'currentUser' | 'currentThread' | 'handleSetCurrentThread';
type AssistantContainerProps = Omit<DatamodelAssistantProps, Omitted> & {
    assistanceRequested: boolean | null;
    assistanceRefused: boolean | null;
    assistanceCollapsed: boolean | null;
    onToggleCollapse: () => void;
};

const AssistantContainer = (props: AssistantContainerProps) => {
    const classes = useStyles();

    if (props.blueprint?._links.assistant === undefined) {
        return null; // Don't show the assistant ui if the feature is disabled
    }

    if (props.assistanceRefused) {
        return (
            <Fab
                size="small"
                className={classes.fab}
                aria-label="AI assistant"
                onClick={() => props.onRefuseAssistance(false)}
            >
                <AIIcon fontSize="small" />
            </Fab>
        );
    }

    return (
        <>
            <Box height="12em">
                {/* Spacer to make room for datamodel assistant, so you can scroll the normal page content out from behind it. */}
            </Box>
            <Paper elevation={3} className={classes.container}>
                <ButtonBase className={classes.openClose} onClick={props.onToggleCollapse}>
                    <Box flexShrink="0" minWidth="3em">
                        <AIIcon />
                    </Box>
                    <ListItemText primary={'ContentGrid Assistant'} />
                    {props.assistanceCollapsed ? <UnfoldLess /> : <UnfoldMore />}
                </ButtonBase>
                <Collapse in={props.assistanceCollapsed ?? false} timeout={360} className={classes.collapse}>
                    {props.assistanceRequested ? (
                        <Suspense fallback={<CircularProgress />}>
                            <DataModelAssistant {...props} />
                        </Suspense>
                    ) : (
                        <AssistantWelcomePopup />
                    )}
                </Collapse>
            </Paper>
        </>
    );
};

export default connect(
    (state: AppState) => ({
        assistanceRequested: selectIsRequested(state),
        assistanceRefused: selectIsRefused(state),
        assistanceCollapsed: selectIsCollapsed(state),
    }),
    (dispatch: AppDispatch) => ({
        onRefuseAssistance: (refuse: boolean) => dispatch(refuseAssistanceThunk(refuse)),
        onToggleCollapse: () => dispatch(toggleAssistantCollapsedThunk()),
    }),
)(AssistantContainer);
