import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import { Relation } from '../../repository/models/Entity';
import { WithoutHal } from '../../hal';
import { Card } from '../../ui/Card';
import { RequireIcon } from '../../ui/icons/RequireIcon';
import { HighlightedDatamodelPropertyContext } from './HighlightedDataModelContext';

type RelationModelMinimizedProps = {
    relation: WithoutHal<Relation>;
    onClick: () => void;
};

export default function RelationModelMinimized(props: RelationModelMinimizedProps) {
    const rel = props.relation;

    const textSource = rel.many_source_per_target ? `Many ${rel.source}s` : `One ${rel.source}`;
    const textTarget = rel.many_target_per_source ? `Many ${rel.target}s` : `One ${rel.target}`;

    const { highlightedDatamodelProperty } = useContext(HighlightedDatamodelPropertyContext);
    const isHighlighted = highlightedDatamodelProperty === rel.name;

    return (
        <Card minHeight={false} onClick={props.onClick} highlighted={isHighlighted}>
            <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                <Grid item xs={4}>
                    <Typography noWrap>
                        <Tooltip title={rel.description ?? ''}>
                            <strong>{rel.name}</strong>
                        </Tooltip>
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography color="textSecondary">{textSource + ' → ' + textTarget}</Typography>
                </Grid>

                <Grid item xs={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title={rel.required ? 'Values are required' : 'Values are not required'}>
                            <RequireIcon color={rel.required ? 'inherit' : 'disabled'} />
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}
