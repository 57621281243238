import { Box, Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import FingerPrintIcon from '@material-ui/icons/Fingerprint';
import SearchIcon from '@material-ui/icons/Search';

import { Attribute } from '../../repository/models/Entity';
import { WithoutHal } from '../../hal';
import { Card } from '../../ui/Card';
import { RequireIcon } from '../../ui/icons/RequireIcon';
import { HighlightedDatamodelPropertyContext } from './HighlightedDataModelContext';

type AttributeModelMinimizedProps = {
    attribute: WithoutHal<Attribute>;
    onClick?: () => void;
};

const translateType = (type: string) =>
    (
        ({
            STRING: 'Text',
            LONG: 'Integer',
            DOUBLE: 'Decimal',
            DATETIME: 'Date',
            BOOLEAN: 'Boolean',
            CONTENT: 'Content',
            FAKE_ID: 'UUID',
            AUDIT_METADATA: 'Audit Metadata',
        }) as any
    )[type];

export default function AttributeModelMinimized(props: AttributeModelMinimizedProps) {
    const attr = props.attribute;
    const extraText =
        props.attribute.type === 'FAKE_ID' ? (
            <Chip disabled variant="outlined" size="small" label="Primary Key" />
        ) : null;

    const { highlightedDatamodelProperty } = useContext(HighlightedDatamodelPropertyContext);
    const isHighlighted = highlightedDatamodelProperty === attr.name;
    const isSearchable = !!attr._embedded?.['search-options']?.length;

    return (
        <Card minHeight={false} onClick={props.onClick} disabled={!props.onClick} highlighted={isHighlighted}>
            <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                <Grid item xs={6}>
                    <Tooltip title={attr.description ?? ''}>
                        <Typography component="span" noWrap>
                            <strong>{attr.name}</strong> {extraText}
                        </Typography>
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Typography color="textSecondary">{translateType(attr.type)}</Typography>
                </Grid>

                <Grid item xs={3}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box mr={2}>
                            <Tooltip title={attr.unique ? 'Values are unique' : 'Values are not unique'}>
                                <FingerPrintIcon color={attr.unique ? 'inherit' : 'disabled'} />
                            </Tooltip>
                        </Box>
                        <Box mr={2}>
                            <Tooltip title={attr.required ? 'Values are required' : 'Values are not required'}>
                                <RequireIcon color={attr.required ? 'inherit' : 'disabled'} />
                            </Tooltip>
                        </Box>
                        <Tooltip title={isSearchable ? 'Values are searchable' : 'Values are not searchable'}>
                            <SearchIcon color={isSearchable ? 'inherit' : 'disabled'} />
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}
