import { Action, createSelector } from '@reduxjs/toolkit';
import { createBrowserHistory, Location } from 'history';
import {
    CALL_HISTORY_METHOD,
    createReduxHistoryContext,
    LOCATION_CHANGE,
    push,
    replace,
    RouterActions,
} from 'redux-first-history';
import { AppState } from '../../store';
import { AppThunkAction } from '../../store/store';
import { BaseRoute, Route } from './_internal';
import { PathMatchRequiredVariables } from './_internal/types';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
    // This ensures that our application reducers also get the navigation actions
    showHistoryAction: true,
});
export default routerReducer;

interface ReplaceVariableActionParams<Pattern extends string, K extends string> {
    readonly route: Route<Pattern, K>;
    readonly params: Record<K, string>;
    readonly operation?: typeof push | typeof replace;
}

export function routerReplaceVariable<P extends string, K extends string>(
    command: ReplaceVariableActionParams<P, K>,
): AppThunkAction<boolean> {
    return (dispatch, getState) => {
        const currentPath = selectRouterLocation(getState())?.pathname;
        const newPath = command.route.replace(currentPath, command.params);

        const dispatchPath = newPath ?? command.route.generate(command.params);

        if (dispatchPath) {
            dispatch((command.operation ?? push)(dispatchPath));
        }

        return newPath !== null;
    };
}

interface NavigateActionParams<Pattern extends string, K extends string> {
    readonly route: Route<Pattern, K>;
    readonly operation?: typeof push | typeof replace;
}

export function routerNavigate<P extends string, K extends string>(
    command: NavigateActionParams<P, K>,
): AppThunkAction<boolean> {
    return (dispatch, getState) => {
        const currentPath = selectRouterLocation(getState())?.pathname;
        const newPath = command.route.navigate(currentPath, command.route);

        if (newPath) {
            dispatch((command.operation ?? push)(newPath));
        }

        return newPath !== null;
    };
}

export { routerMiddleware, createReduxHistory };

export function selectRouterState(state: AppState) {
    return state.router;
}

export const selectRouterLocation = createSelector(selectRouterState, (router) => router.location);

export const selectRouterPath = createSelector(selectRouterLocation, (location) => location?.pathname ?? null);

// Action matchers
function _isRouterAction(action: Action): action is RouterActions {
    return action.type === LOCATION_CHANGE || action.type === CALL_HISTORY_METHOD;
}

export function isRouterUrlVisit(route: BaseRoute): (action: Action) => action is RouterActions {
    return matchRouterLocation((location) => route.match(location.pathname) !== null);
}

export function matchRouterLocation(
    matcher: (location: Location) => boolean,
): (action: Action) => action is RouterActions {
    return function (action: Action): action is RouterActions {
        if (!_isRouterAction(action)) return false;
        if (action.type === LOCATION_CHANGE) {
            return matcher(action.payload.location);
        }
        return false;
    };
}

export function getRouterUrlParams<Variables extends string>(
    route: BaseRoute<string, Variables>,
    action: RouterActions,
): PathMatchRequiredVariables<Variables> | null {
    if (action.type === LOCATION_CHANGE) {
        return route.match(action.payload.location.pathname);
    }
    return null;
}
