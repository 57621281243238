import { createStyles, makeStyles, Tabs, TabsProps, Theme } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { isLinkTab } from './LinkTab';

type LinkTabsProps = Omit<TabsProps, 'value'>;

export default function LinkTabs({ children, ...props }: LinkTabsProps) {
    const classes = useStyles();
    const location = useLocation();
    const childrenMatch = React.Children.toArray(children).map((child) => {
        if (!isLinkTab(child)) {
            throw new Error('Children of LinkTabs must be of type LinkTab');
        }
        return [child.props.to, ...(child.props.activeRoutes ?? [])].some((route) => route.match(location.pathname));
    });
    // Take the first tab index that matches
    const matchIndex = childrenMatch.indexOf(true);
    return (
        <div className={classes.root}>
            <Tabs {...props} value={matchIndex}>
                {children}
            </Tabs>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: 'solid 1px',
            borderBottomColor: theme.palette.divider,
            margin: theme.spacing(2, 0, 2, 0),
            '& .Mui-selected .MuiTab-wrapper': {
                fontWeight: 600,
            },
        },
    }),
);
