import React from 'react';
import { connect } from 'react-redux';

import { selectCurrentOrganization } from '../account/accountSlice';
import AutoBreadcrumbs from '../routes/AutoBreadcrumbs';
import { Organization } from '../../repository/models/Organization';
import { AppState } from '../../store';
import LinkTabs from '../../ui/tabs/LinkTabs';
import LinkTab from '../../ui/tabs/LinkTab';
import { ORGANIZATION_DASHBOARD, ORGANIZATION_MEMBERS, ORGANIZATION_ROOT } from '../routes';
import { Outlet } from 'react-router-dom';

type OrganizationDashboardProps = {
    currentOrganization: Organization | null;
};

function OrganizationDashboard({ currentOrganization }: OrganizationDashboardProps) {
    if (currentOrganization === null) {
        return null;
    }

    return (
        <>
            <AutoBreadcrumbs>
                <>
                    <strong>{currentOrganization.display_name}</strong> dashboard
                </>
            </AutoBreadcrumbs>
            <LinkTabs indicatorColor="primary">
                <LinkTab label="Projects" from={ORGANIZATION_ROOT} to={ORGANIZATION_DASHBOARD} />
                <LinkTab label="Members" from={ORGANIZATION_ROOT} to={ORGANIZATION_MEMBERS} />
            </LinkTabs>
            <Outlet />
        </>
    );
}

export default connect((state: AppState) => ({
    currentOrganization: selectCurrentOrganization(state),
}))(OrganizationDashboard);
