import { useMatch, useNavigate } from 'react-router-dom';
import { BLUEPRINT_MODEL, DATAMODEL_ATTRIBUTE } from '../routes';
import { useGetAttributeQuery, useGetEntityListQuery, usePatchAttributeMutation } from './api';
import { Blueprint } from '../../repository/models/Blueprint';
import { useCallback, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { paramsToRecord } from '../routes/helpers';
import { resolveTemplateRequired, WithoutHal } from '../../hal';
import { Attribute } from '../../repository/models/Entity';
import { RequestStateHandler } from '../../ui/RequestStateHandler';
import React from 'react';
import AutoBreadcrumbs from '../routes/AutoBreadcrumbs';
import Link from '../../ui/Link';
import { AppState } from '../../store';
import { selectCurrentBlueprint } from '../blueprint/blueprintSlice';
import { connect } from 'react-redux';
import AttributeModelForm from './AttributeModelForm';
import AssistantContainer from './DataModelAssistant/AssistantContainer';

interface AttributeModelProps {
    currentBlueprint: Blueprint;
}

function AttributeModelEdit(props: AttributeModelProps) {
    const routeMatchEdit = useMatch({ path: DATAMODEL_ATTRIBUTE.pattern })!;

    const navigate = useNavigate();

    const {
        data: entityList,
        isLoading: isEntityLoading,
        error: entityError,
        refetch: refetchDataModel,
    } = useGetEntityListQuery(props.currentBlueprint);
    const currentEntity = useMemo(
        () => entityList?._embedded?.entities?.find((e) => e.name === routeMatchEdit.params.entity),
        [entityList, routeMatchEdit.params.entity],
    );
    const {
        data: attribute,
        isLoading: isAttributeLoading,
        error: attributeError,
    } = useGetAttributeQuery(
        currentEntity ? { entity: currentEntity, attributeName: routeMatchEdit.params.attribute! } : skipToken,
    );

    const [updateAttribute, { isLoading: isSaving, error: updateError }] = usePatchAttributeMutation();

    const parentUrl =
        BLUEPRINT_MODEL.generate(paramsToRecord(routeMatchEdit.params!)!) + '?entity=' + routeMatchEdit.params.entity!;

    const backToOverview = useCallback(() => {
        navigate(parentUrl);
    }, [navigate, parentUrl]);

    const onSave = useCallback(
        async (data: Partial<WithoutHal<Attribute>>) => {
            await updateAttribute({ attribute: attribute!, patch: data });
            backToOverview();
        },
        [updateAttribute, attribute, backToOverview],
    );

    if (isEntityLoading || entityError) {
        return <RequestStateHandler isLoading={isEntityLoading} error={entityError} />;
    }
    if (isAttributeLoading || attributeError) {
        return <RequestStateHandler isLoading={isAttributeLoading} error={attributeError} />;
    }

    if (!attribute || !currentEntity) {
        return null;
    }

    return (
        <>
            <AutoBreadcrumbs collapseDefaults>
                <Link to={parentUrl}>
                    Entity <strong>{currentEntity.name}</strong>
                </Link>
                <>
                    Edit attribute <strong>{attribute.name}</strong>
                </>
            </AutoBreadcrumbs>
            <AttributeModelForm
                // Ensure whole component re-renders when attribute is changed
                key={attribute._links.self.href}
                currentBlueprint={props.currentBlueprint}
                form={resolveTemplateRequired(attribute, 'default')}
                entity={currentEntity}
                attribute={attribute}
                isSaving={isSaving}
                error={updateError}
                onSave={onSave}
                onRename={(attribute) => {
                    navigate(
                        DATAMODEL_ATTRIBUTE.generate(
                            paramsToRecord({
                                ...routeMatchEdit.params!,
                                attribute: attribute.name,
                            })!,
                        ),
                    );
                }}
                onCancel={backToOverview}
                onDelete={backToOverview}
            />

            <AssistantContainer
                blueprint={props.currentBlueprint}
                refetchDataModel={refetchDataModel}
                autoNavigation={false}
            />
        </>
    );
}

export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state)!,
}))(AttributeModelEdit);
