import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ExpressionDto } from '../../repository/models/PolicyDto';
import TokenDisplay from './TokenDisplay';
import { Expression } from '../../repository/models/Policy';

interface PolicyConditionProps {
    readonly condition: ExpressionDto;
    readonly entityName: string;
}

export default function PolicyCondition({ condition, entityName }: PolicyConditionProps) {
    const expression = useMemo(() => Expression.fromDto(condition), [condition]);
    return (
        <Typography variant="body2">
            <code>
                <TokenDisplay token={expression.left} entityName={entityName} />
            </code>
            <span> {expression.shortOperator()} </span>
            <code>
                <TokenDisplay token={expression.right} entityName={entityName} />
            </code>
        </Typography>
    );
}
