import {
    combineReducers,
    createAction,
    createReducer,
    createSelector,
    ThunkDispatch,
    UnknownAction,
} from '@reduxjs/toolkit';
import { Thread } from '../../../repository/models/Assistant';
import { withTypedPayload } from '@xenit/redux-utils';
import { AppState } from '../../../store';
import { selectCurrentBlueprint } from '../../blueprint/blueprintSlice';
import { Blueprint } from '../../../repository/models/Blueprint';

export const setAssistantThread = createAction(
    'datamodel/assistant/setCurrent',
    withTypedPayload<{ bp: Blueprint; thread: Thread }>(),
);
const requestAssistance = createAction('datamodel/assistant/request', withTypedPayload<Blueprint>());
const refuseAssistance = createAction(
    'datamodel/assistant/refuse',
    withTypedPayload<{ bp: Blueprint; refuse: boolean }>(),
);
const toggleAssistantCollapsed = createAction('datamodel/assistant/toggleCollapsed');

interface DatamodelAssistantContext {
    readonly threadMapping: { [blueprintLink: string]: Thread };
    readonly requestedFor: string[];
    readonly refusedFor: string[];
    readonly isCollapsed: boolean;
}

export const requestAssistanceThunk =
    () => (dispatch: ThunkDispatch<AppState, unknown, UnknownAction>, getState: () => AppState) => {
        const state = getState();
        const blueprint = selectCurrentBlueprint(state);
        if (blueprint) {
            dispatch(requestAssistance(blueprint));
        }
    };

export const refuseAssistanceThunk =
    (refuse: boolean) => (dispatch: ThunkDispatch<AppState, unknown, UnknownAction>, getState: () => AppState) => {
        const state = getState();
        const blueprint = selectCurrentBlueprint(state);
        if (blueprint) {
            dispatch(refuseAssistance({ bp: blueprint, refuse }));
        }
    };

export const toggleAssistantCollapsedThunk = () => (dispatch: ThunkDispatch<AppState, unknown, UnknownAction>) => {
    dispatch(toggleAssistantCollapsed());
};

const datamodelAssistantContextReducer = createReducer<DatamodelAssistantContext>(
    { threadMapping: {}, requestedFor: [], refusedFor: [], isCollapsed: false },
    (builder) =>
        builder
            .addCase(setAssistantThread, (state, action) => {
                state.threadMapping[action.payload.bp._links.self.href] = action.payload.thread;
            })
            .addCase(requestAssistance, (state, action) => {
                state.requestedFor.push(action.payload._links.self.href);
            })
            .addCase(refuseAssistance, (state, action) => {
                const bpLink = action.payload.bp._links.self.href;
                if (action.payload.refuse) {
                    state.refusedFor.push(bpLink);
                } else {
                    state.refusedFor = state.refusedFor.filter((id) => id !== bpLink);
                }
            })
            .addCase(toggleAssistantCollapsed, (state) => {
                state.isCollapsed = !state.isCollapsed;
            }),
);

export default combineReducers({
    currentContext: datamodelAssistantContextReducer,
});

function selectSlice(state: AppState) {
    return state.datamodelAssistant;
}

export const selectIsRequested = createSelector(
    (state: AppState) => state,
    selectCurrentBlueprint,
    (state, bp) => {
        return bp && selectSlice(state).currentContext.requestedFor.includes(bp._links.self.href);
    },
);

export const selectIsRefused = createSelector(
    (state: AppState) => state,
    selectCurrentBlueprint,
    (state, bp) => {
        return bp && selectSlice(state).currentContext.refusedFor.includes(bp._links.self.href);
    },
);

export const selectIsCollapsed = createSelector(
    (state: AppState) => state,
    selectCurrentBlueprint,
    (state, bp) => {
        return bp && selectSlice(state).currentContext.isCollapsed;
    },
);

export const selectCurrentThread = createSelector(
    (state: AppState) => state,
    selectCurrentBlueprint,
    (state, bp) => {
        const bpLink = bp?._links.self.href;
        if (bpLink) {
            return selectSlice(state).currentContext.threadMapping[bpLink];
        }
        return undefined;
    },
);
