import RelativeTime from '@yaireo/relative-time';
import React from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';

import { PageTitle } from '../../../../ui/typography/PageTitle';
import { useGetOrganizationMembersQuery } from '../../api';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Organization } from '../../../../repository/models/Organization';
import { RequestStateHandler } from '../../../../ui/RequestStateHandler';

const relativeTime = new RelativeTime();

interface MembersTableProps {
    organization: Organization;
}

export const MembersTable = ({ organization }: MembersTableProps) => {
    const { data: members, isLoading, error } = useGetOrganizationMembersQuery(organization ?? skipToken);

    const sortedMembers = members?.map((m) => ({ ...m, since: new Date(m.since) })).sort(compareRecency);

    return (
        <>
            <PageTitle>Members</PageTitle>
            {isLoading || !!error ? (
                <RequestStateHandler isLoading={isLoading} error={error} />
            ) : !!members ? (
                <Box mb={6}>
                    <TableContainer component={Paper}>
                        <Table aria-label="table of users">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Since</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {sortedMembers?.map((member) => (
                                    <TableRow key={member.email}>
                                        <TableCell>{member.display_name}</TableCell>
                                        <TableCell>{member.email}</TableCell>
                                        <TableCell>
                                            <Tooltip title={member.since.toLocaleString()}>
                                                <span>{relativeTime.from(member.since)}</span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : null}
        </>
    );
};

const compareRecency = (a: { since: Date }, b: { since: Date }) => b.since.getTime() - a.since.getTime();
