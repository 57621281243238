import React from 'react';
import { SerializedError } from '@reduxjs/toolkit';

import { ProgressIndicator } from './ProgressIndicator';
import { ServerErrorMessage } from './ServerErrorMessage';

interface RequestStateHandlerProps {
    isLoading?: boolean;
    error?: SerializedError;
}

export const RequestStateHandler = ({ isLoading, error }: RequestStateHandlerProps) => {
    if (isLoading) {
        return <ProgressIndicator />;
    }

    return <ServerErrorMessage error={error} />;
};
