import React, { ReactNode } from 'react';
import { withStyles, WithStyles, CardActionArea, createStyles } from '@material-ui/core';
import cx from 'classnames';
import { StyledCard } from './styles/StyledCard';

const styles = () =>
    createStyles({
        '@keyframes highlightAnimation': {
            '0%': {
                transform: 'scale(1)',

                boxShadow: '0 0 0 0 rgba(114, 237, 242, 0.7)',
            },
            '50%': {
                transform: 'scale(1.03)',

                boxShadow: '0 0 0 20px rgba(114, 237, 242, 0)',
            },
            '100%': {
                transform: 'scale(1)',
                boxShadow: '0 0 0 0 rgba(114, 237, 242, 0)',
            },
        },
        wrapper: {
            transition: 'background 1000ms ease-in-out',
            '&.is-active': {
                '&:hover': {
                    backgroundColor: 'none',
                    '&::before': {
                        opacity: '1',
                        transition: 'opacity 100ms ease-out',
                    },
                    '&::after': {
                        opacity: '0',
                        transition: 'opacity 100ms ease-out',
                    },
                },
            },
            '&.is-disabled': {
                '&::before': {
                    visibility: 'hidden',
                },
                '&::after': {
                    visibility: 'hidden',
                },
                border: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '&.is-highlighted': {
                transition: 'background 1000ms ease-in-out',
                backgroundColor: '#59DEE3',
                animation: '$highlightAnimation 3s ease-out',
                backgroundSize: '400% 400%',
            },
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            padding: '0.5rem 1rem',
            flexDirection: 'column',
            width: '100%',
            '&.min-height': {
                minHeight: '6rem',
                padding: '1rem',
            },
        },
    });

interface CardProps extends WithStyles<typeof styles> {
    minHeight?: boolean;
    children: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    highlighted?: boolean;
}

const CardComponent = ({ classes, minHeight = true, children, onClick, disabled, highlighted }: CardProps) => {
    const wrapperClasses = cx(classes.wrapper, {
        'is-active': !!onClick,
        'is-disabled': disabled,
        'is-highlighted': highlighted,
    });
    const contentClasses = cx(classes.content, { 'min-height': minHeight });

    return (
        <StyledCard className={wrapperClasses}>
            {!onClick || disabled ? (
                <div className={contentClasses}>{children}</div>
            ) : (
                <CardActionArea>
                    <div className={contentClasses} onClick={onClick}>
                        {children}
                    </div>
                </CardActionArea>
            )}
        </StyledCard>
    );
};

export const Card = withStyles(styles)(CardComponent);
