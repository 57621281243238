import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, FormControl, Grid, Link, MenuItem, Typography, styled } from '@material-ui/core';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { connect } from 'react-redux';
import { Link as NavLink, useNavigate, useParams } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import AutoBreadcrumbs from '../routes/AutoBreadcrumbs';
import BusyButton from '../../BusyButton';
import TextInput from '../../ui/input/TextInput';
import { ServerErrorMessage } from '../../ui/ServerErrorMessage';
import { Changeset, ChangesetOperation } from '../../repository/models/Changeset';
import {
    useGetBlueprintOverviewsQuery,
    useGetBlueprintStagedHistoryQuery,
    useGetBlueprintsQuery,
    useGetChangesetQuery,
} from '../blueprint/api';
import { useAddReleaseMutation } from './api';
import { AppState } from '../../store';
import { selectCurrentBlueprint } from '../blueprint/blueprintSlice';
import { selectCurrentProject } from '../project/projectSlice';
import { selectCurrentOrganization } from '../account/accountSlice';
import { Organization } from '../../repository/models/Organization';
import { Project } from '../../repository/models/Project';
import { Blueprint } from '../../repository/models/Blueprint';
import { BLUEPRINT_PERMISSIONS, RELEASE_SHOW } from '../routes';
import { RequestStateHandler } from '../../ui/RequestStateHandler';
import { HighlightedComponent, releasesCreateLocation, useTourLocation } from '../tour';
import { resolveTemplate, RForm, TypedLink } from '../../hal';
import ValidatedTextInput from '../../hal/forms/ValidatedTextInput';
import { inheritHalProperty } from '../../hal/forms/react';
import { StyledSelect } from '../../ui/styles/StyledSelect';
import { ProgressIndicator } from '../../ui/ProgressIndicator';
import { Features } from './components/Features';

type CreateReleaseProps = {
    currentOrganization: Organization | null;
    currentProject: Project | null;
    currentBlueprint: Blueprint | null;
};

const HalValidatedTextInput = inheritHalProperty(ValidatedTextInput);

const Row = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
    '& > * + *': {
        marginLeft: '1em',
    },
    marginBottom: '1em',
});

const CreateRelease = (props: CreateReleaseProps) => {
    const navigate = useNavigate();

    const { blueprint: blueprintParam } = useParams();

    const [label, setLabel] = useState('');
    const [message, setMessage] = useState('');

    const [addRelease, { isLoading, error }] = useAddReleaseMutation();
    const [, markVisited] = useTourLocation(releasesCreateLocation);

    const { data: blueprints } = useGetBlueprintsQuery(props.currentProject ?? skipToken);
    const { data: blueprintOverviews } = useGetBlueprintOverviewsQuery(props.currentProject ?? skipToken);
    const blueprintsWithChanges = (blueprintOverviews ?? [])
        .filter((item) => item.unreleased_changes !== 0)
        .map((item) => {
            return blueprints?.find((bp) => bp?.name === item?.name);
        }) as Blueprint[];

    const [userSelectedBlueprint, setSelectedBlueprint] = useState<Blueprint | null>(null);
    const selectedBlueprint = userSelectedBlueprint ?? props.currentBlueprint ?? blueprintsWithChanges?.[0] ?? null;
    const stagedChangeset = selectedBlueprint?._links['staged-changeset']?.href;

    const onPublish = useCallback(() => {
        if (!selectedBlueprint || !stagedChangeset) {
            return;
        }

        addRelease({
            blueprint: selectedBlueprint,
            changeset: stagedChangeset,
            label,
            message,
        }).then((response) => {
            if ('data' in response && response.data) {
                const releaseLink = RELEASE_SHOW.generate({
                    org: props.currentProject!.organization,
                    project: props.currentProject!.name,
                    release: response.data.label,
                });

                markVisited();
                navigate(releaseLink);
            }
        });
    }, [addRelease, label, message, props, navigate, selectedBlueprint, stagedChangeset, markVisited]);

    const template = !!selectedBlueprint ? resolveTemplate(selectedBlueprint, 'createRelease') : null;

    if (selectedBlueprint === null) {
        return (
            <>
                <AutoBreadcrumbs>Create Release</AutoBreadcrumbs>

                <ProgressIndicator />
            </>
        );
    }

    return (
        <>
            <AutoBreadcrumbs>Create Release</AutoBreadcrumbs>
            <Typography variant="h6">
                Changes for{' '}
                {!!blueprintParam ? (
                    <i>{props.currentBlueprint?.name}</i>
                ) : (
                    <BlueprintSelector
                        value={selectedBlueprint?.name}
                        options={blueprintsWithChanges ?? []}
                        onChange={(value) =>
                            setSelectedBlueprint(blueprintsWithChanges.find((item) => item.name === value) as Blueprint)
                        }
                    />
                )}
            </Typography>

            <ChangesetHistory blueprint={selectedBlueprint} />

            {!!selectedBlueprint ? <Features blueprint={selectedBlueprint} /> : null}

            <ChangesetPolicies {...props} currentBlueprint={selectedBlueprint} stagedChangeset={stagedChangeset} />

            {!!error && <ServerErrorMessage error={error} />}

            {template !== null && (
                <RForm template={template}>
                    <Row>
                        <Typography display="inline">Provide a label uniquely identifying this release: </Typography>
                        <HighlightedComponent location={releasesCreateLocation} disabled={label !== ''}>
                            <HalValidatedTextInput
                                name="label"
                                displayName="Label"
                                autoFocus
                                placeholder="v1.2.3"
                                handleOnChange={setLabel}
                                short
                                value={label}
                            />
                        </HighlightedComponent>
                    </Row>

                    <Box mb={2}>
                        <TextInput
                            value={message}
                            placeholder="Message describing the change (optional)"
                            handleOnChange={(val) => setMessage(val)}
                            fullWidth
                            spellCheck={true}
                        />
                    </Box>
                </RForm>
            )}

            <Grid container justifyContent="flex-end">
                <Box mr={2}>
                    <Button onClick={() => navigate(-1)}>Cancel</Button>
                </Box>

                <HighlightedComponent
                    location={releasesCreateLocation}
                    disabled={!blueprintsWithChanges?.length || label === ''}
                >
                    <BusyButton
                        variant="contained"
                        disableElevation
                        color="primary"
                        busy={isLoading}
                        disabled={!blueprintsWithChanges?.length || label === ''}
                        onClick={onPublish}
                    >
                        Create Release
                    </BusyButton>
                </HighlightedComponent>
            </Grid>
        </>
    );
};

export default connect((state: AppState) => ({
    currentBlueprint: selectCurrentBlueprint(state),
    currentProject: selectCurrentProject(state),
    currentOrganization: selectCurrentOrganization(state),
}))(CreateRelease);

type ChangesetHistoryProps = {
    blueprint: Blueprint | null;
};

const ChangesetHistory = ({ blueprint }: ChangesetHistoryProps) => {
    const { data: history, isLoading, error } = useGetBlueprintStagedHistoryQuery(blueprint ?? skipToken);

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    if (!history) {
        return null;
    }

    const operations = history
        .slice()
        .reverse()
        .flatMap((changeset) => changeset.operations);

    return (
        <ul>
            {operations.map((operation: ChangesetOperation, index) => {
                return !operation.properties?.hidden ? (
                    <li key={index}>{operation.description || operation.type}</li>
                ) : null;
            })}
        </ul>
    );
};

const ChangesetPolicies = (
    props: CreateReleaseProps & {
        stagedChangeset: TypedLink<Changeset> | undefined;
    },
) => {
    const { data: changeset, isLoading, error } = useGetChangesetQuery(props.stagedChangeset ?? skipToken);

    const noPolicyEntities = useMemo(() => {
        if (changeset) {
            const entities = changeset.projections.post.entities.map((entity) => entity.name);
            const policies = changeset.projections.post.policies;

            return entities?.filter((entity) => {
                const policyList = policies?.filter((policy) => policy.entity === entity);

                return !policyList?.length;
            });
        }

        return null;
    }, [changeset]);

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    const url = BLUEPRINT_PERMISSIONS.generate({
        org: props.currentOrganization?.name!,
        project: props.currentProject?.name!,
        blueprint: props.currentBlueprint?.name!,
    });

    if (noPolicyEntities?.length) {
        return (
            <Box marginBottom={2}>
                <Alert severity="warning">
                    <Typography variant="body2" gutterBottom>
                        You are attempting to create a release without having assigned any permissions to{' '}
                        <strong>{noPolicyEntities.join(', ')}</strong>. As a result, you won't have the ability to read
                        or write any data.
                    </Typography>
                    <Link color="primary" component={NavLink} to={url}>
                        Add permissions
                    </Link>
                </Alert>
            </Box>
        );
    }

    return null;
};

interface BlueprintSelectorType {
    value: string;
    options: Blueprint[] | null;
    onChange: (value: string) => void;
}

export const BlueprintSelector = ({ value, options, onChange }: BlueprintSelectorType) => {
    return (
        <FormControl size="small">
            <StyledSelect variant="outlined" value={value ?? ''} onChange={(e) => onChange(e.target.value as string)}>
                {(options ?? []).map((item) => (
                    <MenuItem key={item?.name} value={item?.name}>
                        {item?.name}
                    </MenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    );
};
