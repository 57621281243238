import { Box, Button, CircularProgress, InputLabel, Typography, styled } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { AppDispatch, AppState } from '../../store';
import { Organization } from '../../repository/models/Organization';
import { selectCurrentOrganization } from '../account/accountSlice';
import { projectSetCreatedProject, selectProjects } from './projectSlice';
import OrganizationSelector from '../account/OrganizationSelector';
import { Project } from '../../repository/models/Project';
import { CREATE_PROJECT } from '../routes';
import FormActions from '../../ui/FormActions';
import { resolveTemplate } from '../../hal';
import { useCreateProjectMutation, useGetProjectListForOrgQuery } from './api';
import { skipToken } from '@reduxjs/toolkit/query/react';
import ValidatedTextInput from '../../hal/forms/ValidatedTextInput';
import BusyButton from '../../BusyButton';
import { ServerErrorMessage } from '../../ui/ServerErrorMessage';
import { RequestStateHandler } from '../../ui/RequestStateHandler';

interface CreateProjectProps {
    currentOrganization: Organization | null;
    handleSetCreatedProject: (project: Project) => void;
    projects: readonly Project[];
}

const CreateProject = ({ currentOrganization, handleSetCreatedProject, projects }: CreateProjectProps) => {
    const navigate = useNavigate();
    const [projectName, setProjectName] = useState<string>('');
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(currentOrganization);
    const {
        data: projectList,
        isLoading,
        error,
        refetch,
    } = useGetProjectListForOrgQuery(currentOrganization ?? skipToken);
    const [createProject, { isLoading: createProjectIsLoading, error: createProjectError }] =
        useCreateProjectMutation();
    const [justCreatedProject, setJustCreatedProject] = useState<Project | null>(null);

    const handleConfirmCreateProject = useCallback(async () => {
        if (selectedOrg !== null && projectList) {
            const project: Project = await createProject({
                projectList,
                projectName,
            }).unwrap();
            refetch();
            setJustCreatedProject(project);
        }
    }, [selectedOrg, projectName, createProject, projectList, refetch]);

    useEffect(() => {
        // if the project we just created appears in the list of projects, we can safely navigate to it
        if (justCreatedProject && projects.map((p) => p.slug).includes(justCreatedProject.slug)) {
            handleSetCreatedProject(justCreatedProject);
        }
    }, [projects, justCreatedProject, handleSetCreatedProject]);

    useEffect(() => {
        if (!!selectedOrg) {
            navigate(CREATE_PROJECT.generate({ org: selectedOrg.name }), {
                replace: true,
            });
        }
    }, [selectedOrg, navigate]);

    if (isLoading || !!error) {
        return <RequestStateHandler isLoading={isLoading} error={error} />;
    }

    if (projectList === undefined) {
        return (
            <Box display="flex" flexDirection="row" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    }

    const halFormsProperty = resolveTemplate(projectList, 'default')?.property('name')!;

    return (
        <Box sx={{ maxWidth: '40rem' }}>
            <Box mb={2}>
                <Typography variant="h6">Create Project</Typography>
            </Box>

            <ServerErrorMessage error={createProjectError} />

            <StyledRow>
                <InputLabel id="orgselect" htmlFor="name">
                    Create in organization:
                </InputLabel>
                <OrganizationSelector labelId="orgselect" handleSetSelectedOrg={setSelectedOrg} />
            </StyledRow>

            <StyledRow>
                <InputLabel htmlFor="projectName">Name the project:</InputLabel>
                <ValidatedTextInput
                    value={projectName}
                    displayName="Project name"
                    halProperty={halFormsProperty}
                    autoFocus
                    handleOnChange={(val) => setProjectName(val)}
                    handleOnKeyDown={(key) => key === 'Enter' && handleConfirmCreateProject()}
                    id="projectName"
                    fullWidth
                />
            </StyledRow>

            <FormActions>
                <Button onClick={() => navigate(-1)}>Back</Button>

                <BusyButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleConfirmCreateProject}
                    busy={createProjectIsLoading}
                >
                    Create
                </BusyButton>
            </FormActions>
        </Box>
    );
};

const StyledRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: '12rem 1fr',
    alignItems: 'baseline',
    marginBottom: '1rem',
});

export default connect(
    (state: AppState) => ({
        currentOrganization: selectCurrentOrganization(state),
        projects: selectProjects(state),
    }),
    (dispatch: AppDispatch) => ({
        handleSetCreatedProject: (project: Project) => {
            dispatch(projectSetCreatedProject(project));
        },
    }),
)(CreateProject);
