import { CircularProgress, Tooltip } from '@material-ui/core';
import RelativeTime from '@yaireo/relative-time';
import React from 'react';

import { Application } from '../../../../repository/models/Application';
import { Project } from '../../../../repository/models/Project';
import { Deployment } from '../../../../repository/models/Deployment';
import { INSTANCE_OVERVIEW } from '../../../routes';
import { Cell } from '../../../../ui/table/Cell';
import { RowLink } from '../../../../ui/table/RowLink';
import { ApplicationHeaderInfo } from '../../../../ui/ApplicationHeaderInfo';
import { useGetDeploymentsForApplicationQuery, useGetZoneQuery } from '../../api';
import ApplicationState from '../../components/ApplicationState';
import { applicationDeployLocation, applicationVisitLocation, HighlightedComponent } from '../../../tour';
import useQuery from '../../../useQuery';
import { ServerErrorMessage } from '../../../../ui/ServerErrorMessage';
import { createStyles, makeStyles } from '@material-ui/styles';

type InstancesRowProps = {
    application: Application;
    project: Project | null;
    isNotFirstDescendant: boolean;
    columnWidths: number[];
};

const relativeTime = new RelativeTime();

export default function InstancesRow({ application, project, isNotFirstDescendant, columnWidths }: InstancesRowProps) {
    const { data: deployments, isLoading, error } = useGetDeploymentsForApplicationQuery(application);
    const classes = useStyles();

    const deploying = useQuery().get('deploying');

    const detailUrl =
        INSTANCE_OVERVIEW.generate({
            org: project!.organization,
            project: project!.name,
            application: application.name,
        }) + (deploying ? '?deploying=' + deploying : '');

    const { data: zone, isLoading: zoneLoading, error: zoneError } = useGetZoneQuery(application._links.zone.href);

    return (
        <RowLink link={detailUrl} isNotFirstDescendant={isNotFirstDescendant}>
            <HighlightedComponent location={[applicationDeployLocation, applicationVisitLocation]}>
                <Cell width={columnWidths[0]}>
                    <ApplicationHeaderInfo
                        name={application.name}
                        zoneName={zone ? zone.name : '-'}
                        isLoading={zoneLoading}
                        error={zoneError}
                    />
                </Cell>
            </HighlightedComponent>

            <Cell width={columnWidths[1]}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.25em',
                    }}
                >
                    <ApplicationState state={application.state} />
                </div>
            </Cell>

            <Cell width={columnWidths[2]}>
                <CreatedTimestamp app={application} />
            </Cell>

            <Cell width={columnWidths[3]}>
                {isLoading ? (
                    <CircularProgress disableShrink size="1em" />
                ) : !!error ? (
                    <ServerErrorMessage className={classes.error} textView error={error} />
                ) : (
                    <LastDeploymentTimestamp deployments={deployments ?? null} />
                )}
            </Cell>
        </RowLink>
    );
}

type LastDeploymentTimestamProps = {
    deployments: readonly Deployment[] | null;
};
const LastDeploymentTimestamp = ({ deployments }: LastDeploymentTimestamProps) => {
    if (deployments === null) {
        return <CircularProgress size="1em" disableShrink />;
    }
    if (deployments.length > 0) {
        const last = deployments[deployments.length - 1]!;
        const date = new Date(last.created);
        return (
            <Tooltip title={date.toLocaleString()} arrow>
                <>{relativeTime.from(date)}</>
            </Tooltip>
        );
    }
    return <>—</>;
};

type CreatedTimestampProps = {
    app: Application;
};
const CreatedTimestamp = ({ app }: CreatedTimestampProps) => {
    const date = new Date(app.createdAt);
    return (
        <Tooltip title={date.toLocaleString()} arrow>
            <>{relativeTime.from(date)}</>
        </Tooltip>
    );
};

const useStyles = makeStyles(() =>
    createStyles({
        error: {
            fontSize: 'inherit',
        },
    }),
);
