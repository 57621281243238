import { connect } from 'react-redux';
import { AppState } from '../../store';
import { selectCurrentProject } from '../project/projectSlice';
import { ReleasesTable } from '../release/Releases';
import { selectCurrentOrganization } from '../account/accountSlice';

export default connect((state: AppState) => ({
    project: selectCurrentProject(state),
    organization: selectCurrentOrganization(state),
}))(ReleasesTable);
