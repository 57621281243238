import React from 'react';

import { Theme, withStyles } from '@material-ui/core';
import clsx from 'clsx';

interface FormActionsProps extends React.HTMLAttributes<HTMLDivElement> {
    classes: { root: any };
    className?: string;
    children?: React.ReactNode;
}

export const styles = (theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *:not(first-child)': {
            marginLeft: theme.spacing(1),
        },
    },
});

const FormActions = React.forwardRef((props: FormActionsProps, ref: React.ForwardedRef<any>) => {
    const { classes, className, ...other } = props;

    return <div ref={ref} className={clsx(classes.root, className)} {...other} />;
});

export default withStyles(styles, { name: 'FormActions' })(FormActions);
