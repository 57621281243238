import { createWatcher, combineWatchers } from '@xenit/redux-utils';
import { AppDispatch } from '../../store';
import { selectCurrentOrganization } from '../account/accountSlice';
import projectsApi from './api';
import { clearCurrentProject } from './projectSlice';

const currentOrgWatcher = createWatcher(
    selectCurrentOrganization,
    (newOrg, _oldOrg, { dispatch }: { dispatch: AppDispatch }) => {
        if (newOrg) {
            dispatch(projectsApi.endpoints.getProjectListForOrg.initiate(newOrg));
        } else {
            dispatch(clearCurrentProject());
        }
    },
);

export default combineWatchers(currentOrgWatcher);
